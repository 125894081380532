// Pages
import Organizations from './pages/organizations/Organizations';
import Portfolios from './pages/portfolios/Portfolios';
import Locations from './pages/locations/Locations';
import LocationPortfolios from './pages/locationPortfolios/LocationPortfolios';
import Accounts from './pages/accounts/Accounts';
import ChangeLocationPortfolioModal from './organisms/ChangeLocationPortfolioModal';
import ActiveDuplicates from './pages/activeDuplicates/activeDuplicates';
import ActiveDuplicatesFiles from './pages/activeDuplicates/activeDuplicatesFiles';
import PortfolioFile from './pages/portfolioFile/portfolioFile';

export const bit_pages = {
  Organizations,
  Portfolios,
  Locations,
  LocationPortfolios,
  Accounts,
  ActiveDuplicates,
  ActiveDuplicatesFiles,
  PortfolioFile,
};


export const bit_organisms = {
  ChangeLocationPortfolioModal
};

export default () => {
  return {
    bit_pages,
    bit_organisms,
  };
};
