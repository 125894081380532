import React from "react";
import Button from "../../atoms/button/button";
import { useTable, usePagination, useSortBy } from "react-table";
import styles from "./react-table.module.css";
import { Box } from "@mui/material"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';

export default ({
  columns,
  data,
  pageIndexProp,
  pageSizeProp,
  setPageSizeProp,
  setPageIndexProp,
  setSortObjProp,
  sortObjProp,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageIndexProp ?? 0,
        pageSize: pageSizeProp ?? 20,
        sortBy: [
          {
            id: sortObjProp && sortObjProp.Header ? sortObjProp.Header : "",
            desc: sortObjProp && sortObjProp.isSortedDesc ? true : false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );
  // Render the UI for your table
  return (
      <>
      <div
          style={{
              maxHeight: "calc(100% - 80px)",
              maxWidth: "100%",
          }}
          className={styles.table_container}
      >
          <table
              {...getTableProps()}
              style={{minWidth: "100%"}}
              className={styles.table}
          >
              <thead>
              {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.length &&
                          headerGroup.headers.map((column) => {
                              return (
                                  <th
                                      style={{textAlign: "left"}}
                                      {...column.getHeaderProps(column.getSortByToggleProps())}
                                  >
                                      <div
                                          style={{width: "100%", height: "100%"}}
                                          onClick={() => {
                                              const sortHandlerRedux = {Header: column.Header};
                                              if (column.isSorted) {
                                                  if (column.isSortedDesc) {
                                                      sortHandlerRedux.isSorted = false;
                                                      sortHandlerRedux.isSortedDesc = false;
                                                  } else {
                                                      sortHandlerRedux.isSorted = true;
                                                      sortHandlerRedux.isSortedDesc = true;
                                                  }
                                              } else {
                                                  sortHandlerRedux.isSorted = true;
                                                  sortHandlerRedux.isSortedDesc = false;
                                              }

                                              setSortObjProp && setSortObjProp(sortHandlerRedux);
                                          }}
                                      >
                                          {column.isSorted
                                              ? column.isSortedDesc
                                                  ? "↓    "
                                                  : "↑    "
                                              : ""}
                                          {column.customHeader
                                              ? column.customHeader
                                              : column.render("Header")}
                                      </div>
                                  </th>
                              );
                          })}
                  </tr>
              ))}
              </thead>
              <tbody>
              {page.map((row, i) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                              return (
                                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              );
                          })}
                      </tr>
                  );
              })}
              </tbody>
          </table>
      </div>
        <Box
        className="pagination"
        sx={{
            width: "100%",
            bottom: 0,
            left: 0,
            paddingLeft: "20px",
            paddingRight: "20px",
            minHeight: {xs: 100, sm: 60},
            background: "var(--darker)",
            display: "flex",
            alignItems: "center",
            justifyContent: {xs: "inherit", sm: "flex-end"},
        }}
    >
            <Box sx={{display: "flex", flexDirection: {xs: "column", sm: "row"}, justifyContent: "center", alignItems: "center"}}>
                <Box>
                    <select
                        style={{marginRight: 10}}
                        value={pageSizeProp}
                        onChange={(e) => {
                            setPageSizeProp && setPageSizeProp(Number(e.target.value));
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50, 100, 500, 1000].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Box>
                <Box>
                    <Button
                        style={{marginRight: 10}}
                        onClick={() => {
                            setPageIndexProp && setPageIndexProp(0);
                            gotoPage(0);
                        }}
                        disabled={!canPreviousPage}
                    >
                        <FirstPageIcon />
                    </Button>{" "}
                    <Button
                        style={{marginRight: 20}}
                        onClick={() => {
                            previousPage();
                            setPageIndexProp && setPageIndexProp(pageIndex - 1);
                        }}
                        disabled={!canPreviousPage}
                    >
                        <KeyboardArrowLeftIcon size={12}/>
                    </Button>
                </Box>
                <Box>
                    <span>
                            <strong>{pageIndex + 1}</strong>
                        &nbsp;&nbsp;
                        <span style={{fontWeight: 400, opacity: 0.6, fontSize: 14}}>
                              of
                            </span>
                            <span style={{opacity: 0.7}}>
                              &nbsp;&nbsp;
                                {pageOptions.length}
                            </span>
                          </span>
                </Box>
                <Box>
                    <Button
                        style={{marginRight: 10, marginLeft: 20}}
                        onClick={() => {
                            nextPage();
                            setPageIndexProp && setPageIndexProp(pageIndex + 1);
                        }}
                        disabled={!canNextPage}
                    >
                        <KeyboardArrowRightIcon fontSize={'medium'} />
                    </Button>{" "}
                    <Button
                        style={{marginRight: 40}}
                        onClick={() => {
                            setPageIndexProp && setPageIndexProp(pageCount - 1);
                            gotoPage(pageCount - 1);
                        }}
                        disabled={!canNextPage}
                    >
                        <LastPageIcon />
                    </Button>{" "}
                </Box>
            </Box>
        </Box>
      </>
  );
};
