import {createSlice} from '@reduxjs/toolkit';

const portfolioFileSlice = createSlice({
  name: 'portfolioFile',
  initialState: {
    portId: null,
    fileData: [],
    badAccounts: [],
    rangesData: [],
    creditorStratum: [],
    creditorLuhnStats: [],
    portfolioStats: {},
    importedTotals: {},
    activeView: 0,
  },
  reducers: {
    setPortId: (state, action) => {
      state.portId = action.payload;
    },
    // y'know, these could probably be combined into one set function. KS 05/26/22
    setFileData: (state, action) => {
      state.fileData = action.payload;
    },
    setBadAccounts: (state, action) => {
      state.badAccounts = action.payload;
    },
    setRangesData: (state, action) => {
      state.rangesData = action.payload;
    },
    setCreditorStratum: (state, action) => {
      state.creditorStratum = action.payload;
    },
    setPortfolioStats: (state, action) => {
      state.portfolioStats = action.payload;
    },
    setCreditorLuhnStats: (state,action) => {
      state.creditorLuhnStats = action.payload;
    },
    setImportedTotals: (state, action) => {
      state.importedTotals = action.payload;
    },
    setActiveView: (state,action) => {
      state.activeView = action.payload;
    },
    updateLuhnIgnore: (state,action) => {
      const { client_id, luhn_directive, updated_bool } = action.payload;
      const itemToUpdate = state.creditorLuhnStats.find(item => item.client === client_id);
      const idx = state.creditorLuhnStats.indexOf(itemToUpdate);
      if(state.creditorLuhnStats[idx].luhn_directive === luhn_directive && !state.creditorLuhnStats[idx].luhn_updated){
        // don't do anything if the directive hasn't changed and updated is false
        return;
      } else {
        // update the directive and set the updated flag
        state.creditorLuhnStats[idx].luhn_directive = luhn_directive;
        state.creditorLuhnStats[idx].luhn_updated = updated_bool;
      }
    },
  },
});

export const {
  setPortId,
  updateLuhnIgnore,
  setFileData,
  setBadAccounts,
  setRangesData,
  setCreditorStratum,
  setPortfolioStats,
  setCreditorLuhnStats,
  setImportedTotals,
  setActiveView,
} = portfolioFileSlice.actions;

export default portfolioFileSlice.reducer;
