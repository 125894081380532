import React from "react";
import ReactDOM from "react-dom";
import "./app/style/index.css";
import "./app/style/gg.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, useLocation } from 'react-router-dom';
import checkUserAuth from './modules/fc_src/utils/fetch/auth/checkUserIsAuthenticated';
import userSettings from "./modules/fc_src/utils/userSettings/userSettings";
// Pull in auth
import {store} from './app/store';

import {
  setUserSessionIsAuthenticated,
  setUserSessionPermissions,
} from './modules/fc_src/reducers/userSessionSlice';
import {setBitLocationPortfoliosPageSize} from './modules/fc_bit_mgmt_mods/reducers/locationPortfoliosSlice';
import {setBitLocationsPageSize} from './modules/fc_bit_mgmt_mods/reducers/locationsSlice';
import {setOrganizationsPageSize} from './modules/fc_bit_mgmt_mods/reducers/organizationsSlice';
import {setBitPortfoliosPageSize} from './modules/fc_bit_mgmt_mods/reducers/portfoliosSlice';
import {setServerMonitorTableSize} from './modules/fc_clos_admin_mods/reducers/serverMonitorSlice';
import {setQueryToolNumRows, setQueryToolTab} from './modules/fc_src/reducers/queryToolSlice';
import {setGridLayout} from './modules/fc_src/reducers/appSettingsSlice';

const AuthedApp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userSessionIsValid = useSelector(
    (state) => state.UserSessions.isAuthenticated
  );
  React.useEffect(() => {
    const pullUserDataInits = async () => {
      const userData = await userSettings.getUserSettings();
      const ud = userData && Object.keys(userData).length ?
        [...userData.ui_settings] :
          [];
      ud.forEach((v, i) => {
        switch(v.type) {
          case "SET_BIT_LOCATION_PORTFOLIOS_PAGE_SIZE":
            dispatch(setBitLocationPortfoliosPageSize(v.setting))
            break;
          case "SET_BIT_LOCATIONS_PAGE_SIZE":
            dispatch(setBitLocationsPageSize(v.setting))
            break;
          case "SET_BIT_ORGANIZATIONS_PAGE_SIZE":
            dispatch(setOrganizationsPageSize(v.setting))
            break;
          case "SET_BIT_PORTFOLIOS_PAGE_SIZE":
            dispatch(setBitPortfoliosPageSize(v.setting))
            break;
          case "set_server_monitor_table_size":
            dispatch(setServerMonitorTableSize(v.setting))
            break;
          // case "SET_GRID_LAYOUT":
          //   dispatch(setGridLayout(v.setting))
          //   break;
          case "SET_QUERY_TOOL_NUM_ROWS":
            dispatch(setQueryToolNumRows(v.setting))
            break;
          case "SET_QUERY_TOOL_ACTIVE_TAB":
            dispatch(setQueryToolTab(v.setting))
            break;
          default:
            // Check if this is a grid setting
            const isGridSetting = v.type.slice(0,16) === 'SET_GRID_LAYOUT_';
            if(isGridSetting) {
              dispatch(setGridLayout(v.setting))
            }
        }
      });
    };
    if (userSessionIsValid) {
      pullUserDataInits();
    }
  }, [userSessionIsValid]);

  const doAuthCheck = async () => {
    const authCheck = await checkUserAuth();
      dispatch(setUserSessionIsAuthenticated(authCheck.isValid));
      dispatch(setUserSessionPermissions(authCheck.permissions ? authCheck.permissions : {}));
      return;
  }
  React.useEffect(() => {
    const validateUserSession = async () => {
      doAuthCheck();
    }
    validateUserSession();
  }, [location]);

  //checks if user is authenticated every 30 seconds
  //to avoid timeout bugs KS 12/16/21
  React.useEffect(() => {
    const authInterval = setInterval(() => {
      doAuthCheck();
    }, 30000);
    return () => clearInterval(authInterval);
  }, []);

  return (
    <App validSession={userSessionIsValid} />
  );
};
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <AuthedApp />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
