import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material"

import {
  setSearchFilters,
  setPageSize,
  setPageIndex,
  setSortObj
} from "../../reducers/accountSlice";

// API Interface
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_molecules,
  fc_atoms
} from '../../../fc_src/ui';

import {
  bit_organisms,
} from '../../ui';

import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';
export const Accounts = () => {

  const {
    Card,
    Table,
  } = fc_molecules;

  const {
    Spinner,
    TextInput,
    Button,
  } = fc_atoms;

  const {
    ChangeLocationPortfolioModal
  } = bit_organisms;

  // Inital App Config
  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();


  // Redux Selectors
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const BitMgmtAccountFilters = useSelector((state) => state.AccountSearch.filters);
  const pageSize = useSelector((state) => state.AccountSearch.displayTable.pageSize);
  const pageIndex = useSelector((state) => state.AccountSearch.displayTable.pageIndex);
  const sortObj = useSelector((state) => state.AccountSearch.displayTable.sortObj);


  // Page State
  const [accountSearchResults, setAccountSearchResults] = useState([]);
  const [searchIsRunning, setSearchIsRunning] = useState(false);
  const [showChangeLocationPortfolio, setShowChangeLocationPortfolio] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState({});

  
  const runAccountSearch = async () => {
    setSearchIsRunning(true);
    const accountSearch = await bitMgmtAPI.doAccountSearch(BitMgmtAccountFilters);
    setAccountSearchResults(accountSearch);
    setSearchIsRunning(false);
  }

  const updateFilterValue = (key, val) => {
    const tmpFilters = {...BitMgmtAccountFilters};
    tmpFilters[key] = val;
    dispatch(setSearchFilters(tmpFilters))
  }

  const doViewAccount = (account) => {
    // 
    alert(`View Account called for: ${JSON.stringify(account)}`);
  };

  const doAssignToLocation = async (account) => {
    // If account is already loaded, this action cannot be completed
    if(account.loaded) {
      // Alert failure
      alert('Account has already been loaded!');
      return false;
    }
    // Populate the state variable with modal contents
      // Relavent account info in .account
      // all (portfolio_location_id and portfolio_location_name) from the same organization to which the account belongs
    // Store all that data for the modal showing the account
    setAccountToEdit({...account});
    setShowChangeLocationPortfolio(true);

  };

  const updateAccountsArray = (newAccountData, index) => {
    const resultsArray = [...accountSearchResults];
    resultsArray[index] = {...newAccountData};
    setAccountSearchResults(resultsArray);
  };

  const doPushToLocation = async (account, index) => {
    const tmpAccount = {...account};
    // If account is already loaded, this action cannot be completed
    if(tmpAccount.loaded) {
      // Alert failure
      alert('Account has already been loaded!');
      return false;
    }
    const results = {success: false};
    const confirmAccount = window.confirm(`Pushing account ${tmpAccount.account_id} to location : ${tmpAccount.location_portfolio_id}`);
    if(confirmAccount) {
      const updateCall =  await bitMgmtAPI.doPostToLocation(tmpAccount.account_id);
      if(updateCall.success || updateCall.length) {
        results.success = true;
      }
      // Pass the account to the API. It will get the location in the API, so we aren't passing it from the front-end.
    }
    if(results.success) {
      tmpAccount.loaded = true;
      updateAccountsArray(tmpAccount, index);
    }
  };

  // const doChangeWorklist = (account) => {
  //   // 
  //   alert(`Change worklist on account: ${JSON.stringify(account)}`);
  // };

  useEffect(() => {
    if (ActivePage !== "account_search") {
      dispatch(setActivePage("account_search"));
    }
  });

  return (
    <Box>
        <Card
          title="Account Search"
          style={{ height: "calc(100vh - 150px)" }}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          <Box sx={{marginBottom: "8px", display: "flex", flexDirection: "row"}}>
            <TextInput
                customRightOffset={"15px"}
                placeholder="Full Name"
                onChange={(val) => updateFilterValue('full_name', val)}
                value={BitMgmtAccountFilters.full_name}
                style={{ marginRight: 10 }}
                onEnter={() => runAccountSearch()}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="SSN"
                onChange={(val) => updateFilterValue('ssn', val)}
                value={BitMgmtAccountFilters.ssn}
                style={{ marginRight: 10 }}
                onEnter={() => runAccountSearch()}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Client Reference ID"
                onChange={(val) => updateFilterValue('client_reference_id', val)}
                value={BitMgmtAccountFilters.client_ref_id}
                style={{ marginRight: 10 }}
                onEnter={() => runAccountSearch()}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Account ID"
                onChange={(val) => updateFilterValue('account_id', val)}
                value={BitMgmtAccountFilters.account_id}
                style={{ marginRight: 10 }}
                onEnter={() => runAccountSearch()}
            />
          </Box>
          {(
            searchIsRunning ?
              <div
                style={{
                  width: "100%",
                  height: 300,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            :
            accountSearchResults.length ? (
              <Table
                columns={(() => {
                  const colData = [];
                  accountSearchResults[0] &&
                    Object.keys(accountSearchResults[0]).forEach((key) => {
                      if(key !== 'portfolio_id' && key !== 'location_portfolio_id' && key !== 'organization_id') {
                        colData.push({
                          Header: key,
                          accessor: key,
                          customHeader: key.replace(/_/g, " "),
                        });
                      }
                    });
                    colData.push({
                      Header: `Actions`,
                      accessor: "view_account",
                    });
                    colData.push({
                      Header: ` `,
                      accessor: "assign_to_location",
                    });
                    colData.push({
                      Header: ` `,
                      accessor: "push_to_location",
                    });
                    colData.push({
                      Header: ` `,
                      accessor: "is_loading"
                    })
                    // colData.push({
                    //   Header: ` `,
                    //   accessor: "change_worklist",
                    // });
                  return colData;
                })()}
                data={(() => {
                  return accountSearchResults.map((i, ind) => {
                    const tmp = { ...i };
                    tmp.loaded = i.loaded === true ? 'Yes' : 'No';
                    tmp.ssn = (
                      <div className={`accounts_num_holder`} style={{cursor: 'pointer'}}>
                        <span className={`masked_first`}>*--{String(i.ssn).substring(4,9)}</span>
                        <span className={`unmasked_first`}>{String(i.ssn).substring(0,4)}{String(i.ssn).substring(4,9)}</span>
                      </div>
                    );
                    tmp.view_account = (
                      <Button
                       onClick={() => doViewAccount(i)}
                      >View</Button>
                    );
                    tmp.assign_to_location = (
                      <Button
                        onClick={() => doAssignToLocation(i, ind)}
                        disabled={(i.loaded === true)}
                        style={{cursor: `${i.loaded  === true ? 'not-allowed' : 'pointer'}`}}
                      >Assign&nbsp;to&nbsp;Port</Button>
                    );
                    tmp.push_to_location = (
                      <Button
                        onClick={() => doPushToLocation(i, ind)}
                        disabled={i.loaded === true || !i.location_portfolio_id}
                        style={{cursor: `${i.loaded === true || !i.location_portfolio_id ? 'not-allowed' : 'pointer'}`}}
                      >Push&nbsp;to&nbsp;Location</Button>
                    );
                    tmp.is_loading = (
                      <div style={{display: `${tmp.account_id ===  accountToEdit.account_id ? 'block' : 'none'}`}}>
                        <Spinner />
                      </div>
                    );
                    return tmp;
                  });
                })()}
                pageSizeProp={pageSize}
                pageIndexProp={pageIndex}
                sortObjProp={sortObj}
                setPageSizeProp={(val) => {
                  dispatch(setPageSize(val));
                }}
                setPageIndexProp={(val) => {
                  dispatch(setPageIndex(val));
                }}
                setSortObjProp={(val) => {
                  dispatch(setSortObj(val));
                }}
              />
            ) : (
                <Box>
                  <Typography>No matching accounts</Typography>
                </Box>
            )
          )}
        </Card>
        {/* 
          Change account locationPortfolio:
            - Account must not have been loaded
            - New location porfolio must come from the same organization
        */}
        
        <ChangeLocationPortfolioModal 
          isVisible={showChangeLocationPortfolio}
          closeModal={(tf) => {
            setShowChangeLocationPortfolio(tf);
            if(tf === false ) {
              // If we're closing the modal, empty the modal state variable
              setAccountToEdit({});
            }
          }}
          updateAccounts={(newPortId) => {
            const tmpAccount = {...accountToEdit};
            tmpAccount.location_portfolio_id = newPortId;
            const accountIndex = accountSearchResults.findIndex(i => i.account_id === accountToEdit.account_id);
            updateAccountsArray(tmpAccount, accountIndex);
          }}
          account={accountToEdit}
        />
    </Box>
  );
};

export default Accounts;