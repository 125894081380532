import React, { useEffect } from "react"
import {
    Modal,
    Box,
    FormGroup,
    Button,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import {modalStyle} from "../../theme/theme";

export const AddEditPhoneModal = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    const {modalOpen, setModalOpen, addFunction, editFunction, editID, editData, setActivePhoneEditId} = props

    const handleClose = () => {
        setActivePhoneEditId(-1)
        setModalOpen(false)
    }
    const onErrors = (errors) => console.error(errors)
    const isEdit = (editID !== -1)
    const submitPhone = (data) => {
        /* React + RHF really don't like hidden inputs, so we manually add the 'verified' field + value before submitting */
        let submissionData = {...data, verified: false,}
        if (isEdit){
            submissionData = {...submissionData, phone_id: editID}
            editFunction(submissionData)
        } else {
            addFunction(submissionData)
        }
    };

    useEffect(() => {
        const fields = ["number", "description", "mobile", "do_not_contact", "archive"]

        /* In the case of cancelling an "Edit", then opening up an "Add", clear out all fields*/
        if (editID === -1){
            fields.forEach(field => {
                if (field !== "do_not_contact" && field !== "archive" && field !== "mobile"){
                    setValue(field, "")
                } else {
                    setValue(field, false)
                }
            })
        }
        /* We're doing an explicit check on editID rather than isEdit to avoid weird state issues */
        if (editID !== -1){
            const phoneEntry = editData.filter((entry) => {return entry.id === editID})
            fields.forEach(field => {
                    if (field === "mobile" || field === "do_not_contact" || field === "archive"){
                        if (phoneEntry[0][field] === 1){
                            setValue(field, true)
                        } else {
                            setValue(field, false)
                        }
                    } else {
                        setValue(field, phoneEntry[0][field]);
                    }
            }
               )
        }
    }, [editID, modalOpen])

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
        >
            <Box sx={modalStyle}>
                <Typography variant='h4' align="center">{isEdit ? "Edit Phone" : "Add Phone"}</Typography>
                <FormGroup>
                    <TextField
                        {...register('number', {required: true})}
                        name="number"
                        sx={{margin: '5px'}}
                        label="Phone Number"
                        error={(errors?.number) ? true : false}
                        helperText={(errors?.number) ? "Please enter a valid phone number" : ""}
                    />
                    <TextField
                        {...register('description')}
                        name="description"
                        sx={{margin: '5px'}}
                        label="Description"
                        color="primary"
                    />
                    <Controller
                        name="mobile"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`mobile`)}
                                label="Mobile"
                            />
                        )}
                    />
                    <Controller
                        name="do_not_contact"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`do_not_contact`)}
                                label="Do Not Contact"
                            />
                        )}
                    />
                    <Controller
                        name="archive"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`archive`)}
                                label="Archived"
                            />
                        )}
                    />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "16px"}}>
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit(submitPhone, onErrors)}> Save
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            sx={{color: "#fff"}}
                            onClick={handleClose}> Cancel
                        </Button>
                    </div>
                </FormGroup>
            </Box>
        </Modal>
    )
}

export default AddEditPhoneModal;