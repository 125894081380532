// Pages
import Contact from './pages/crm/Contact';
import Contacts from './pages/crm/Contacts';
import CreateContact from './pages/crm/createContact';
import Login from './pages/login/login';
import WebApp from './pages/webApp/webApp';
import QueryTool from './pages/queryTool/queryTool';
import QueryAuth from './pages/queryTool/queryAuth';
import Users from './pages/users/Users';
import NotificationServices from './pages/notificationServices/NotificationServices';
import MyAccount from './pages/myAccount/myAccount';
import Campaigns from './pages/campaigns/campaigns';

// Templates
import TabView from './templates/tabView/TabView';
import UpdatedTabView from './templates/tabView/UpdatedTabView';

// Organisms
import Grid from './organisms/grid/grid';
import Header from './organisms/header/header';
import Page from './organisms/page/page';
import UsersTable from './organisms/users/UsersTable';

// Molecules
import Card from './molecules/card/card';
import Modal from './molecules/modal/modal';
import NewModal from './molecules/modal/newModal';
import Table from './molecules/react-table/react-table';
import StatusBar from './molecules/statusBar/statusBar';
import SortableColumnsTable from './molecules/sortableColumnsTable/SortableColumnsTable';
import PermissionTable from './molecules/permissionTable/permissionTable';
import FCUserForm from './molecules/forms/fcUserForm';

// Atoms
import AddButton from './atoms/addButton/addButton';
import BlockDataItem from './atoms/blockDataItem/blockDataItem';
import Button from './atoms/button/button';
import Checkable from './atoms/checkable/checkable';
import CheckableRightDisplay from './atoms/checkable/checkableRightDisplay';
import NumberInput from './atoms/inputs/numberInput/numberInput';
import PaginationNumberInput from './atoms/inputs/numberInput/paginationNumberInput';
import PageNumInput from './atoms/inputs/numberInput/pageNumInput';
import TextInput from './atoms/inputs/textInput/textInput';
import InlineTextInput from './atoms/inputs/textInput/inlineTextInput';
import Spinner from './atoms/spinner/spinner';
import Switch from './atoms/switch/switch';

export const fc_pages = {
  Contact,
  Contacts,
  CreateContact,
  Login,
  WebApp,
  QueryTool,
  QueryAuth,
  Users,
  NotificationServices,
  MyAccount,
  Campaigns,
};

export const fc_templates = {
  TabView,
  UpdatedTabView,
};

export const fc_organisms =  {
  Grid,
  Header,
  Page,
  UsersTable,
};

export const fc_molecules = {
  Card,
  Modal,
  NewModal,
  Table,
  StatusBar,
  SortableColumnsTable,
  PermissionTable,
  FCUserForm,
};

export const fc_atoms = {
  AddButton,
  BlockDataItem,
  Button,
  Checkable,
  CheckableRightDisplay,
  NumberInput,
  PaginationNumberInput,
  PageNumInput,
  TextInput,
  InlineTextInput,
  Spinner,
  Switch,
};


export default () => {
  return {
    fc_pages,
    fc_templates,
    fc_organisms,
    fc_molecules,
    fc_atoms,
  };
};
