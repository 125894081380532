import React from "react";
import { Loader } from "react-feather";
import styles from "./spinner.module.css";

export default () => {
  return (
    <div className={styles.parent}>
      <Loader className="spinner" height={16} />
    </div>
  );
};
