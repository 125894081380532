import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { Box } from "@mui/material"

import {
  fc_templates,
  fc_molecules,
  fc_atoms
} from '../../../fc_src/ui';

import FCSpokesAPI from "../../../fc_src/utils/fetch/spokes/spokes";
import userSettings from "../../../fc_src/utils/userSettings/userSettings";

import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';

import {
  setInitServerData,
  sortServerMonitorTable,
  setServerMonitorTableSize,
  updateItemStatus,
  updateItemKillStatus,
  setServerFilterString,
  setSyncingStatus,
} from "../../reducers/serverMonitorSlice";
import { EditSpokeModal } from "../../organisms/editSpokeModal";

export const ServerMonitor = (props) => {

  const {
    TabView,
  } = fc_templates;

  const {
    Card,
    Table,
  } = fc_molecules;

  const {
    Button,
    Spinner,
    TextInput,
  } = fc_atoms;

  // const apiGetters = new APIGetters();
  const spokesAPI = new FCSpokesAPI();
  const [pageIndex, setPageIndex] = useState(0);
  const [archivedLocations, setArchivedLocations] = useState([]);

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);

  const [activeTab, setActiveTab] = useState(0);
  const [buttonLoading,setButtonLoading] = useState({
    syncButton: false,
  });
  const ClientData = useSelector((state) => state.ServerMonitor.data);
  const sortKey = useSelector((state) => state.ServerMonitor.meta.sortKey);

  const userSessionPermissions = useSelector((state) => state.UserSessions.permissions);

  // const sortDir = useSelector((state) => state.ServerMonitorTable.meta.sortDir);

  const filterString = useSelector((state) => state.ServerMonitor.filterString);

  const pageSize = useSelector((state) => state.ServerMonitor.meta.pageSize);

  const dispatch = useDispatch();

  const isStatusSyncing = useSelector((state) => state.ServerMonitor.syncing);

  const forceUpdate = async () => {
    if (isStatusSyncing) {
      return
    };
    const synctime = new Date().getTime() / 1000;
    dispatch(setSyncingStatus({ syncing: true, syncTime: synctime}));

    const locations = await spokesAPI.getSpokes();
    const statusData = await spokesAPI.getSpokesAndStatus();

    const newState = Object.assign({}, ClientData);
    newState.locations = [...locations];
    newState.initialData = statusData;
    newState.locationsMap = {};
    newState.filterString = filterString;
    locations.forEach((loc) => {
      newState.locationsMap[loc.location_id] = loc.shortName;
    });
    dispatch(setInitServerData(newState));
    dispatch(setSyncingStatus({ syncing: false, syncTime: synctime }));
  };

  const getServerData = async () => {
    const synctime = new Date().getTime() / 1000;

    dispatch(setSyncingStatus({ syncing: true, syncTime: synctime }));
    const locations = await spokesAPI.getSpokes();
    const statusData = await spokesAPI.getSpokesAndStatus();

    const newState = {};
    newState.locations = locations;
    newState.initialData = statusData;
    newState.filterString = filterString;
    newState.locationsMap = {};
    locations.forEach((loc) => {
      newState.locationsMap[loc.location_id] = loc.short_name;
    });
    dispatch(setInitServerData(newState));
    dispatch(setSyncingStatus({ syncing: false, syncTime: synctime }));
  };

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editProps, setEditProps] = useState({});

  useEffect(() => {
    if (ActivePage !== "server_monitor") {
      dispatch(setActivePage("server_monitor"));
    }
  });

  const getArchivedLocations = async () => {
    // getter2
    const res = await spokesAPI.getArchivedSpokes();
    setArchivedLocations(res);
  };

  // Killswitch
  const kill = (server) => {
    confirmAlert({
      title: "Deactivate CLOS",
      message: `Are you sure want to engage the killswitch at ${server}`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // getter3
            await spokesAPI.engageKillswitch(server);
            forceUpdate();
            alert("Please allow up to 30 seconds for changes to be reflected.");
          },
        },
        {
          label: "No",
          onClick: () => {
            return "";
          },
        },
      ],
    });
  };
  const killOff = (server, i, locationId) => {
    confirmAlert({
      title: "Activate CLOS",
      message: `Are you sure want to turn off the killswitch at ${server}`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // getter4
            // await apiGetters.killSwitchOff(server, i, locationId);
            await spokesAPI.disengageKillswitch(server);
            forceUpdate();
            alert("Please allow up to 30 seconds for changes to be reflected.");
          },
        },
        {
          label: "No",
          onClick: () => {
            return "";
          },
        },
      ],
    });
  };

  // syncEnable/Disable confirmation alert
  const toggleLocationSync = (server,val) => {
    const boolVal = val === 'enable' ? true : false;
    confirmAlert({
      title: `${val[0].toUpperCase() + val.slice(1)} Sync`,
      message: `Are you sure you want to ${val} at ${server.shortName}`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            setButtonLoading({...buttonLoading, syncButton: server.siteID});
            dispatch(updateItemStatus(server.siteID));
            return await spokesAPI.toggleSyncEnable(server,boolVal).then(() => {
              setButtonLoading({...buttonLoading, syncButton: false});
            });
          }
        },
        {
          label: "No",
          onClick: () => {
            return "";
          },
        },
      ],
    });
  };

  const openEditModal = (spoke,mode) => {
    if(mode === 'edit'){
      setEditProps({
        location_id: spoke.siteID,
        host_name: spoke.siteName,
        short_name: spoke.shortName,
        archived: false,
        mode: mode,
      });
    }
    if(mode === 'create'){
      setEditProps({
        location_id: '',
        host_name: '',
        short_name: '',
        archived: false,
        mode: mode,
      });
    }
    setEditModalVisible(true);
  };

  const submitAndCloseModal = (spoke) => {
    if(editProps.mode === 'create'){
      spokesAPI.createNewSpoke(spoke);
    }
    if(editProps.mode === 'edit'){
      spokesAPI.editSpoke(spoke);
      getArchivedLocations();
    }
    setEditProps({});
    getServerData();
  };

  useEffect(() => {
    getServerData();
    getArchivedLocations();
  }, []);

  return (
      <Box>
        <Card title="Client Locations">
          <Box sx={{display: "flex", flexDirection: "row", marginTop: "8px", marginBottom: "8px"}}>
            <TextInput
                placeholder="Filter Servers"
                onChange={(val) =>
                    dispatch(setServerFilterString(val))
                }
                customRightOffset={"4px"}
                value={filterString}
            />
            <Button
                disabled={!userSessionPermissions.CLOS}
                style={{ marginRight: 10, marginLeft: 10 }}
                onClick={() => openEditModal({},'create')}
            >
              + Add server
            </Button>
          </Box>
          {ClientData && ClientData.length ? (
            <TabView
              activeItem={activeTab}
              sections={[
                {
                  title: "Active",
                  setActive: (i) => setActiveTab(i),
                  children: (
                    <Table
                      pageIndexProp={pageIndex}
                      pageSizeProp={pageSize}
                      setPageIndexProp={(val) => setPageIndex(val)}
                      sortObjProp={sortKey}
                      setSortObjProp={(val) => {
                        dispatch(sortServerMonitorTable(val));
                      }}
                      setPageSizeProp={(val) => {
                        dispatch(setServerMonitorTableSize(val) );
                        userSettings.setNewUserSetting(pageSize, {
                          module: "SERVERMONITOR",
                          type: "set_server_monitor_table_size",
                          payload: val,
                        });
                      }}
                      columns={[
                        {
                          Header: "shortName",
                          accessor: "shortName",
                          customHeader: "Location",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return (
                              <span>
                                {original.shortName.replace("BIT2-", "")}
                              </span>
                            );
                          },
                        },
                        {
                          Header: "clos",
                          accessor: "clos",
                          customHeader: "CLOS",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return (
                              <span
                                style={original.clos === 'Active' ? {color: "var(--green"} : {color: "var(--red"}}
                              >
                                {original.clos}
                              </span>
                            );
                          },
                        },
                        {
                          Header: "mysql",
                          accessor: "mysql",
                          customHeader: "MySQL",
                          Cell: ({ row }) => {
                            const { original } = row;

                            return (
                              <span
                                style={original.mysql === 'Active' ? {color: "var(--green"} : {color: "var(--red"}}
                              >
                                {original.mysql}
                              </span>
                            );
                          },
                        },
                        {
                          Header: "sybase",
                          accessor: 'sybase',
                          customHeader: "Sybase",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return (
                              <span
                                style={original.sybase === 'Active' ? {color: "var(--green"} : {color: "var(--red"}}
                              >
                                {original.sybase}
                              </span>
                            );
                          },
                        },
                        {
                          Header: "node",
                          accessor: "node",
                          customHeader: "Spoke Status",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return (
                              <span
                                style={original.node === 'Active' ? {color: "var(--green"} : {color: "var(--red"}}
                              >
                                {original.node}
                              </span>
                            );
                          },
                        },
                        {
                          Header: "lastSynced",
                          accessor: "lastSynced",
                          customHeader: "Last Synced",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return (
                              <div style={{ width: 150 }}>
                                {original.lastSynced
                                  ? moment(original.lastSynced).format(
                                      "MM/DD hh:mma"
                                    )
                                  : "More than 2 days ago"}
                              </div>
                            );
                          },
                        },
                        {
                          Header: "syncEnabled",
                          accessor: "syncEnabled",
                          customHeader: "Sync Enabled",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return original.syncEnabled === true ? (
                              <Button
                                onClick={() => {
                                  toggleLocationSync(original,'disable');
                                }}
                              >
                                {buttonLoading.syncButton === original.siteID ? <Spinner /> : `Disable`}
                              </Button>
                            ) : (
                              <Button
                                color={"red"}
                                onClick={async () => {
                                  toggleLocationSync(original,'enable');
                                }}
                              >
                                {buttonLoading.syncButton === original.siteID ? <Spinner /> : `Enable`}
                              </Button>
                            );
                          },
                        },
                        {
                          Header: "killSwitchEngaged",
                          accessor: "killSwitchEngaged",
                          customHeader: "Killswitch",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return original.killSwitchEngaged === true ? (
                              <Button
                                color={"red"}
                                onClick={() => {
                                  killOff(
                                    original.shortName,
                                    1,
                                    original.siteID
                                  );
                                  dispatch(updateItemKillStatus(original.siteID));
                                }}
                              >
                                Disable
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  kill(original.shortName, 0, original.siteID);
                                  dispatch(updateItemKillStatus(original.siteID));
                                }}
                              >
                                Enable
                              </Button>
                            );
                          },
                        },
                        {
                          Header: "Edit",
                          customHeader: "Edit",
                          Cell: ({ row }) => {
                            const { original } = row;
                            return (
                              <Button
                                onClick={() => openEditModal(original,'edit')}
                                disabled={!userSessionPermissions.CLOS}
                              >
                                Edit
                              </Button>
                            );
                          },
                        },
                      ]}
                      data={ClientData.map((oldLoc) => {
                        const loc = { ...oldLoc };
                        // set all the statuses to 'Active','Error', or 'Unknown' based on the mins_since_last_sync and service status
                        // necessary for proper table sorting
                        if (loc.mins_since_last_sync < 22) { // skipped about 3 updates
                          loc.sybase = loc.sybaseStatus === true ? 'Active' : 'Error';
                          loc.mysql  = loc.mysqlStatus  === true ? 'Active' : 'Error';
                          loc.clos   = loc.closStatus   === true ? 'Active' : 'Error';
                          loc.node   = loc.nodeStatus   === true ? 'Active' : 'Error';
                        } else {
                          loc.sybase = 'Unknown';
                          loc.mysql  = 'Unknown';
                          loc.clos   = 'Unknown';
                          loc.node   = 'Error';
                        }

                        loc.siteName = loc.siteName.toUpperCase();
                        return loc;
                      })}
                    />
                  ),
                },
                {
                  title: "Archived",
                  setActive: (i) => setActiveTab(i),
                  children: (
                    <div
                      style={{
                        background: "var(--darker)",
                        textAlign: "left",
                        padding: "10px",
                        width: "calc(100% - 35px)",
                        borderRadius: "3px",
                        boxShadow: "inset 0px 1px 6px rgb(0 0 0 / 30%)",
                      }}
                    >
                      <table>
                        <thead
                          style={{
                            textTransform: "uppercase",
                            fontSize: "12px",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                borderBottom: "1px solid var(--medium)",
                                background: "var(--darker)",
                                height: "25px",
                                top: "-10px",
                                position: "sticky",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                borderBottom: "1px solid var(--medium)",
                                background: "var(--darker)",
                                height: "25px",
                                top: "-10px",
                                position: "sticky",
                              }}
                            >
                              ID
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {archivedLocations.map((l) => (
                            <tr>
                              <td
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  padding: "10px 40px 10px 0px",
                                  borderBottom:
                                    "1px solid rgba(255, 255, 255, 0.1)",
                                }}
                              >
                                {l.shortName}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  padding: "10px 40px 10px 0px",
                                  borderBottom:
                                    "1px solid rgba(255, 255, 255, 0.1)",
                                }}
                              >
                                {l.id}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div
              style={{ height: 300, width: "100%" }}
              className="inline_center"
            >
              <Spinner />
            </div>
          )}
        </Card>
        <EditSpokeModal
          modalVisible={editModalVisible}
          setModalVisible={setEditModalVisible}
          spoke={editProps}
          mode={editProps.mode}
          submitFunction={submitAndCloseModal}
        />
      </Box>
  );
};


export default ServerMonitor;