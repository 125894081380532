/* eslint-disable class-methods-use-this */

const {REACT_APP_API_URL} = process.env;

export default class UserGetters {
  async getFCUsers() {
    const out = await fetch(`${REACT_APP_API_URL}/users/read/getUsers`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  async getRoleList() {
    const out = await fetch(`${REACT_APP_API_URL}/users/read/getRoleList`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  async getPermissionList() {
    const out = await fetch(`${REACT_APP_API_URL}/users/read/getPermissionList`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getUserPermissions(userID) {
    const out = await fetch(`${REACT_APP_API_URL}/users/read/getUserPermissions`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({userID: userID}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
}
