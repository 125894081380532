/* eslint-disable class-methods-use-this */
const {REACT_APP_API_URL} = process.env;
export default async (props) => {
  const {setLoginInfo, setLoading, userName, password} = props;
  try {
    return fetch(`${REACT_APP_API_URL}/auth/write/login`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({ userName, password }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          return res.json();
        }
        return {};
      })
      .then((resJS) => {
        if (resJS.success) {
          setLoginInfo(true);
        } else {
          setLoginInfo(false);
        }
      });;
  } catch (e) {
    return setLoginInfo(false);
  }
}
