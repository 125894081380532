import UserSettingsAPI from "../fetch/userSettings/userSettings";

export const setNewUserSetting = (currentState, newUserUISetting) => {
  // Store the new object back in the redux store at the module key
  return setUserSetting(
    newUserUISetting.module,
    newUserUISetting.type,
    newUserUISetting.payload
  );
};

export const setUserSetting = async (module, type, setting) => {
  const settingsAPI = new UserSettingsAPI();
  await settingsAPI.setUserUISetting(module, {
    module,
    type,
    setting,
  });
  return true;
};

export const getUserSettings = async () => {
  const settingsAPI = new UserSettingsAPI();
  const userSettings = await settingsAPI.getUserUISettings();
  // Return the user setting from the redux store-- if not one exists, pull from the database
  return userSettings;
};

export default {
  setUserSetting,
  setNewUserSetting,
  getUserSettings,
};
