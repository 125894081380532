import React from "react";
import { X, Check } from "react-feather";
import styles from "./switch.module.css";

export default (props) => {
  return (
    <div
      className={`${styles.track} hoverable`}
      onClick={() => props.onClick(!props.active)}
    >
      <div
        className={
          props.active
            ? `${styles.circle} ${styles.circle_on} hoverable`
            : `${styles.circle}`
        }
      >
        {props.active ? <Check height={12} /> : <X height={12} />}
      </div>
    </div>
  );
};
