import React, { useState } from "react";
import moment from "moment";
import FCQueryAPI from "../../utils/fetch/queryTool/queryTool";

import {
  fc_atoms,
} from '../../ui';
const AuthRequest = (props) => {
 
  const {
    BlockDataItem,
    InlineTextInput,
    Button,
  } = fc_atoms;

  const { setAuthCode, queryNeedsAuth, authExpires } = props;

  const queryApi = new FCQueryAPI();
  const [requestListUsersActive, setRequestListUsersActive] = useState(false);
  const [authRequestUser, setAuthRequestUser] = useState(0);
  const [authRequestSent, setAuthRequestSent] = useState(false);
  const [authUsers, setAuthUsers] = useState([]);

  const startAuthRequest = () => {
    // Current request is open
    queryApi.getAuthUsers().then((r) => {
      if(r.length > 0) {
        setAuthUsers(r);
        setRequestListUsersActive(true);
      }
    });
  };

  const sendAuthRequest = async () => {
    if (authRequestUser.length) {
      await queryApi.requestRemoteQueryAuth(authRequestUser);
      setAuthRequestSent(true);
    }
  };

  return (
    <div
      style={{
        width: "calc(70% - 20px)",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {
      authRequestUser && authRequestUser.length && authRequestSent ? 
        (
          <BlockDataItem
            title=" "
            style={{
              width: "calc(100% - 40px)",
              marginRight: 40,
              maxWidth: "none",
            }}
          >
            <InlineTextInput
              type={`text`}
              style={{ width: "60%", display: "inline-block" }}
              onChange={(val) => {
                setAuthCode(val);
              }}
              placeholder={`Auth Code`}
            />
            <span>
              {authExpires
                ? `Expires : ${moment(authExpires).format("hh:mm")} `
                : ``}
            </span>
            <Button
              onClick={() => {
                setAuthRequestUser(null);
                setAuthRequestSent(false);
              }}
              style={{ marginLeft: '5px', paddingBottom: '13px', height: '45px' }}
            >
              Reset
            </Button>
          </BlockDataItem>
        ) 
      : 
        authUsers.length && requestListUsersActive ? 
          (
            <BlockDataItem
              title=" "
              style={{
                width: "calc(100% - 40px)",
                marginRight: 40,
                marginTop: "-10px",
                maxWidth: "none",
              }}
            >
              <select
                style={{ width: "80%", display: "inline-block" }}
                onChange={(e) => setAuthRequestUser(e.target.value)}
              >
                <option value={0}>Select an authorizer</option>
                {authUsers.map((s) => (
                  <option 
                    key={`user_${authUsers.id}`}
                    value={s.id}>{`${s.firstname} ${s.lastname}`}</option>
                ))}
              </select>
              <Button
                disabled={!authRequestUser}
                onClick={() => sendAuthRequest()}
                style={{ marginLeft: '5px', paddingBottom: '13px', height: '45px' }}
              >
                Request
              </Button>
            </BlockDataItem>
          ) 
        : 
          (
            <Button
              color={`${queryNeedsAuth ? "red" : ""}`}
              onClick={() => startAuthRequest()}
              style={{ marginLeft: '5px', paddingBottom: '13px', height: '45px' }}
            >
              Request Auth
            </Button>
          )
    }
    </div>
  );
};

export default AuthRequest;
