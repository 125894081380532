/* eslint-disable class-methods-use-this */

const {REACT_APP_API_URL} = process.env;

export default class CampaignGetters {
  async getCampaign() {
    const out = await fetch(`${REACT_APP_API_URL}/campaigns/read/getCampaigns`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  //
}
