import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    FormGroup,
    TextField,
    Button,
    Typography,
    Modal,
    Snackbar,
    Alert,
} from "@mui/material"
import { useForm } from 'react-hook-form'
import { setActivePage } from '../../reducers/appSettingsSlice';
import resetPassword from "../../utils/fetch/auth/resetPassword";
import CenteredSpinner from "../../organisms/CenteredSpinner";
import { modalStyle, cardActionStyles } from "../../theme/theme";

export const MyAccount = () => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();
    const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
    const [submitting, setSubmitting] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const onSubmit = async (data) => {
        setSubmitting(true);
        /* remove inputs from the form - we want to force fresh inputs each time*/
        reset();
        const res = await resetPassword(data);
        if (res) {
            setSubmitting(false);
            setSnackbarOpen(true);
        } else {
            setSubmitting(false);
            setShowErrorModal(true);
        }
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
          if (ActivePage !== "my_account") {
              dispatch(setActivePage("my_account"));
          }
    });

    return (
        <Box>
            {submitting ? <CenteredSpinner /> :
                <Card
                    sx={{ width: "100%", backgroundColor: "primary.main", color: "primary.contrastText"}}
                >
                    <CardHeader title="Change Password"/>
                    <CardContent  sx={{ width: "100%", backgroundColor: "primary.main", color: "primary.contrastText", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <FormGroup sx={{width: "80%"}}>
                            <TextField
                                {...register("oldPass", {required: true})}
                                sx={{margin: '5px'}}
                                name="oldPass"
                                label="Current Password *"
                                type={"password"}
                                error={(errors?.oldPass) ? true : false}
                                helperText={(errors?.oldPass) ? "This field is required" : ""}
                            />
                            <TextField
                                {...register("newPass", {required: true})}
                                sx={{margin: '5px'}}
                                name="newPass"
                                label="New Password *"
                                type={"password"}
                                error={(errors?.newPass) ? true : false}
                                helperText={(errors?.newPass) ? "This field is required" : ""}
                            />
                            <TextField
                                {...register("newPassVal", {
                                    required: true,
                                    validate: (value) => {
                                        return value === watch('newPass')
                                    }
                                })}
                                sx={{margin: '5px'}}
                                name="newPassVal"
                                label="Confirm New Password *"
                                type={"password"}
                                error={(errors?.newPassVal) ? true : false}
                                helperText={(errors?.newPassVal) ? "Passwords do not match" : ""}
                            />
                        </FormGroup>
                        <CardActions sx={{cardActionStyles}}>
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{minWidth: "40%"}}
                                onClick={handleSubmit(onSubmit)}> Submit
                            </Button>
                        </CardActions>
                    </CardContent>
                </Card>
            }

          <Modal
              open={showErrorModal}
              onClose={() => setShowErrorModal(false)}
          >
              <Box sx={modalStyle}>
                  <Typography variant="h4" align="center">Error</Typography>
                  <Typography sx={{marginBottom: "16px"}}>
                      There was a server error - please check your inputs and try again.
                      If problem persists, contact support.
                  </Typography>
                  <Button
                      onClick={() => setShowErrorModal(false)}
                      sx={{width: "100%", backgroundColor: "#2e2e2e", color: "primary.contrastText"}}
                  >OK</Button>
              </Box>
          </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={"success"}
                    variant={"filled"}
                    sx={{width: "100%"}}
                >
                    Password Changed Successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MyAccount;