import {createSlice} from "@reduxjs/toolkit";

const newUserSlice = createSlice({
  name: "newuser",
  // Define the initial state for the app
  initialState: {
    user: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      adminChecked: false,
    },
    submitting: false,
  },
  reducers: {
    setNewUserData: (state, action) => {
      state.user = action.payload;
    },
    setNewUserSubmitting: (state, action) => {
      state.submitting = action.payload;
    },
  },
})

export const { 
  setNewUserData,
  setNewUserSubmitting,
} = newUserSlice.actions;

export default newUserSlice.reducer;
