const XLSX = require("xlsx");

    const parseFile = (file,setCb) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, {type:'array'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {header:1,blankrows: false});
        /* Update state */
        setCb(data);
        console.log(data);
        // setCols(make_cols(ws['!ref']))
      };
      reader.readAsArrayBuffer(file);
    };

  module.exports={parseFile};