/* eslint-disable class-methods-use-this */
// import getCookie from "../../utils/helper_functions/getCookie";
const {REACT_APP_API_URL} = process.env;
const validator = require("validator");

export default class userPutters {
  async updateUser(data) {
    const postbody = {
      firstname: data.first,
      lastname: data.last,
      username: data.username,
      userid: data.id,
      email: data.email,
    };
    console.log(postbody);
    try {
      const updateUser = await fetch(`${REACT_APP_API_URL}/users/write/updateUser`, {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          body: JSON.stringify(postbody),
          headers: { 'Content-Type': 'application/json' },
        })
        .then((res) => res.status === 200 ? res.json() : {})
        .then(res => res);
      return updateUser;
    } catch (e) {
      return e;
    }
  }

  async createFCUser(user) {
    const newUser = user;
    if ((newUser.username && newUser.username.length < 3) || !newUser.username) {
      alert("Please enter a valid username.");
      return {ok: false};
    }
    if ((newUser.email && !validator.isEmail(newUser.email)) || !newUser.email) {
      alert("Please enter a valid email.");
      return {ok: false};
    }
    else{
      const out = await fetch(`${REACT_APP_API_URL}/users/write/addUser`, {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userInfo: newUser }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return { ok: false, err: "An unknown error occured" };
        })
        .then((resJS) => {
          if (resJS.message) {
            alert(resJS.message);
          }
          return { ok: true };
        });
      return out;
    }
  }

  async toggleUserActive(userid,val) {
    const postbody = {
      active: val,
      userid: userid,
    };
    console.log(postbody);
    try {
      const updateUser = await fetch(`${REACT_APP_API_URL}/users/write/toggleactive`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(postbody),
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => res.status === 200 ? res.json() : {})
      .then(res => res);
    return updateUser;
    } catch (e) {
      return e;
    }
  }
}