// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blockDataItem_item__3E5Yq {\n  font-size: 14px;\n  margin-right: 50px;\n  margin-bottom: 20px;\n  max-width: 200px;\n  word-wrap: \"wrap\";\n  word-break: \"break-word\";\n}\n.blockDataItem_inline__3IDVE {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  max-width: none;\n  text-align: left;\n}\n.blockDataItem_inline__3IDVE .blockDataItem_top__jkaBw {\n  margin-bottom: 0px !important;\n  margin-right: 20px;\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/atoms/blockDataItem/blockDataItem.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,kBAAkB;AACpB","sourcesContent":[".item {\n  font-size: 14px;\n  margin-right: 50px;\n  margin-bottom: 20px;\n  max-width: 200px;\n  word-wrap: \"wrap\";\n  word-break: \"break-word\";\n}\n.inline {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  max-width: none;\n  text-align: left;\n}\n.inline .top {\n  margin-bottom: 0px !important;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "blockDataItem_item__3E5Yq",
	"inline": "blockDataItem_inline__3IDVE",
	"top": "blockDataItem_top__jkaBw"
};
export default ___CSS_LOADER_EXPORT___;
