import React from "react";
import styles from "./tabView.module.css";

export default (props) => {
  return (
    <div className={styles.tabView}>
      <div className={styles.tabContainer}>
        {props.sections.map((section, i) => {
          return (
            <div
              key={Math.random()}
              className={`${styles.tab} ${
                props.activeItem === i && styles.tabActive
              }`}
              onClick={() => section.setActive(i)}
            >
              {section.title}
            </div>
          );
        })}
      </div>
      <div className={styles.bottomSection}>
        {props.sections.map((section, i) => {
          if (i === props.activeItem) {
            return section.children;
          }
          return null;
        })}
      </div>
    </div>
  );
};
