import React from "react";
import styles from "./blockDataItem.module.css";
export default (props) => {
  return (
    <div
      style={props.style}
      className={props.inline ? `${styles.item} ${styles.inline}` : styles.item}
    >
      <div
        className={styles.top}
        style={{
          marginBottom: 10,
          fontWeight: "800",
          fontSize: 13,
          width: props.topWidth,
        }}
      >
        {props.title}
      </div>
      <div style={{ wordBreak: "break-word", width: props.bottomWidth }}>
        {props.children}
      </div>
    </div>
  );
};
