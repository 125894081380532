import {createSlice} from "@reduxjs/toolkit";
const campaignsSlice = createSlice({
  name: "campaigns",
  // Define the initial state for the app
  initialState: {
    campaigns: {},
  },
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
  },
})

export const { 
  setCampaigns,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;