import {createSlice} from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  // Define the initial state for the app
  initialState: {
    prefs: {
      digest: false,
      toAdd: [],
      toRemove: [],
      subbedTypes: [],
      subbedSpokes: [],
      unSubbedSpokes: [],
    },
    hasDownloaded: false,
    locationList: [], //pulling this here for now, but probably should just pull the location list
    //at a higher level than the server monitor page or this page and use the same array everywhere
  },
  reducers: {
    setNotificationPrefs: (state, action) => {
      state.prefs = action.payload;
      state.hasDownloaded = true;
    },
    setNotificationsHasDownloaded: (state, action) => {
      state.hasDownloaded = action.payload;
    },
    setNotificationsPrefsToAdd: (state, action) => {
      state.prefs.toAdd = action.payload;
    },
    setSpokesToRemove: (state, action) => {
      state.prefs.toRemove = action.payload;
    },
    setLocationList: (state, action) => {
      state.locationList = action.payload;
    },
    setSelectedTypes: (state,action) => {
      state.prefs.subbedTypes = action.payload;
    },
    setSubbedSpokes: (state,action) => {
      state.prefs.subbedSpokes = action.payload;
      state.prefs.toAdd = [];
      const removeSpokes = state.prefs.unSubbedSpokes.filter((spoke) => !action.payload.includes(spoke));
      state.prefs.unSubbedSpokes = removeSpokes;
    },
    removeSubbedSpokes: (state,action) => {
      //filter out 'toRemove' spokes
      const removeSpokes = state.prefs.subbedSpokes.filter((spoke) => !action.payload.includes(spoke));
      state.prefs.subbedSpokes = removeSpokes;
      state.prefs.unSubbedSpokes.push(...action.payload);
      state.prefs.toRemove = [];
    }
  },
})

export const { 
  setNotificationPrefs,
  setNotificationsHasDownloaded,
  setNotificationsPrefsToAdd,
  setSpokesToRemove,
  setLocationList,
  setSelectedTypes,
  setSubbedSpokes,
  removeSubbedSpokes,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;