/* eslint-disable class-methods-use-this */

const {REACT_APP_API_URL} = process.env;

export default class CRMGetters {
  async getContact(contactID) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/read/getContact`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contactID }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  
  async getContacts() {
    const out = await fetch(`${REACT_APP_API_URL}/crm/read/getContacts`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  //
}
