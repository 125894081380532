import {createSlice} from "@reduxjs/toolkit";

const paginateData = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

const sort = (array, key, reverse) => {
  const out = array.sort((a, b) => {
    let x = a[key] ? a[key] : "";
    let y = b[key] ? b[key] : "";
    return x.toString().toUpperCase() < y.toString().toUpperCase()
      ? -1
      : x.toString().toUpperCase() > y.toString().toUpperCase()
      ? 1
      : 0;
  });
  return reverse ? out.reverse() : out;
};

const queryToolSlice = createSlice({
  name: "QueryTool",
  // Define the initial state for the app
  initialState: {
    locationList: [],
    remoteQuery: {
      locations: {},
      text: "",
      isRunning: false,
      results: [],
      paginatedResults: [],
      rightPaneStatus: "both",
      queryNeedsAuth: false,
    },
    authCode: {
      code: "",
      expires: null,
    },
    meta: {
      sortKey: "server",
      sortDir: "asc",
      numRows: 50,
      numPages: 0,
      hasDownloaded: false,
      pageNum: 0,
      hasPaginated: false,
      fetchingServers: false,
      fetchingDups: false,
      exportLocation: "",
      exportType: "",
      activeTab: 0,
    },
  },
  reducers: {
    setQueryLocationIsRunning: (state, action) => {
      const { location, running } = action.payload;
      state.remoteQuery.locations[location].isRunning = running;
    },
    setQueryLocationError: (state, action) => {
      const { location, error } = action.payload;
      state.remoteQuery.locations[location].error = error;
    },
    setQueryLocationResults: (state, action) => {
      const { location, results } = action.payload;
      state.remoteQuery.locations[location].results = results;
    },
    setQueryisRunning: (state, action) => {
      state.remoteQuery.isRunning = action.payload;
    },
    setQueryLocationIsComplete: (state, action) => {
      const { location, isComplete } = action.payload;
      state.remoteQuery.locations[location].isComplete = isComplete;
    },
    setQueryText: (state, action) => {
      state.remoteQuery.text = action.payload;
    },
    toggleQueryLocation: (state, action) => {
      const chosenLocation = action.payload;
      const locationData = {...state.remoteQuery.locations};
      const reducedLocations = locationData[chosenLocation]
        ? Object.keys(locationData).reduce((p, c) => {
            const tmp = {...p};
            if (chosenLocation !== c) {
              tmp[c] = locationData[c];
            }
            return tmp;
          }, {})
        : {...locationData};
      if (!locationData[chosenLocation]) {
        reducedLocations[chosenLocation] = {
          isRunning: false,
          isComplete: false,
          results: [],
          error: null,
        };
      }
      state.remoteQuery.locations = reducedLocations;
    },
    
    setQueryToolNumRows: (state, action) => {
      const num = action.payload;
      const resultsLength = state.remoteQuery.results.length;
      const numRows =
        num > 0 ? 
          num
        : 
          resultsLength < 15 ? 
            resultsLength
          : 
            resultsLength > 15 && resultsLength < 50 ?
              15
            : 
              50;
      state.meta.numRows = numRows;
      state.meta.numPages = Math.ceil(
        state.remoteQuery.results.length / numRows
      );
      state.remoteQuery.paginatedResults = paginateData(
        state.remoteQuery.results,
        Math.ceil(numRows)
      );
    },
    combineQueryResults: (state) => {
      const mappedData = Object.keys(state.remoteQuery.locations)
        .map((i) => {
          const locationResults = state.remoteQuery.locations[i];
          const withServer = locationResults &&  locationResults.results  ? locationResults.results.map((j) => {
            const tm = { ...j };
            tm.server = i;
            return tm;
          }) : [];
          return withServer;
        })
        .reduce((p, c) => {
          const tmp = [...p, ...c];
          return tmp;
        }, []);
      state.remoteQuery.results = [...mappedData];
      const resultsLength = mappedData.length;
      const numRows =
        state.meta.numRows > 0
          ? state.meta.numRows
          : resultsLength < 15
          ? resultsLength
          : resultsLength > 15 && resultsLength < 50
          ? 15
          : 50;
      state.meta.numRows = numRows;
      state.meta.numPages = Math.ceil(
        state.remoteQuery.results.length / numRows
      );
      state.remoteQuery.paginatedResults = paginateData(
        state.remoteQuery.results,
        Math.ceil(numRows)
      );
    },
    setQueryToolRightPane: (state, action) => {
      state.remoteQuery.rightPaneStatus = action.payload;
    },
    setPageNum: (state, action) => {
      const pageNum = action.payload;
      state.meta.pageNum = pageNum < 1 ? 0 : pageNum;
    },
    sortQueryResults: (state, action) => {
      const sortKey = action.payload;
      if (sortKey === state.meta.sortKey) {
        state.meta.sortDir = state.meta.sortDir === "asc" ? "desc" : "asc";
        state.remoteQuery.results = [
          ...sort(
            state.remoteQuery.results,
            sortKey,
            state.meta.sortDir === "asc" ? false : true
          ),
        ];
      } else {
        state.meta.sortDir = "asc";
        state.remoteQuery.results = [
          ...sort(state.remoteQuery.results, sortKey, false),
        ];
      }
      state.remoteQuery.paginatedResults = paginateData(
        state.remoteQuery.results,
        Math.ceil(state.meta.numRows)
      );
      state.meta.sortKey = sortKey;
    },
    setTablePaginated: (state) => {
      state.meta.hasPaginated = true;
    },
    setQueryAuthCode: (state, action) => {
      state.authCode.code = action.payload;
    },
    setQueryAuthCodeExpires: (state, action) => {
      state.authCode.expires = action.payload;
    },
    setQueryNeedsAuth: (state, action) => {
      state.remoteQuery.queryNeedsAuth = action.payload;
    },
    setQueryToolTab: (state, action) => {
      state.meta.activeTab = action.payload;
    },
    setQueryLocations: (state, action) => {
      const locations = action.payload;
      state.locationList = locations && locations.length ? locations : [];
    },
  },
})

export const { 
  setQueryLocationIsRunning,
  setQueryLocationError,
  setQueryLocationResults,
  setQueryisRunning,
  setQueryLocationIsComplete,
  setQueryText,
  toggleQueryLocation,
  setQueryToolNumRows,
  combineQueryResults,
  setQueryToolRightPane,
  setPageNum,
  sortQueryResults,
  setTablePaginated,
  setQueryAuthCode,
  setQueryAuthCodeExpires,
  setQueryNeedsAuth,
  setQueryToolTab,
  setQueryLocations,
} = queryToolSlice.actions;

export default queryToolSlice.reducer;
