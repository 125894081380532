import React from "react";
import styles from "./textInput.module.css";
import { XCircle } from "react-feather";
const validator = require("validator");

export default (props) => {
  const whitelists = {
    numeric: "1234567890",
    ip: "1234567890.",
    alpha: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    alphaNumeric:
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
    hostName: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-",
  };
  return (
    <div style={{ position: "relative" }}>
      <input
        key={props.key && props.key}
        type={props.type === "password" ? "password" : "text"}
        style={props.style}
        className={styles.input}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onKeyDown={(e) => {
          if (e.key === "Enter" && props.onEnter) props.onEnter();
        }}
        onChange={(e) => {
          if (props.whitelist && whitelists[props.whitelist]) {
            props.onChange(
              validator.whitelist(
                e.target.value,
                `${whitelists[props.whitelist]}${
                  props.allowWildCard ? "%" : ""
                }`
              )
            );
          } else {
            props.onChange(e.target.value);
          }
        }}
        value={props.value}
      />
      <div
        className={styles.clearButton}
        onClick={() => props.onChange("")}
        style={{
          display: `${props.value ? "block" : "none"}`,
          right: `${
            props.customRightOffset ? props.customRightOffset : "-20px"
          }`,
        }}
      >
        <XCircle height={16} />
      </div>
    </div>
  );
};
