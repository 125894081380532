import React, { useState, useEffect } from 'react';
import {
  fc_templates,
  fc_molecules,
  fc_atoms
} from '../../ui';

const EditUserModal = (props) => {

  const {
    modalVisible,
    setModalVisible,
    userValues,
    permissionList,
    submitFunction,
    roles,
  }=props;

  // get UI elements
  const {
    UpdatedTabView,
  } = fc_templates;
  const {
    Card,
    Modal,
    PermissionTable,
    FCUserForm,
  } = fc_molecules;
  const {
    Button,
  } = fc_atoms;

  const [activeForm, setActiveForm] = useState(0); //toggles between the FCUserForm and the PermissionTable
  const [formValues, setFormValues] = useState({}); //object to capture values from inputs

  useEffect(() => {
    setFormValues({...userValues});
  },[userValues]);

  const closeModal = () => {
    setModalVisible(false);
    setActiveForm(0);
  };

  return(
    <Modal
      visible={modalVisible}
      onHide={() => closeModal()}
    >
    <Card title="Edit User" style={{ width: 375, height: 600 }}>
      <UpdatedTabView
        headers={[{title: 'General'}, {title: 'Permissions'}]}
        setActiveItem={setActiveForm}
        activeItem={activeForm}
      >
        {
          activeForm === 0 ?
          <FCUserForm
            formValues={formValues}
            setFormValues={setFormValues}
            roles={roles}
          />
          :
          <div>
            <PermissionTable
              permissionList={permissionList}
              userPermissions={formValues.permissions}
              changePermissions={(newPermissions) => {
                const newData = { ...formValues };
                newData.permissions = newPermissions;
                setFormValues(newData);
              }}
            />
          </div>
        }
        <div
          style={{ width: "calc(100% - 20px)" }}
          className="inline_center"
        >
          <Button
            onClick={() => closeModal()}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button onClick={() => submitFunction(formValues)}>
            Save
          </Button>
        </div>
      </UpdatedTabView>
    </Card>
  </Modal>
  );
};

export default EditUserModal;
