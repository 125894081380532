import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { CSVLink } from "react-csv";
import { Box } from "@mui/material"

import userSettings from "../../../fc_src/utils/userSettings/userSettings";

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_molecules,
  fc_atoms
} from '../../../fc_src/ui';

import { 
  setBitLocations,
  setBitLocationsDownloaded,
  setBitLocationsPageSize,
  setBitLocationsPageIndex,
  setBitLocationsSortObj,
  setBitLocationsFilterString,
  setBitLocationsDPSExportData,
  setBitLocationsDPSExportLocation,
} from '../../reducers/locationsSlice';

import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';

export const Locations = () => {

  const {
    Card,
    Table,
  } = fc_molecules;

  const {
    Spinner,
    TextInput,
    Button,
  } = fc_atoms;

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);

  const BitLocations = useSelector((state) => state.Locations.locations, shallowEqual);
  const filterString = useSelector((state) => state.Locations.meta.filterString);
  const pageSize = useSelector((state) => state.Locations.meta.pageSize);
  const pageIndex = useSelector((state) => state.Locations.meta.pageIndex);
  const sortObj = useSelector((state) => state.Locations.meta.sortObj);
  const hasDownloaded = useSelector((state) => state.Locations.meta.hasDownloaded);
  
  const dispatch = useDispatch();

  const bitMgmtAPI = new BitMgmt();

  const dateFormattedForFilename = () => {
    const rightNow = new Date();
    const res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
    return res;
  };

  const getInit = async () => {
    const data = await bitMgmtAPI.getLocations();
    dispatch(setBitLocations(data));
    dispatch(setBitLocationsDownloaded(data));
  };

  const doDPSExports = async (location) => {
    const doExport = await bitMgmtAPI.getDPSExport(location);
    if (doExport.success) {
      // Do the csv download
      dispatch(setBitLocationsDPSExportData(doExport.data));
      dispatch(setBitLocationsDPSExportLocation(location));
    } else {
      alert("There has been an error with the export");
    }
    return true;
  };
  const dpsExportLocation = useSelector(
    (state) => state.Locations.DPSexport.location
  );
  const dpsExportData = useSelector(
    (state) => state.Locations.DPSexport.data
  );

  useEffect(() => {
    if (ActivePage !== "bit_locations") {
      dispatch(setActivePage("bit_locations"));
    }
    if (!hasDownloaded && !BitLocations.length) {
      getInit();
    }
  });

  return (
    <Box>
        <Card
          title="All Locations"
          style={{ height: "calc(100vh - 150px)" }}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          <Box sx={{marginBottom: "8px"}}>
            <TextInput
                customRightOffset={"15px"}
                placeholder="Filter"
                onChange={(val) =>
                    dispatch(setBitLocationsFilterString(val))
                }
                value={filterString}
                style={{ marginRight: 10 }}
            />
          </Box>
          {hasDownloaded ? (
            BitLocations.length ? (
              <Table
                columns={(() => {
                  const colData = [];
                  BitLocations[0] &&
                    Object.keys(BitLocations[0]).forEach((key) => {
                      colData.push({
                        Header: key,
                        accessor: key,
                        customHeader: key.replace("_", " "),
                      });
                    });
                  colData.push({
                    Header: "DPS Export",
                    accessor: "dps_export",
                  });
                  return colData;
                })()}
                data={(() => {
                  let rows = [];
                  if (filterString.replace(/\s/g, "") === "") {
                    rows = BitLocations;
                  } else {
                    BitLocations.forEach((row) => {
                      let hasInserted = false;
                      Object.keys(row).forEach((key) => {
                        if (
                          row[key] &&
                          row[key]
                            .toString()
                            .toLowerCase()
                            .includes(filterString.toLowerCase()) &&
                          !hasInserted
                        ) {
                          rows.push(row);
                          hasInserted = true;
                        }
                      });
                    });
                  }
                  return rows.map((i) => {
                    const tmp = { ...i };
                    tmp.dps_export = (
                      <div>
                        {dpsExportLocation === i.location_name ? (
                          <CSVLink
                            headers={[
                              { label: "Company", key: "@company" },
                              { label: "debt_id", key: "debt_id" },
                              { label: "ssn", key: "ssn" },
                              { label: "lastname", key: "lastname" },
                              { label: "firstname", key: "firstname" },
                              { label: "address", key: "address" },
                              { label: "city", key: "city" },
                              { label: "state", key: "state" },
                              { label: "zip", key: "zip" },
                            ]}
                            data={dpsExportData}
                            target="_blank"
                            filename={`${
                              i.location_name
                            }.${dateFormattedForFilename()}_dps.csv`}
                            newLineSeparator={`\r\n`}
                          >
                            Download {dpsExportData.length} records
                          </CSVLink>
                        ) : (
                          <Button onClick={() => doDPSExports(i.location_name)}>
                            Export
                          </Button>
                        )}
                      </div>
                    );
                    return tmp;
                  });
                })()}
                pageSizeProp={pageSize}
                pageIndexProp={pageIndex}
                sortObjProp={sortObj}
                setPageSizeProp={(val) => {
                  dispatch(setBitLocationsPageSize(val));
                  userSettings.setNewUserSetting(pageSize, {
                    module: "LOCATIONS",
                    type: "SET_BIT_LOCATIONS_PAGE_SIZE",
                    payload: val,
                  });
                }}
                setPageIndexProp={(val) => {
                  dispatch(setBitLocationsPageIndex(val));
                }}
                setSortObjProp={(val) => {
                  dispatch(setBitLocationsSortObj(val));
                }}
              />
            ) : (
              "No data found"
            )
          ) : (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </Card>
    </Box>
  );
};

export default Locations;