/* eslint-disable class-methods-use-this */
const {REACT_APP_API_URL} = process.env;

export default class BitMgmt {
  async getOrganizations() {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getOrganizations`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getPortfolios(
    searchStringPort,
    searchStringPortName,
    searchStringOrgName
  ) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getPortfolios`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchStringPort,
        searchStringPortName,
        searchStringOrgName,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getPortfolioSummary(id, importID) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getPortfolioSummary`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, importID }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getPortfolioDuplicatesNetwork(portfolioID, count = false) {
    const out = await fetch(
      `${REACT_APP_API_URL}/bitmgmt/read/getPortfolioDuplicatesNetwork`,
      {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ portfolioID, count }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  // this seems to be deprecated KS 3/25/22
  async getPortfolioActiveDuplicates(portfolioID, count = false) {
    const out = await fetch(
      `${REACT_APP_API_URL}/bitmgmt/read/getPortfolioActiveDuplicates`,
      {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ portfolioID, count }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  // this seems to be deprecated KS 3/25/22
  async getBitPortfolioDuplicates(id, type) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getPortfolioDuplicates`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, type }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getLocations() {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getLocations`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getLocationPortfolios(searchOptions) {
    const { locationSearchString, portoflioSearchString } = searchOptions;
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getLocationPortfolios`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locationSearchString,
        portoflioSearchString,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getLocationPortfolioStats(locationPortfolioId) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getLocationPortfolioStats`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locationPortfolioId,
      }),
    })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return false;
        })
        .then((resJS) => {
          return resJS;
        });
    return out;
  }

  async getLocationPortfolioStats(locationPortfolioId) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getLocationPortfolioStats`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          locationPortfolioId,
      }),
    })
      .then((res) => {
          if (res.ok) {
              return res.json();
          }
          return false;
      })
      .then((resJS) => {
          return resJS;
      });
    return out;
  }

  async getRemotePortfolioStats(location, portfolioId) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getRemotePortfolioStats`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        location: location,
        portfolioId: portfolioId
      }),
    })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return false;
        })
        .then((resJS) => {
          return resJS;
        });
    return out;
  }

  async getBitPortfolioLocationExport(name) {
    const out = await fetch(
      `${REACT_APP_API_URL}/bitmgmt/read/getLocationPortfolioExports`,
      {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async getBitLocationPortfoliosExportRecords(name) {
    const out = await fetch(
      `${REACT_APP_API_URL}/bitmgmt/read/getBitLocationPortfoliosExportRecords`,
      {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async uploadBitLocationPortfolio(id, location) {
    const timeout = 300000;
    const controller = new AbortController();
    const event = setTimeout(() => controller.abort(), timeout);
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/write/uploadLocationPortfolio`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, location }),
      signal: controller.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return { ok: false };
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((err) => {
        if (err.message === "The user aborted a request.") {
          return { error: "Network timeout" };
        }
        if (err) {
          return { error: err.message };
        }
        return { error: "Unknown error" };
      });
    clearTimeout(event);
    return out;
  }

  async markBitLocationPortfolioLoaded(id) {
    const timeout = 300000;
    const controller = new AbortController();
    const event = setTimeout(() => controller.abort(), timeout);
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/write/markPortfolioLoaded`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
      signal: controller.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return { ok: false };
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((err) => {
        if (err.message === "The user aborted a request.") {
          return { error: "Network timeout" };
        }
        if (err) {
          return { error: err.message };
        }
        return { error: "Unknown error" };
      });
    clearTimeout(event);
    return out;
  }

  async updateLocationPortfolioWorklist(
    location,
    portfolio,
    current_worklist,
    new_worklist,
    account_count
  ) {
    const data = { location, portfolio, current_worklist, new_worklist, account_count };
    const out = await fetch(
      `${REACT_APP_API_URL}/bitmgmt/write/updatePortfolioLocationWorklist`,
      {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          return r;
        }
      });
    return out;
  }

  async getDPSExport(location) {
    const data = { location };
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/generateDPSFileForBTS`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          return r;
        }
      });
    return out;
  }

  async doAccountSearch(accountSearchFilters) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/accountSearch`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accountSearchFilters }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          return r;
        }
      });
    return out;
  }

  async doPostToLocation(account_id) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/write/postAccountToLocation`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          return r;
        }
      });
    return out;
  }
  // bitmgmt/read/getOrganizationByLocationPortfolioId
  async getOrganizationByLocationPortfolioId(locationPortfolioId) {
    const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/read/getOrganizationByLocationPortfolioId`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ locationPortfolioId }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          return r;
        }
      });
    return out;
  }

    // bitmgmt/read/getOrganizationByLocationPortfolioId
    async updateAccountPortfolioLocation(newLocPortId, accountId) {
      const out = await fetch(`${REACT_APP_API_URL}/bitmgmt/write/updateAccountPortfolioLocation`, {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newLocPortId, accountId }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((r) => {
          if (r) {
            return r;
          }
        });
      return out;
    }

    //getting locations for removing active duplicates
    async getSyncLocations() {
      const out = await fetch(`${REACT_APP_API_URL}/spokes/read/getSyncSpokes`, {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return false;
        })
        .then((resJS) => {
          return resJS;
        });
      return out;
    }

  //getting active dup counts and account counts from fullcircle
  async getActiveDupCounts(ssnMatch,cltRefMatch) {
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/fcActiveDupsCheck`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ssnMatch: ssnMatch, cltRefMatch: cltRefMatch}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    console.log(out);
    return out;
  }

  // gets accounts for ONE location
  async getFCcentralAccountsByLocation (matchCriteria,location) {

    const postbody = {};
    postbody.location = location;
    if((matchCriteria.ssn && matchCriteria.clt_ref_no) || matchCriteria.both){
      postbody.ssnMatch = false;
      postbody.cltRefMatch = false;
    } else {
      postbody.ssnMatch = matchCriteria.ssn;
      postbody.cltRefMatch = matchCriteria.clt_ref_no;
    }

    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/fcCentralAccountsByLocation`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postbody),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    console.log(out);
    return out;
  }

  async checkFileAgainstDuplicates(accounts,ssnMatch,cltRefMatch) {
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/checkAccountListForDuplicate`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accounts: accounts,
        ssnMatch: ssnMatch,
        cltRefMatch: cltRefMatch
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    // console.log(out);
    return out;
  }

  async checkSpokeForAccountsFromFile(loc,accounts) {
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/checkSpokeForAccountsFromFile`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({accounts: accounts, location: loc}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    // console.log(out);
    return out;
  }

  async checkPortsForAccountsFromFile(loc,accounts) {
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/checkPortsForAccountsFromFile`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({accounts: accounts, location: loc}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    // console.log(out);
    return out;
  }

  // remote querying the account counts from the spokes
  async locationAccountNumbers(location) {
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/activeDupsLocationsCheck`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({location: location}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    // console.log(out);
    return out;
  }

    // get fc account counts from fc central
    async getPortDupCount(location,ssnMatch,cltRefMatch) {
      const out = await fetch(`${REACT_APP_API_URL}/spokes/read/portFCDupsCheck`, {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location: location,
          ssnMatch: ssnMatch,
          cltRefMatch: cltRefMatch,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return false;
        })
        .then((resJS) => {
          return resJS;
        });
      return out;
    }

    // remote query account counts
    async getSpokePortCountsByLocation(location) {
      const out = await fetch(`${REACT_APP_API_URL}/spokes/read/portAccountsByLocation`, {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({location: location}),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return false;
        })
        .then((resJS) => {
          return resJS;
        });
      return out;
    }

    //pulls specified number of records and updates fc table to match spoke records
    async syncAccountRecords(loc, size, setCb) {
      const out = await fetch(`${REACT_APP_API_URL}/fc_spokes_write/syncAccountRecords`, {
        method: "POST",
        credentials: 'include',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({location: loc, sync_size: size}),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return false;
        })
        .then((resJS) => {
          console.log(resJS);
          if(setCb){
            setCb({location: loc,results: resJS});
          }
        });
      return out;
    }
  
  async importPortfolio(accounts, portInfo) {
    const out = await fetch(`${REACT_APP_API_URL}/portfolios/write/importPortfolio`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({accounts: accounts, portfolio: portInfo}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      });
      console.log('out',out);
    return out;
  }

  async updateLuhnIgnore(port, client, directive) {
    const out = await fetch(`${REACT_APP_API_URL}/portfolios/write/updateLuhnIgnore`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({port_id: port, client: client, directive: directive}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      });
      console.log('out',out);
    return out;
  }

  async checkDuplicatesFromAccountArray (accounts) {
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/dupsCountFromAccountNumberArray`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({accounts: accounts}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      });
    return out;
  }
  
}
