import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "react-feather";
import { setGridLayout } from "../../reducers/appSettingsSlice";
import Button from "../../atoms/button/button";
import styles from "./header.module.css";
import userSettings from "../../utils/userSettings/userSettings";
export const Header = (props) => {
  const [showToggle, setShowToggle] = useState(false);
  const collapsed = useSelector((state) => state.SidePanelToggle);
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const dispatch = useDispatch();
  const updateGridSetting = (newGridLayout) => {
    dispatch(
      setGridLayout({ page: ActivePage, layout: newGridLayout })
    );
    userSettings.setNewUserSetting(null, {
      module: "GRIDS",
      type: `SET_GRID_LAYOUT_${ActivePage}`,
      payload: { page: ActivePage, layout: newGridLayout },
    });
  }
  return (
    <div
      className={
        collapsed ? `${styles.header} ${styles.collapsed}` : `${styles.header}`
      }
    >
      <div className={styles.headerLeft}>{props.headerLeft}</div>
      <div
        className={`${styles.headerRight} ${
          showToggle ? styles.showToggle : ""
        }`}
      >
        {props.headerRight}
        <div
          className={`${styles.slider_toggle} hoverable`}
          onClick={() => setShowToggle(!showToggle)}
        >
          <Grid height={14} className="icon" />
        </div>
        <div className={styles.header_slide}>
          <Button
            onClick={() => {
              updateGridSetting('one_three');
              // dispatch(
              //   setGridLayout({ page: ActivePage, layout: "one_three" })
              // );
              // userSettings.setNewUserSetting(null, {
              //   module: "GRIDS",
              //   type: "SET_GRID_LAYOUT",
              //   payload: { page: ActivePage, layout: "one_three" },
              // });
            }}
            style={{ marginRight: 10 }}
          >
            <img
              src="/g_1_3.svg"
              height={12}
              style={{ transform: "translateY(1px)" }}
              alt="grid_size"
            />
          </Button>
          <Button
            onClick={() => {
              updateGridSetting('three_one');
              // dispatch(
              //   setGridLayout({ page: ActivePage, layout: "three_one" })
              // );
              // userSettings.setNewUserSetting(null, {
              //   module: "GRIDS",
              //   type: "SET_GRID_LAYOUT",
              //   payload: { page: ActivePage, layout: "three_one" },
              // });
            }}
            style={{ marginRight: 10 }}
          >
            <img
              src="/g_1_3.svg"
              height={12}
              style={{ transform: "translateY(1px) scaleX(-1)" }}
              alt="grid_size"
            />
          </Button>
          <Button
            onClick={() => {
              updateGridSetting('one');
              // dispatch(setGridLayout({ page: ActivePage, layout: "one" }));
              // userSettings.setNewUserSetting(null, {
              //   module: "GRIDS",
              //   type: "SET_GRID_LAYOUT",
              //   payload: { page: ActivePage, layout: "one" },
              // });
            }}
            style={{ marginRight: 10 }}
          >
            <img
              src="/g_1.svg"
              height={12}
              style={{ transform: "translateY(1px)" }}
              alt="grid_size"
            />
          </Button>
          <Button
            onClick={() => {
              updateGridSetting('two');
              // dispatch(setGridLayout({ page: ActivePage, layout: "two" }));
              // userSettings.setNewUserSetting(null, {
              //   module: "GRIDS",
              //   type: "SET_GRID_LAYOUT",
              //   payload: { page: ActivePage, layout: "two" },
              // });
            }}
            style={{ marginRight: 10 }}
          >
            <img
              src="/g_2.svg"
              height={12}
              style={{ transform: "translateY(1px)" }}
              alt="grid_size"
            />
          </Button>
          <Button
            onClick={() => {
              updateGridSetting('three');
              // dispatch(setGridLayout({ page: ActivePage, layout: "three" }));
              // userSettings.setNewUserSetting(null, {
              //   module: "GRIDS",
              //   type: "SET_GRID_LAYOUT",
              //   payload: { page: ActivePage, layout: "three" },
              // });
            }}
            style={{ marginRight: 10 }}
          >
            <img
              src="/g_3.svg"
              height={12}
              style={{ transform: "translateY(1px)" }}
              alt="grid_size"
            />
          </Button>
          <Button
            onClick={() => {
              updateGridSetting('four');
              // dispatch(setGridLayout({ page: ActivePage, layout: "four" }));
              // userSettings.setNewUserSetting(null, {
              //   module: "GRIDS",
              //   type: "SET_GRID_LAYOUT",
              //   payload: { page: ActivePage, layout: "four" },
              // });
            }}
            style={{ marginRight: 10 }}
          >
            <img
              src="/g_4.svg"
              height={12}
              style={{ transform: "translateY(1px)" }}
              alt="grid_size"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;