import React from "react";
import styles from "./button.module.css";

export const FCButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      className={
        props.disabled
          ? props.small
            ? `${styles.button} disabled ${styles.small} ${
                props.color && styles[props.color]
              }`
            : `${styles.button} disabled ${props.color && styles[props.color]}`
          : props.small
          ? `${styles.button}  ${styles.small} ${
              props.color && styles[props.color]
            }`
          : `${styles.button} ${props.color && styles[props.color]}`
      }
    >
      {props.children}
    </button>
  );
};

export default FCButton;
