import React, { useState } from "react";
import styles from "./numberInput.module.css";

export default (props) => {
  const [currentVal, setCurrentVal] = useState(props.value);
  return (
    <input
      type="number"
      style={props.style}
      className={styles.input}
      placeholder={props.placeholder}
      value={currentVal}
      onChange={(e) => {
        setCurrentVal(e.target.value);
      }}
      onBlur={(e) => {
        props.onBlur(currentVal);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          props.onEnter && props.onEnter(currentVal);
        }
      }}
    />
  );
};
