/* eslint-disable class-methods-use-this */
const {REACT_APP_API_URL} = process.env;
export default async () => {
  try {
    const checkUserAuth = await fetch(`${REACT_APP_API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({attempt: true}),
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => res.status === 200 ? res.json() : {success: false})
      .then(res => res);
    return checkUserAuth;
  } catch (e) {
    return {isValid: false};
  }
}
