import React from "react";
import {
    Button,
    Chip,
} from '@mui/material'
import theme, {StyledTableCell, StyledTableRow} from "../../theme/theme"
import { Trash, Edit } from "react-feather"
import ContactDataTable from "../../molecules/contactDataTable";

const tableColumns =["Number", "Description", "Status", "Actions"]

const PhoneTable = (props) => {
    const { Phones, editFunction, archivedData, deleteFunction } = props
    const [phones] = Phones

    const rowSnippet = (row) => {
        return (
            <StyledTableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell component="th" scope="row">
                    {row.number}
                </StyledTableCell>
                <StyledTableCell >{row.description}</StyledTableCell>
                <StyledTableCell >
                    {(row.archive === 1) ? <Chip label="Archived" color="warning"/> : <Chip label="Active" color="success" /> }
                    {(row.do_not_contact === 1) ? <Chip  label="Do Not Contact" color="error" /> : ""}
                </StyledTableCell>
                <StyledTableCell >
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{marginRight: "5px"}}
                        onClick={() => {editFunction(row.id)}}>
                        <Edit size={16}/>
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            deleteFunction(row.id)}}>
                        <Trash size={16}/>
                    </Button>
                </StyledTableCell>
            </StyledTableRow>
        )
    }

    return (
        <ContactDataTable
            columns={tableColumns}
            rows={phones}
            editFunction={editFunction}
            deleteFunction={deleteFunction}
            archivedData={archivedData}
            formattedRow={rowSnippet}
        />
    )

}

export default PhoneTable;