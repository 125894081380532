// Pages
import {ServerMonitor as ServerMonitorPage} from './pages/serverMonitor/serverMonitor';
import {HubSpoke as HubSpokePage} from './pages/hubSpoke/HubSpoke';

export const ServerMonitor = ServerMonitorPage;
export const HubSpoke = HubSpokePage;

export const clos_pages = {
  ServerMonitor,
  HubSpoke,
};

export default () => {
  return {
    clos_pages,
  };
};
