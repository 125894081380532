// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_modal_overlay__5s1ut {\n  position: absolute;\n  left: 0;\n  top: 0;\n  background: rgba(0, 0, 0, 1) !important;\n  /* backdrop-filter: blur(10px); */\n  width: 100vw;\n  height: 100vh;\n  z-index: 100001;\n  transform: translateX(-200px);\n  /* animation: fadeIn 0.1s forwards; */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.modal_hidden__3RExV {\n  display: none;\n}\n.modal_is_full__1jOsm {\n  transform: translateX(0px);\n}\n.modal_light__3YZ0G {\n  background: rgba(116, 116, 116, 0.8) !important;\n}\n@keyframes modal_fadeIn__1RLwa {\n  0% {\n    -webkit-backdrop-filter: blur(0px);\n            backdrop-filter: blur(0px);\n    opacity: 0;\n  }\n  100% {\n    -webkit-backdrop-filter: blur(10px);\n            backdrop-filter: blur(10px);\n    opacity: 1;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/molecules/modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,uCAAuC;EACvC,iCAAiC;EACjC,YAAY;EACZ,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,qCAAqC;EACrC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,+CAA+C;AACjD;AACA;EACE;IACE,kCAA0B;YAA1B,0BAA0B;IAC1B,UAAU;EACZ;EACA;IACE,mCAA2B;YAA3B,2BAA2B;IAC3B,UAAU;EACZ;AACF","sourcesContent":[".modal_overlay {\n  position: absolute;\n  left: 0;\n  top: 0;\n  background: rgba(0, 0, 0, 1) !important;\n  /* backdrop-filter: blur(10px); */\n  width: 100vw;\n  height: 100vh;\n  z-index: 100001;\n  transform: translateX(-200px);\n  /* animation: fadeIn 0.1s forwards; */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.hidden {\n  display: none;\n}\n.is_full {\n  transform: translateX(0px);\n}\n.light {\n  background: rgba(116, 116, 116, 0.8) !important;\n}\n@keyframes fadeIn {\n  0% {\n    backdrop-filter: blur(0px);\n    opacity: 0;\n  }\n  100% {\n    backdrop-filter: blur(10px);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_overlay": "modal_modal_overlay__5s1ut",
	"hidden": "modal_hidden__3RExV",
	"is_full": "modal_is_full__1jOsm",
	"light": "modal_light__3YZ0G",
	"fadeIn": "modal_fadeIn__1RLwa"
};
export default ___CSS_LOADER_EXPORT___;
