
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Route, Switch, useHistory, useLocation } from 'react-router-dom';

import {
  fc_pages
} from './modules/fc_src/ui';

import {
  bit_pages
} from './modules/fc_bit_mgmt_mods/ui';

import {
  clos_pages
} from './modules/fc_clos_admin_mods/ui';

import {
  ServerMonitor
} from './modules/fc_clos_admin_mods/ui';

import "./App.css";
import {
  setUserSessionIsAuthenticated,
} from './modules/fc_src/reducers/userSessionSlice';
const {
  Login,
  WebApp,
  CreateContact,
  Contact,
  Contacts,
  QueryTool,
  QueryAuth,
  Users,
  NotificationServices,
  MyAccount,
  Campaigns,
} = fc_pages;

const {
  Organizations,
  Portfolios,
  Locations,
  LocationPortfolios,
  Accounts,
  ActiveDuplicates,
  ActiveDuplicatesFiles,
  PortfolioFile,
} = bit_pages;

const {
  // ServerMonitor,
  HubSpoke,
} = clos_pages;

const App = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const setLoginInfo = (tf) => {
    dispatch(setUserSessionIsAuthenticated(tf));
  };
  const userSessionIsValid = useSelector(
    (state) => state.UserSessions.isAuthenticated
  );
  const userSessionPermissions = useSelector(
    (state) => state.UserSessions.permissions
  );
  useEffect(() => {
    // Session validation redirects
    if(userSessionIsValid === false) {
      history.push('/login');
    } else if(userSessionIsValid === true && location.pathname === '/login') {
      history.push('/serverMonitor');
    }
  },[userSessionIsValid])
  return (
    <Switch>
      <Route
        path="/login"
        exact
        render={() => (
          <Login 
            setLoginInfo={setLoginInfo}
          />
        )}
      />
      <Route path="/">
          <WebApp>
            {/* CRM */}
            {
              userSessionPermissions.CRM &&   
                <Switch>
                  <Route
                    path="/contacts/create"
                    exact
                    render={() => <CreateContact history={history} />}
                  />
                  <Route
                    path="/contacts/contact/:contactUID"
                    exact
                    render={({match}) => <Contact history={history} match={match} />}
                  />
                  <Route
                    path="/contacts/all"
                    exact
                    render={() => <Contacts history={history} />}
                  />
                </Switch>
            }
            {
              userSessionPermissions.CLOS &&   
                <Switch>
                  <Route
                    path="/serverMonitor"
                    exact
                    render={() => (
                      <ServerMonitor history={history} />
                    )}
                  />
                  <Route 
                    path="/hubspoke"
                    exact
                    render={() => (
                      <HubSpoke history={history} />
                    )}
                  />
                  <Route
                    exact
                    path="/queryTool"
                    render={() => (
                      <QueryTool
                        history={history}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/queryAuth/:emailCode"
                    render={({ match }) => (
                      <QueryAuth
                        history={history}
                        match={match}
                      />
                    )}
                  />
                </Switch>
            }

            {
              userSessionPermissions.BitMGMT && 
                <Switch>
                  <Route
                    path="/organizations"
                    exact
                    render={() => <Organizations history={history} />}
                  />
                  <Route
                    path="/portfolios"
                    exact
                    render={() => <Portfolios history={history} />}
                  />
                  <Route
                    path="/locations"
                    exact
                    render={() => <Locations history={history} />}
                  />
                  <Route
                    path="/locationPortfolios"
                    exact
                    render={() => <LocationPortfolios history={history} />}
                  />
                  <Route 
                    path="/accounts"
                    exact
                    render={() => <Accounts history={history} />}
                  />

                  {/* portfolio file analysis page */}
                  <Route
                    path="/portfolioFile"
                    exact
                    render={() => <PortfolioFile history={history} />}
                  />
                  
                </Switch>
            }
            {
              userSessionPermissions.BitMGMT && 
                <Switch>
                  <Route
                    path="/users"
                    exact
                    render={() => <Users history={history} />}
                  />
                  <Route
                    path="/campaigns"
                    exact
                    render={() => <Campaigns history={history} />}
                  />
                </Switch>

            }
            <Switch>
              <Route
                path="/notificationServices"
                exact
                render={() => <NotificationServices history={history} />}
              />
              <Route
                path="/myAccount"
                exact
                render={() => <MyAccount history={history} />}
              />
              <Route
                path="/activeDuplicates"
                exact
                render={() => <ActiveDuplicates history={history} />}
              />
              <Route
                path="/activeDuplicates/files"
                exact
                render={() => <ActiveDuplicatesFiles history={history} />}
              />
            </Switch>
          </WebApp>
      </Route>
    </Switch>
  );
};

export default App;
