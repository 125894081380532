// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".permissionTable_container__3VYMc {\n  width: calc(100% - 41px);\n  background: var(--darkest);\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  padding: 13px;\n  overflow: scroll;\n}\n.permissionTable_table__lbKal {\n  margin-bottom: 20px;\n  text-align: left;\n  vertical-align: middle;\n  border-collapse: collapse;\n}\n.permissionTable_table__lbKal tr {\n  font-size: 13px;\n  height: 32px;\n  line-height: 32px;\n  vertical-align: middle;\n}\n.permissionTable_table__lbKal th {\n  min-width: 70px;\n}\n.permissionTable_table__lbKal td {\n  line-height: 32px;\n  vertical-align: middle;\n  border-bottom: 1px solid var(--dark);\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/molecules/permissionTable/permissionTable.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,mBAAmB;EACnB,oBAAoB;EACpB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,oCAAoC;AACtC","sourcesContent":[".container {\n  width: calc(100% - 41px);\n  background: var(--darkest);\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  padding: 13px;\n  overflow: scroll;\n}\n.table {\n  margin-bottom: 20px;\n  text-align: left;\n  vertical-align: middle;\n  border-collapse: collapse;\n}\n.table tr {\n  font-size: 13px;\n  height: 32px;\n  line-height: 32px;\n  vertical-align: middle;\n}\n.table th {\n  min-width: 70px;\n}\n.table td {\n  line-height: 32px;\n  vertical-align: middle;\n  border-bottom: 1px solid var(--dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "permissionTable_container__3VYMc",
	"table": "permissionTable_table__lbKal"
};
export default ___CSS_LOADER_EXPORT___;
