import { createTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow'

/* TABLE STYLES */
export const tableStyles = {
    minWidth: 650,
    textDecorationColor: "primary.main",
    backgroundColor: "secondary.main",
    color: "primary.main",
}

/* MODAL STYLES*/
export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#333333',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "#fff",
    zIndex: 100,
};

/* CARDACTION STYLES */
export const cardActionStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "16px",
    width: "100%"
}

/* STYLED COMPONENTS */

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.dark,
    },
    [`&.${tableCellClasses.body}`]: {
        color: theme.palette.common.white,
        fontSize: 14,
        borderRadius: 0,
        padding: "none",
        border: "none"
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.secondary.dark,
        border: "none",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.primary.main,
    },
    // hide last border
    '&:last-child td': {
        border: "none",
    },
}));

/* MAIN THEME */
export const theme = createTheme({
    palette: {
        primary: {
            main: "#333333",
            contrastText: "#fff"
        },
        secondary: {
            main:"#2E2E2E",
            dark: "#212121",
            contrastText: "#fff"
        },
        error: {
            main: "#CA111E"
        },
        warning: {
            main: "#FD6910",
            contrastText: "#fff"
        },
        info: {
            main: "#1A63A5"
        },
        infoNeutral: "#fff"
    },
    typography: {
        allVariants: {
            color: "#fff",
            fontFamily: "Lato, sans serif"
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                }
            }
        },
        Card: {
            styleOverrides: {
                margin: "8px",
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
               root: {
                   backgroundColor: "#212121",
                   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                       borderColor: "#fff",
                   }
               }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused ": {
                        color: "#fff"
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: "#333",
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: "#fff"
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                thumb: {
                    color: "#fff"
                },
                track: {
                    border: "1px solid black",
                    backgroundColor: "#333",
                    ".Mui-checked.Mui-checked + &": {
                        backgroundColor: "#212121"
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    backgroundColor: "#333",
                    textDecorationColor: "#333",
                    minWidth: 650,
                    color: "#fff",
                }
            }
        },
        MuiTableRow: {
          styleOverrides: {
              root: {
                  border: "none",
                  '&:nth-of-type(even)': {
                      backgroundColor: "#212121",
                      border: "none",
                  },
                  '&:last-child td': {
                      border: "none",
                  },
              }
          }
        },
        MuiTableCell: {
          styleOverrides: {
              root: {
                color: "#fff",
                borderRadius: 0,
              },
          }
        },
        MUIDataTableHeadCell: {
            styleOverrides:{
                root: {
                    backgroundColor: "#212121",
                    color: "#fff",
                },
                sortActive: { color: "#fff"}
            }
        },
        MUIDataTableRow: {
            styleOverrides: {
                root: {
                    border: "none",
                }
            }
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none"
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#333",
                    color: "#fff"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#fff",
                },
                MuiTableSortLabel: {
                    color: "#fff"
                }
            }
        },
        MUIDataTableFilter: {
            styleOverrides: {
                root: {
                    backgroundColor: "#333",
                },
                title: {
                    color: "#fff"
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: "#fff"
                }
            }
        },
        MUIDataTableViewCol: {
            styleOverrides: {
                root: {
                    backgroundColor: "#333",
                },
                label: {
                    color: "#fff"
                },
                title: {
                    color: "#fff"
                }
            }
        },
        MUIDataTableFilterList: {
            styleOverrides: {
                root: {
                    backgroundColor: "#333",
                    margin: "0",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px"
                },
                chip: {
                    border: "solid 1px #212121"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    color: "#fff"
                }
            }
        },
        MuiList: {
            styleOverrides: {
                padding: {
                    backgroundColor: "#333"
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                menuItem: {
                    "&.Mui-selected": {
                        backgroundColor: "#212121"
                    }
                }
            }
        },
    }
})


export default theme;
