/* eslint-disable class-methods-use-this */
const {REACT_APP_API_URL} = process.env;

export default class FCQueryAPI {
  runRemoteQuery(location, queryText, queryAuthCode, resultsCB) {
    const controller = new AbortController();
    const signal = controller.signal;
    // We need to pass the callback to set to not running here
    // signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 90000);
    const out = fetch(`${REACT_APP_API_URL}/fc_query_read/remoteQuery`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        queryText,
        location,
        accessToken: queryAuthCode,
      }),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return resultsCB(location, [], "Response from server not ok");
        }
      })
      .then((r) => {
        if (r) {
          return resultsCB(location, r.data, null);
        } else {
          return resultsCB(location, [], "Error");
        }
      })
      .catch((error) => {
        resultsCB(location, [], "Query timed out");
      });
    return out;
  }
  async getAuthUsers() {
    const authUsers = await fetch(`${REACT_APP_API_URL}/fc_query_read/listAuthUsers`, {
      method: "GET",
      credentials: 'include',
      mode: 'cors',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          console.log('Error requesting query auth users')
        }
      })
      .catch((error) => {
        console.log(error)
      });
    return authUsers;
  }
  requestRemoteQueryAuth(authUser) {
    const out = fetch(`${REACT_APP_API_URL}/fc_query_read/requestQueryAuth`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authUser: Number(authUser),
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          // We don't have a behavior defined with this request, but we probably should
        }
      });
    return out;
  }
  getAuthAccessCode(emailCode, setCb) {
    const out = fetch(`${REACT_APP_API_URL}/fc_query_read/getAuthAccessCode`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emailCode }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          setCb(r.accessToken);
        }
      });
    return out;
  }
  checkQueryAuthTokenTimeout(accessToken, setCB) {
    // checkAuthCodeTimeout
    const out = fetch(`${REACT_APP_API_URL}/fc_query_read/checkAuthCodeTimeout`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accessToken }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          setCB(r.authTimeout);
        }
      });
    return out;
  }
  getSpokeQueryLocations(setCb) {
    const out = fetch(`${REACT_APP_API_URL}/fc_query_read/getQueryLocations`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r) {
          setCb(r.queryLocations);
        }
      });
    return out;
  }

}
