import {createSlice} from "@reduxjs/toolkit";

const accountSlice = createSlice({
  name: "accountsearch",
  // Define the initial state for the app
  initialState: {
    filters: {
      full_name: '',
      ssn: '',
      client_reference_id: '',
      account_id: '',
    },
    displayTable: {
      pageSize: null,
      pageIndex: null,
      sortObj: {},
    }
  },
  reducers: {
    setSearchFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPageSize: (state, action) => {
      state.displayTable.pageSize = action.payload;
    },
    setPageIndex: (state, action) => {
      state.displayTable.pageIndex = action.payload;
    },
    setSortObj: (state, action) => {
      state.displayTable.sortObj = action.payload;
    },
  },
})

export const { setSearchFilters, setPageSize, setPageIndex, setSortObj } = accountSlice.actions;

export default accountSlice.reducer;
