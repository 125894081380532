import React from "react";
import {
    Button,
    TableCell,
    TableRow,
    Chip,
} from '@mui/material'
import { Trash, Edit } from "react-feather"
import ContactDataTable from "../../molecules/contactDataTable";
import {StyledTableCell, StyledTableRow} from "../../theme/theme";

const tableColumns =["Street 1", "Street 2", "City", "County", "Zip", "State", "Country", "Status", "Actions"]

const AddressTable = (props) => {
    const { Addresses, archivedData, deleteFunction, editFunction } = props
    const [addresses] = Addresses
    const rowSnippet = (row) => {
        return (
            <StyledTableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell component="th" scope="row">{row.street_1}</StyledTableCell>
                <StyledTableCell >{row.street_2}</StyledTableCell>
                <StyledTableCell >{row.city}</StyledTableCell>
                <StyledTableCell >{row.county}</StyledTableCell>
                <StyledTableCell >{row.zip}</StyledTableCell>
                <StyledTableCell >{row.state}</StyledTableCell>
                <StyledTableCell >{row.country}</StyledTableCell>
                <StyledTableCell >
                    {(row.archived === 1) ? <Chip label="Archived" color="warning" /> : <Chip label="Active" color="success" /> }
                    {(row.do_not_contact === 1) ? <Chip color="error" label="Do Not Contact" /> : ""}
                </StyledTableCell>
                <StyledTableCell >
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{marginRight: "5px"}}
                        onClick={() => {
                            editFunction(row.id)}
                        }>
                        <Edit size={16}/>
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            deleteFunction(row.id)}
                        }>
                        <Trash size={16}/>
                    </Button>
                </StyledTableCell>
            </StyledTableRow>
        )
    }

    return (
        <ContactDataTable
            columns={tableColumns}
            rows={addresses}
            editFunction={editFunction}
            deleteFunction={deleteFunction}
            archivedData={archivedData}
            formattedRow={rowSnippet}
        />
    )

}

export default AddressTable;