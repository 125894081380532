import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const locationsSlice = createSlice({
  name: "bit_locations",
  // Define the initial state for the app
  initialState: {
    locations: [],
    DPSexport: {
      location: "",
      data: [],
    },
    meta: {
      hasDownloaded: false,
      pageIndex: 0,
      pageSize: 40,
      sortObj: {
        Header: null,
        isSortedDesc: null,
      },
      filterString: "",
    },
  },
  reducers: {
    setBitLocations: (state, action) => {
      state.locations = action.payload.map((port) => {
        port.created =
          port.created &&
          moment(port.moment_created).format("YYYY/MM/DD h:mm A");
        port.updated =
          port.updated &&
          moment(port.moment_updated).format("YYYY/MM/DD h:mm A");
        port.status = port.status === 1 ? "Active" : "Inactive";
        return port;
      });
    },
    setBitLocationsDownloaded: (state, action) => {
      state.meta.hasDownloaded = true;
    },
    setBitLocationsPageSize: (state, action) => {
      state.meta.pageSize = action.payload;
    },
    setBitLocationsPageIndex: (state, action) => {
      state.meta.pageIndex = action.payload;
    },
    setBitLocationsSortObj: (state, action) => {
      state.meta.sortObj = action.payload;
    },
    setBitLocationsFilterString: (state, action) => {
      state.meta.filterString = action.payload;
      state.meta.pageIndex = 0;
    },
    setBitLocationsDPSExportData: (state, action) => {
      state.DPSexport.data = action.payload;
    },
    setBitLocationsDPSExportLocation: (state, action) => {
      state.DPSexport.location = action.payload;
    },
  },
})

export const { 
  setBitLocations,
  setBitLocationsDownloaded,
  setBitLocationsPageSize,
  setBitLocationsPageIndex,
  setBitLocationsSortObj,
  setBitLocationsFilterString,
  setBitLocationsDPSExportData,
  setBitLocationsDPSExportLocation,
} = locationsSlice.actions;

export default locationsSlice.reducer;
