/* eslint-disable class-methods-use-this */
const {REACT_APP_API_URL} = process.env;

export default class UserSettingsAPI {
  async setUserUISetting(module, settingObj) {
    const data = { settingObj };
    const out = await fetch(`${REACT_APP_API_URL}/settings/write/setFCUserSettings`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => (res.ok ? res.json() : false))
      .then((r) => (r ? r : false));
    return out;
  }
  async getUserUISettings() {
    const out = await fetch(`${REACT_APP_API_URL}/settings/read/getFCUserSettings`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => (res.ok ? res.json() : false))
      .then((r) => (r ? r : false));
    return out;
  }
  //
}
