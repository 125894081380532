// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button .spinner_parent__gFhOk {\n  transform: translateY(2px);\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/atoms/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":["button .parent {\n  transform: translateY(2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": "spinner_parent__gFhOk"
};
export default ___CSS_LOADER_EXPORT___;
