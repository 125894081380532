import {createSlice} from "@reduxjs/toolkit";

const addHoursToNow = (hours, date= new Date()) => {
  const start = Date.now();
  return start + 120000;
};

const userSessionsSlice = createSlice({
  name: "usersessions",
  // Define the initial state for the app
  initialState: {
    isAuthenticated: null,
    permissions: {},
    authTimeout: addHoursToNow(2),
  },
  reducers: {
    setUserSessionIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserSessionPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
})

export const { 
  setUserSessionIsAuthenticated,
  setUserSessionPermissions,
} = userSessionsSlice.actions;

export default userSessionsSlice.reducer;

/*
    case "SET_USER_SESSION_IS_AUTHENTICATED":
      return {...state, isAuthenticated: action.payload}
    case "SET_USER_SESSION_PERMISSIONS":
      return {...state, permissions: action.payload}

      SET_USER_SESSION_IS_AUTHENTICATED
      SET_USER_SESSION_PERMISSIONS
*/