// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabView_tabView__3X5-X {\n  width: 100%;\n}\n.tabView_tabContainer__2vawQ {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n.tabView_tab__1Rbub {\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  border-top: 1px solid var(--darkest);\n  border-left: 1px solid var(--darkest);\n  border-right: 1px solid var(--darkest);\n  margin-right: 10px;\n  padding: 8px 9px 25px 9px;\n  background: var(--darkest);\n  opacity: 0.5;\n  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);\n  font-size: 13px;\n  height: 22px;\n}\n.tabView_tab__1Rbub:hover {\n  cursor: pointer;\n}\n.tabView_tabActive__1bBuH {\n  opacity: 1;\n  border-color: var(--darkest);\n  opacity: 1;\n}\n.tabView_bottomSection__3sbsP {\n  width: 100%;\n  padding-top: 0px;\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/templates/tabView/tabView.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;AACzB;AACA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,oCAAoC;EACpC,qCAAqC;EACrC,sCAAsC;EACtC,kBAAkB;EAClB,yBAAyB;EACzB,0BAA0B;EAC1B,YAAY;EACZ,0CAA0C;EAC1C,eAAe;EACf,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;EACV,4BAA4B;EAC5B,UAAU;AACZ;AACA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".tabView {\n  width: 100%;\n}\n.tabContainer {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n.tab {\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  border-top: 1px solid var(--darkest);\n  border-left: 1px solid var(--darkest);\n  border-right: 1px solid var(--darkest);\n  margin-right: 10px;\n  padding: 8px 9px 25px 9px;\n  background: var(--darkest);\n  opacity: 0.5;\n  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);\n  font-size: 13px;\n  height: 22px;\n}\n.tab:hover {\n  cursor: pointer;\n}\n.tabActive {\n  opacity: 1;\n  border-color: var(--darkest);\n  opacity: 1;\n}\n.bottomSection {\n  width: 100%;\n  padding-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabView": "tabView_tabView__3X5-X",
	"tabContainer": "tabView_tabContainer__2vawQ",
	"tab": "tabView_tab__1Rbub",
	"tabActive": "tabView_tabActive__1bBuH",
	"bottomSection": "tabView_bottomSection__3sbsP"
};
export default ___CSS_LOADER_EXPORT___;
