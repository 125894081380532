import React, { useEffect } from "react"
import {
    Modal,
    Box,
    FormGroup,
    Button,
    TextField,
    Typography,
    MenuItem,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import States from "../../utils/states.json";
import Countries from "../../utils/countries.json";
import {modalStyle} from "../../theme/theme";

export const AddEditAddressModal = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    const {modalOpen, setModalOpen, addFunction, editFunction, editID, editData, setActiveAddressEditId} = props

    const handleClose = () => {
        setActiveAddressEditId(-1)
        setModalOpen(false)
    }
    const isEdit = (editID !== -1)
    const onErrors = (errors) => console.error(errors)
    const submitAddress = (data) => {
        /* React + RHF really don't like hidden inputs, so we manually add the 'verified' field before submitting */
        let submissionData = {...data, verified: false}
        if (isEdit){
            submissionData = {...submissionData,  id: editID}
            editFunction(submissionData)
        } else {
            addFunction(submissionData)
        }
    }
    const fields = ["street_1", "street_2", "city", "state", "county", "zip", "country", "do_not_contact", "archived"]

    useEffect(() => {
        if (editID === -1){
            fields.forEach(field => {
                if (field !== "do_not_contact" && field !== "archived"){
                    setValue(field, "")
                } else {
                    setValue(field, false)
                }
            })
        }
        /* We're doing an explicit check on editID rather than isEdit to avoid weird state issues */
        if (editID !== -1){
            const addressEntry = editData.filter((entry) => {return entry.id === editID})
            fields.forEach(field => {
                    /* FOR SOME REASON, boolean values are returned as 0/1 and MUI doesn't know what to do with those */
                    if (field === "do_not_contact" || field === "archived"){
                        if (addressEntry[0][field] === 1){
                            setValue(field, true)
                        } else {
                            setValue(field, false)
                        }
                    } else {
                        setValue(field, addressEntry[0][field]);
                    }
                }
            )
        }
    }, [editID, modalOpen])


    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
        >
            <Box sx={modalStyle}>
                <Typography variant='h4' align="center">{isEdit ? "Edit Address" : "Add Address"}</Typography>
                <FormGroup>
                    <TextField
                        {...register('street_1', {
                            required: true,
                        })}
                        sx={{margin: '5px'}}
                        name="street_1"
                        label="Address Line 1"
                        error={(errors?.street_1) ? true : false}
                        helperText={(errors?.street_1) ? "Please enter a valid address" : ""}
                    />
                    <TextField
                        {...register('street_2')}
                        sx={{margin: '5px'}}
                        name="street_2"
                        label="Address Line 2"
                    />
                    <TextField
                        {...register('city', {
                            required: true,
                        })}
                        sx={{margin: '5px'}}
                        name="city"
                        label="City"
                        error={(errors?.city) ? true : false}
                        helperText={(errors?.city) ? "Please enter a valid city" : ""}
                    />
                    <TextField
                        {...register('county')}
                        sx={{margin: '5px'}}
                        name="county"
                        label="County"
                    />
                    <TextField
                        {...register('state')}
                        sx={{margin: '5px'}}
                        name="state"
                        label="State"
                        displayempty
                        select
                    >
                        {States.map((state) => (
                            <MenuItem  key={state["alpha-2"]} value={state["alpha-2"]}>{state["alpha-2"]}</MenuItem>
                        ))}
                    </TextField>
                    {/*Do we want to encourage people to put in the +4 digits? Maybe some help text? https://tools.usps.com/zip-code-lookup.htm?byaddress */}
                    <TextField
                        {...register('zip', {
                            required: true,
                            pattern: /^\d{5}(?:[-\s]\d{4})?$/
                        })}
                        sx={{margin: '5px'}}
                        name="zip"
                        label="Zip"
                    />
                    <TextField
                        {...register('country')}
                        sx={{margin: '5px'}}
                        name="country"
                        label="Country"
                        displayEmpty
                        select
                    >
                        {Countries.map((country) => (
                            <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>
                        ))}
                    </TextField>
                    <Controller
                        name="do_not_contact"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`do_not_contact`)}
                                label="Do Not Contact"
                            />
                        )}
                    />
                    <Controller
                        name="archived"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`archived`)}
                                label="Archived"
                            />
                        )}
                    />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "16px"}}>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(submitAddress, onErrors)}> Save
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            sx={{color: "#fff"}}
                            onClick={handleClose}> Cancel
                        </Button>
                    </div>
                </FormGroup>
            </Box>
        </Modal>
    );
}

export default AddEditAddressModal;