import {createSlice} from "@reduxjs/toolkit";

const appSettingsSlice = createSlice({
  name: "appsettings",
  // Define the initial state for the app
  initialState: {
    ActivePage: 'servermonitor',
    gridLayout: {
      server_monitor: "three_one",
      account_search: "one",
      active_duplicates: "one_three",
      user_manager: "one",
      account_view: "two",
      phone_search: "one_three",
      notification_services: "three_one",
      view_contact: "two",
      bit_locations: "two",
      contacts: "one",
      location_portfolios: "one",
      organizations: "one",
      portfolios: "one"
    },
    sidePanelToggle: false,
  },
  reducers: {
    setActivePage: (state, action) => {
      state.ActivePage = action.payload;
    },
    setGridLayout: (state, action) => {
      const {page, layout} = action.payload;
      state.gridLayout[page] = layout;
    },
    toggleSidePanel: (state, action) => {
      state.sidePanelToggle = state.sidePanelToggle ? false : true;
    }
  },
})

export const { 
  setActivePage,
  setGridLayout,
  toggleSidePanel,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
