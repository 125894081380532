import React from "react";
import styles from "./card.module.css";
import Spinner from "../../atoms/spinner/spinner";

export const Card = (props) => {
  return (
    <div
      className={`
        ${styles.card}
        ${props.isTranslucent && styles.isTranslucent}
        ${props.tabOptions && styles.tab_card}
        ${props.isModal && styles.isModal}
      `}
      style={props.style}
    >
      <div className={styles.card_top}>
        <div className={styles.card_top_title}>{props.title}</div>
        <div className={styles.card_tab_container}>
          {props.tabOptions?.map((el, i) => (
            <div
              className={`${styles.tab_option} ${
                el.active && styles.tab_option_active
              }`}
              active={el.active}
              onClick={() => props.onTabChange(i)}
            >
              {el.title}
            </div>
          ))}
        </div>
        <div className={styles.card_top_right}>{props.topRight}</div>
      </div>
      <div
        className={styles.card_body}
        style={props.bodyStyle && props.bodyStyle}
      >
        {props.tabOptions && props.tabOptions.length
          ? props.tabOptions?.map((el) => el.active && el.children)
          : props.children}
      </div>
      {props.bottomButtons && (
        <div className={styles.bottom_buttons}>
          <button
            onClick={props.onCancel}
            className={`${styles.bottom_button} ${styles.bottom_button_cancel} hoverable`}
          >
            Cancel
          </button>
          <button
            disabled={props.fetching}
            onClick={props.onSave}
            className={`${styles.bottom_button} ${styles.bottom_button_save} hoverable`}
          >
            {props.fetching ? <Spinner /> : "Save"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Card;