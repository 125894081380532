import React from "react";
import styles from "./grid.module.css";

export default (props) => {
  return (
    <div
      className={`${styles.grid} ${styles[props.gridSize]} ${
        props.isCard ? styles.is_card : ""
      }`}
      style={props.style && props.style}
    >
      {props.children}
      <br />
      {props.isCard || props.hideSpacer ? (
        ""
      ) : (
        <div style={{ width: "100%", height: 50 }} />
      )}
    </div>
  );
};
