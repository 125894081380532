import React, { useEffect } from "react"
import {
    Modal,
    Box,
    FormGroup,
    FormControlLabel,
    Button,
    TextField,
    Typography,
    Switch,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import {modalStyle} from "../../theme/theme";

export const AddEditEmailModal = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    const {modalOpen, setModalOpen, addFunction, editFunction, editID, editData, setActiveEmailEditId} = props

    const handleClose = () => {
        setActiveEmailEditId(-1)
        setModalOpen(false)
    }
    const isEdit = (editID !== -1)
    const onErrors = (errors) => console.error(errors)
    const submitEmail = (data) => {
        let submissionData = {...data, verified: false}
        if (isEdit){
            submissionData = {...submissionData, email_id: editID}
            editFunction(submissionData)
        }else {
            addFunction(submissionData)
        }
    }

    useEffect(() => {
        const fields = ["email_address", "description", "do_not_contact", "archive"]

        /* In the case of an edit cancel, then opening up "Add", clear out all fields*/
        if (editID === -1){
            fields.forEach(field => {
                if (field !== "do_not_contact" && field !== "archive"){
                    setValue(field, "")
                } else {
                    setValue(field, false)
                }
            })
        }
        /* We're doing an explicit check on editID rather than isEdit to avoid weird state issues */
        if (editID !== -1){
            const emailEntry = editData.filter((entry) => {return entry.email_id === editID})
            fields.forEach(field => {
                    /* FOR SOME REASON, boolean values are returned as 0/1 and MUI doesn't know what to do with those */
                    if (field === "do_not_contact" || field === "archive"){
                        if (emailEntry[0][field] === 1){
                            setValue(field, true)
                        } else {
                            setValue(field, false)
                        }
                    } else {
                        setValue(field, emailEntry[0][field]);
                    }
                }
            )
        }
    }, [editID, modalOpen])

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
        >
            <Box sx={modalStyle}>
                <Typography variant='h4' align="center">{isEdit ? "Edit Email" : "Add Email"}</Typography>
                <FormGroup>
                    <TextField
                        {...register('email_address',
                            { required: true,
                              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                        name="email_address"
                        sx={{margin: '5px'}}
                        label="Email Address"
                        placeholder="email@address.com"
                        required={true}
                        error={(errors?.email_address) ? true : false}
                        helperText={(errors?.email_address) ? "Please enter a valid email address" : ""}
                    />
                    <TextField
                        {...register('description')}
                        name="description"
                        sx={{margin: '5px'}}
                        label="Description"
                    />
                    <Controller
                        name="do_not_contact"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`do_not_contact`)}
                                label="Do Not Contact"
                            />
                        )}
                    />
                    <Controller
                        name="archive"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    sx={{margin: '5px'}}
                                />}
                                {...register(`archive`)}
                                label="Archived"
                            />
                        )}
                    />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "16px"}}>
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit(submitEmail, onErrors)}> Save
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            sx={{color: "#fff"}}
                            onClick={handleClose}> Cancel
                        </Button>
                    </div>
                </FormGroup>
            </Box>
        </Modal>
    )
}

export default AddEditEmailModal;