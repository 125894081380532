// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".portfolioFile_button__2bDWN {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  padding-top: 5px;\n  border-radius: 2px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 14px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}\n\n.portfolioFile_list__2hdwN {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 5;\n}\n\n.portfolioFile_footer__3TM8R {\n  font-weight: bold;\n}\n\n.portfolioFile_footer__3TM8R td {\n  border-top: 1px solid var(--medium);\n}\n\n.portfolioFile_number__35g7P {\n  display: block;\n  text-align:right;\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_bit_mgmt_mods/pages/portfolioFile/portfolioFile.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;EAChD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".button {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  padding-top: 5px;\n  border-radius: 2px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 14px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}\n\n.list {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 5;\n}\n\n.footer {\n  font-weight: bold;\n}\n\n.footer td {\n  border-top: 1px solid var(--medium);\n}\n\n.number {\n  display: block;\n  text-align:right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "portfolioFile_button__2bDWN",
	"list": "portfolioFile_list__2hdwN",
	"footer": "portfolioFile_footer__3TM8R",
	"number": "portfolioFile_number__35g7P"
};
export default ___CSS_LOADER_EXPORT___;
