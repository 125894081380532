
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import styles from './portfolioFile.module.css';

// got them UI modules
import {
  fc_organisms,
  fc_molecules,
  fc_atoms,
  fc_templates,
} from '../../../fc_src/ui';

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import Table from '../../organisms/activeDupsTable/activeDupsTable';

import { setActivePage } from '../../../fc_src/reducers/appSettingsSlice';
import { parseToJSON } from '../../utils/helpers/parseToJSON';
import { 
  setBadAccounts,
  setCreditorStratum,
  setRangesData,
  setPortfolioStats,
  setImportedTotals,
  setActiveView,
  setCreditorLuhnStats,
  updateLuhnIgnore,
  setPortId,
} from '../../reducers/portfolioFile/portfolioFileSlice';
import PortfolioLuhnTable from '../../organisms/portfolioLuhnTable/portfolioLuhnTable';

const PortfolioFile = () => {
  const {
    Page,
    Grid,
  } = fc_organisms;

  const {
    Card,
  } = fc_molecules;

  const {
    Spinner,
    TextInput,
    Button,
    BlockDataItem,
  } = fc_atoms;

  const {
    UpdatedTabView,
  } = fc_templates;

  const bitMgmtAPI = new BitMgmt();

  const ActivePage = useSelector((state) => state.ActivePage);

  const portId = useSelector(state => state.PortfolioFile.portId);
  const ranges = useSelector(state => state.PortfolioFile.rangesData);
  const creditors = useSelector(state => state.PortfolioFile.creditorStratum);
  const badAccountStats = useSelector(state => state.PortfolioFile.badAccounts);
  const portfolioOverview = useSelector(state => state.PortfolioFile.portfolioStats);
  const luhnStats = useSelector(state => state.PortfolioFile.creditorLuhnStats);
  const importedAccountTotals = useSelector(state => state.PortfolioFile.importedTotals);
  const activeView = useSelector(state => state.PortfolioFile.activeView);

  const [allAccounts,setAllAccounts] = useState([]);
  const [tableSize,setTableSize] = useState(30);
  const [tableIndex,setTableIndex] = useState(0);
  const [activeDupsCount, setActiveDupsCount] = useState({count: 0, loading: false});
  const [rangesLoading, setRangesLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    file_name: '',
    portfolio_seller: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (ActivePage !== "portfolio_file") {
      dispatch(setActivePage("portfolio_file"));
    }
  },[]);

  const handleFileSelection = (e) => {
		const files = e.target.files;
		if(files && files[0]) {
      setUserInput({portfolio_seller: '', file_name: files[0].name});
      parseToJSON(files[0],setAllAccounts,['account','client','balance','co_date','state']);
    }
	};

  const importAccounts = async (accounts) => {
    setRangesLoading(true);
    const res = await bitMgmtAPI.importPortfolio(accounts,userInput);
    dispatch(setPortId(res.validation_id));
    dispatch(setRangesData(res.ranges));
    dispatch(setCreditorStratum(res.creditors));
    dispatch(setBadAccounts(res.badAccountTypes));
    dispatch(setPortfolioStats(calculatePortfolioStats(accounts.length, res.badAccountTypes, res.totals.accounts)))
    dispatch(setImportedTotals(res.totals));
    dispatch(setCreditorLuhnStats(res.validity));
    checkForActiveDuplicates(accounts);
    console.log('res',res);
    setRangesLoading(false);
  };

  const calculatePortfolioStats = (allAccounts, badAccounts, goodAccounts) => {
    const stats = {};
    stats.total_imported = goodAccounts;
    stats.total_accounts = allAccounts;

    Object.keys(badAccounts).map((i) => {
      const percent = ((allAccounts - badAccounts[i]) / allAccounts) * 100;
      // this is to deal with the rounding when it's something like 99.99998%
      if(percent >= 99.99 && percent < 100){
        return stats[i] = `99.99%`;
      } else {
        return stats[i] = `${percent.toFixed(2)}%`;
      }
    });
    console.log('port stats',stats);
    return stats;
  };

  const formatNumbers = (num, min=2, max=2) => {
    return Number(num).toLocaleString('en-US', {minimumFractionDigits: min, maximumFractionDigits: max});
  };

  const checkForActiveDuplicates = async (allAccounts) => {
    setActiveDupsCount({...activeDupsCount, loading: true});

    // make accounts into an array of strings
    const accountNumberArray = allAccounts.map((item) => item.account ? (item.account).toString() : null);
    if(!accountNumberArray.length) {
      setActiveDupsCount({count: 0, loading: false});
      return;
    }
    const duplicates = await bitMgmtAPI.checkDuplicatesFromAccountArray(accountNumberArray);
    setActiveDupsCount({count: duplicates.dup_count, loading: false});
  };

  const toggleLuhn = (id,val,updated) => {
    console.log('client, luhn ignore',id,val);
    dispatch(updateLuhnIgnore({client_id: id, luhn_directive: val, updated_bool: updated}));
  };

  const submitDirectiveUpdate = async (id,val,updated) => {
    dispatch(updateLuhnIgnore({client_id: id, luhn_directive: val, updated_bool: 'loading'}));
    await bitMgmtAPI.updateLuhnIgnore(portId,id,val);

    dispatch(updateLuhnIgnore({client_id: id, luhn_directive: val, updated_bool: false}));

  };

  return (
    <Page>
      <Card
        title={'Portfolio Import and Analysis'}
      >
        <Grid gridSize={'one_three'}>
          <div>
            <Card style={{marginBottom: 10}}>
               <div 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <label className={styles.button} htmlFor='upload_file'>
                  <input
                    type="file"
                    id="upload_file"
                    accept={".xlsx, .xlsb, .xlsm, .xls, .xml, .csv"}
                    onChange={handleFileSelection}
                    style={{display: 'none'}}
                  />
                    Upload
                </label>
                <TextInput
                  value={userInput.file_name}
                  onChange={(val) => setUserInput({...userInput, file_name: val})}
                />
              </div>
              <BlockDataItem
                title="Seller"
                style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}
              >
              <TextInput
                value={userInput.portfolio_seller}
                onChange={(val) => setUserInput({...userInput, portfolio_seller: val})}
                style={{marginLeft: 30}}
              />
              </BlockDataItem>
              <BlockDataItem
                title="Purchase Price:"
              />
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  onClick={() => importAccounts(allAccounts)}
                  disabled={!allAccounts.length}
                >
                  Import
                </Button>
              </div>

            </Card>
            { rangesLoading ? null
            : portfolioOverview && Object.keys(portfolioOverview).length ? 
            <Card
              title={'File Stats'}
            >
              { portfolioOverview && Object.keys(portfolioOverview).length ?
                <div>
                  <div
                    className={styles.list}
                    style={{fontWeight: 'bold', marginBottom: 10}}
                  >
                    <span>Accounts Imported </span>
                    <span>{portfolioOverview.total_imported ? formatNumbers(portfolioOverview.total_imported,0,0) : null}</span>
                  </div>
                  
                  <div style={{marginBottom: 10}}>
                    {Object.keys(portfolioOverview).map((i) => {
                      if(i !== 'total_imported' && i !== 'total_accounts' && i !== 'total'){
                        return (
                          <div className={styles.list}>
                            <span key={i}>{`${i.toUpperCase().replace('_',' ')}`}</span>
                            <span>{`${portfolioOverview[i]}`}</span>
                          </div>
                        );
                      }
                    })
                    }
                  </div>
                  <div
                    className={styles.list}
                    style={{fontWeight: 'bold', marginBottom: 10}}
                  >
                    <span>Accounts Not Imported </span>
                    <span>{badAccountStats.total}</span>
                  </div>
                  <div>
                    {Object.keys(badAccountStats).map((i) => {
                      if(badAccountStats[i] > 0 && i !== 'total'){
                        return (
                          <div className={styles.list}>
                            <span>{`${i.toUpperCase().replace('_',' ')}`}</span>
                            <span>{`${badAccountStats[i]}`}</span>
                          </div>
                        );
                      } else return;
                    })}
                  </div>
                  <div
                    className={styles.list}
                    style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}
                  >
                    <span>Active Duplicates </span>
                    <span>
                      { activeDupsCount.loading ?
                        <Spinner />
                        : activeDupsCount.count }
                    </span>
                  </div>
                  <div 
                    className={styles.list}
                    style={{
                      fontWeight: 'bold',
                      borderTop: '1px solid var(--medium)'
                    }}
                  >
                    <span>Total Accounts </span>
                    <span>{portfolioOverview.total_accounts ? formatNumbers(portfolioOverview.total_accounts,0,0) : null}</span>
                  </div>
                </div>
                : <div>Upload a file to view stats...</div>
              }

            </Card>
            : null
            }
          </div>
          <div>

        { rangesLoading ?
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          : ranges && ranges.length ? 

            <UpdatedTabView
              activeItem={activeView}
              setActiveItem={(index) => {
                dispatch(setActiveView(index));
              }}
              headers={[
                {title: 'Primary'},
                {title: 'Validation'},
              ]}
            >
              {activeView === 0 ?
                <div>
                  <div style={{height: 430, position: 'relative', marginTop: 10}}>
                    <Table
                      pageIndexProp={tableIndex}
                      pageSizeProp={tableSize}
                      setPageSizeProp={setTableSize}
                      setPageIndexProp={setTableIndex}
                      data={ranges}
                      sortObjProp={{
                        Header: 'range',
                        isSortedDesc: false,
                      }}
                      setSortObjProp={() => null}
                      columns={[
                        {
                          Header: 'range',
                          accessor: 'range',
                          customHeader: 'Range',
                          Cell: ({row}) => {
                            const {original} = row;
                            const rangeString =
                              original.range === 1 ? `0-250`
                              : original.range === 2 ? '250-500'
                              : original.range === 3 ? '500-1000'
                              : original.range === 4 ? '1000-2500'
                              : original.range === 5 ? '2500-5000'
                              : original.range === 6 ? '5000-10000'
                              : original.range === 7 ? '10000-15000'
                              : '15000+';

                            return (
                              <span>{`${ranges.indexOf(original) + 1}. ${rangeString}`}</span>
                            );
                          }
                        },
                        {
                          Header: 'accounts',
                          accessor: 'accounts',
                          customHeader: 'Accounts',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.accounts,0,0)}</span>
                            );
                          }
                        },
                        {
                          Header: 'total_princ',
                          accessor: 'total_princ',
                          customHeader: 'Principle - Total',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.total_princ)}</span>
                            );
                          }
                        },
                        {
                          Header: 'avg_princ',
                          accessor: 'avg_princ',
                          customHeader: 'Principle - Average',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.avg_princ)}</span>
                            );
                          }
                        },
                        {
                          Header: 'med_princ',
                          accessor: 'med_princ',
                          customHeader: 'Principle - Median',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.med_princ)}</span>
                            );
                          }
                        },
                        {
                          Header: 'avg_co',
                          accessor: 'avg_co',
                          customHeader: 'C/O Average',
                          Cell: ({row}) => {
                            const {original} = row;
                            const average_co = original.avg_co ? format(parseISO(original.avg_co), 'MM/dd/yyyy') : null;
                            return (
                              <span>{average_co}</span>
                            );
                          }
                        },
                        {
                          Header: 'med_co',
                          accessor: 'med_co',
                          customHeader: 'C/O Median',
                          Cell: ({row}) => {
                            const {original} = row;
                            const median_co = original.med_co ? format(parseISO(original.med_co), 'MM/dd/yyyy') : null;
                            return (
                              <span>{median_co}</span>
                            );
                          }
                        },
                      ]}
                      footer={
                        <tfoot className={styles.footer}>
                          <tr>
                            <td>Total Result</td>
                            <td align='right'>{formatNumbers(importedAccountTotals.accounts,0,0)}</td>
                            <td align='right'>{formatNumbers(importedAccountTotals.total_princ)}</td>
                            <td align='right'>{formatNumbers(importedAccountTotals.avg_princ)}</td>
                            <td align='right'>{formatNumbers(importedAccountTotals.med_princ)}</td>
                            <td>{format(parseISO(importedAccountTotals.avg_co), 'MM/dd/yyyy')}</td>
                            <td>{format(parseISO(importedAccountTotals.med_co), 'MM/dd/yyyy')}</td>
                          </tr>
                        </tfoot>
                      }
                    />
                  </div>
                  <div style={{height: 500, position: 'relative', marginTop: 25}}>
                    <Table
                      pageIndexProp={tableIndex}
                      pageSizeProp={tableSize}
                      setPageSizeProp={setTableSize}
                      setPageIndexProp={setTableIndex}
                      sortObjProp={{
                        Header: 'client',
                        isSortedDesc: false,
                      }}
                      setSortObjProp={() => null}
                      columns={[
                        {
                          Header: 'client',
                          accessor: 'client',
                          customHeader: 'Creditors',
                        },
                        {
                          Header: 'accounts',
                          accessor: 'accounts',
                          customHeader: 'Accounts',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.accounts,0,0)}</span>
                              );
                          }
                        },
                        {
                          Header: 'total_princ',
                          accessor: 'total_princ',
                          customHeader: 'Principle - Total',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.total_princ)}</span>
                            );
                          }
                        },
                        {
                          Header: 'avg_princ',
                          accessor: 'avg_princ',
                          customHeader: 'Principle - Average',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.avg_princ)}</span>
                            );
                          }
                        },
                        {
                          Header: 'med_princ',
                          accessor: 'med_princ',
                          customHeader: 'Principle - Median',
                          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
                          Cell: ({row}) => {
                            const {original} = row;
                            return (
                              <span className={styles.number}>{formatNumbers(original.med_princ)}</span>
                            );
                          }
                          
                        },
                        {
                          Header: 'avg_co',
                          accessor: 'avg_co',
                          customHeader: 'C/O Average',
                          Cell: ({row}) => {
                            const {original} = row;
                            const average_co = original.avg_co ? format(parseISO(original.avg_co), 'MM/dd/yyyy') : null;
                            return (
                              <span>{average_co}</span>
                            );
                          }
                        },
                        {
                          Header: 'med_co',
                          accessor: 'med_co',
                          customHeader: 'C/O Median',
                          Cell: ({row}) => {
                            const {original} = row;
                            const median_co = original.med_co ? format(parseISO(original.med_co), 'MM/dd/yyyy') : null;
                            return (
                              <span>{median_co}</span>
                            );
                          }
                        },
                      ]}
                      data={creditors}
                    />
                  </div>
                </div>

                : activeView === 1 && luhnStats && luhnStats.length ?
                  <div style={{marginTop: 10, position: 'relative'}}>
                    <PortfolioLuhnTable
                      data={luhnStats}
                      toggleLuhn={toggleLuhn}
                      submitDirectiveUpdate={submitDirectiveUpdate}
                    />
                  </div>
                : null
              }

            </UpdatedTabView>
          : null
          } 
          </div>
        </Grid>
      </Card>
    </Page>
  );
};

export default PortfolioFile;


// created 04/18/2022
// author - Kari Shortell