import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './activeDuplicates.module.css';
import doExport from '../../../fc_src/utils/exports/fileExport';
import moment from 'moment';
import { Box } from "@mui/material"

// API interface
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

//src modules
import {
  fc_organisms,
  fc_molecules,
  fc_atoms,
} from '../../../fc_src/ui';
import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';
import {
  setFCViewLocation,
  updateMatchCriteria,
} from '../../reducers/activeDuplicatesSlice';

import Table from '../../organisms/activeDupsTable/activeDupsTable';
import { parseFile } from '../../utils/helpers/parseFile';
import ActiveDupsMatchModal from '../../organisms/activeDupsMatchModal/activeDupsMatchModal';

const ActiveDuplicatesFiles = () => {
  const {
    Grid,
  } = fc_organisms;

  const {
    Card,
  } = fc_molecules;

  const {
    Spinner,
    Button,
  } = fc_atoms;

  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();

  //all locations available to sync
/*  const locationList = useSelector((state) => state.ActiveDuplicates.locationList);*/

  const viewLocation = useSelector(state => state.ActiveDuplicates.fileChecker.viewLocation);

  // active page for the whole application
  const ActivePage = useSelector((state) => state.ActivePage);

  // match criteria for duplicates
  const matchToggle = useSelector(state => state.ActiveDuplicates.matchToggle);

  //pull the initial list of sync enabled locations
  useEffect(() => {
    if (ActivePage !== "active_duplicates_files") {
      dispatch(setActivePage("active_duplicates_files"));
    }
  },[]);

  const [locationPorts,setLocationPorts] = useState([]);
  const [portsLoading,setPortsLoading] = useState(false);
  const [locationsLoading,setLocationsLoading] = useState(true);
  const [portsTableSize,setPortsTableSize] = useState(20);
  const [locationTableSize,setLocationTableSize] = useState(20);
  const [portsTableIndex,setPortsTableIndex] = useState(0);
  const [locationTableIndex,setLocationTableIndex] = useState(0);

	const [accountNumbers, setAccountNumbers] = useState([]);
  const [duplicates,setDuplicates] = useState([]);
  const [file,setFile] = useState({});
  const [exportData,setExportData] = useState([]);
  const [tableData,setTableData] = useState([]);
  const [fullDupsRes,setFullDupsRes] = useState([]);

  const [modal,setModal] = useState(false);

  const handleChange = (e) => {
		const files = e.target.files;
		if(files && files[0]) {
      setLocationsLoading(true);

      // clear out any previous state
      setFullDupsRes([]);
      setTableData([]);
      setDuplicates([]);
      dispatch(setFCViewLocation({}));
      setLocationPorts([]);

      parseFile(files[0],setAccountNumbers);
      setFile({file_name: files[0].name});
    }
	};

  //accepted file types for the file input
  const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv"
  ].map(x => `.${x}`).join(",");

  const checkAccountsAgainstFC = async () => {
    setLocationsLoading(true);
    dispatch(setFCViewLocation({}));
    
    const accounts = accountNumbers.map(String);

    const postbody = {};
    if((matchToggle.ssn && matchToggle.clt_ref_no) || matchToggle.both){
      postbody.ssnMatch = false;
      postbody.cltRefMatch = false;
    } else {
      postbody.ssnMatch = matchToggle.ssn;
      postbody.cltRefMatch = matchToggle.clt_ref_no;
    }
    const duplicatesFound = await bitMgmtAPI.checkFileAgainstDuplicates(accounts,postbody.ssnMatch,postbody.cltRefMatch);

    setDuplicates(duplicatesFound.duplicates);
    setFullDupsRes(duplicatesFound);
  };

  useEffect(() => {
    if(accountNumbers.length > 0){
      // make sure the file is only one column of account numbers
      if(accountNumbers[0].length === 1){
        checkAccountsAgainstFC();
      } else {
        alert('Please upload a file with only one column');
      }
    }
  }, [accountNumbers]);

  const prepareTableData = async () => {
    if(fullDupsRes.fcAccounts) {
      const locations = fullDupsRes.fcAccounts.length ? fullDupsRes.fcAccounts.reduce((prev,curr) => {
        const tmp = {...prev};
        tmp[curr.location] = tmp[curr.location] ? tmp[curr.location] : [];
        tmp[curr.location].push(curr);
        return tmp;
      },[]) : [];
      const dups = fullDupsRes.duplicates.length ? fullDupsRes.duplicates.reduce((prev,curr) => {
        const tmp = {...prev};
        tmp[curr.location] = tmp[curr.location] ? tmp[curr.location] : [];
        tmp[curr.location].push(curr);
        return tmp;
      }) : [];

      const useableData = await Promise.all(Object.keys(locations).map(async(i) => {
        const accounts = await bitMgmtAPI.checkSpokeForAccountsFromFile(i,accountNumbers);
        const tmp = {i};
        tmp.short_name = i;
        tmp.total_count = locations[i] ? locations[i].length : 0;
        tmp.external_dups = dups[i] ? dups[i].length : 0;
        tmp.location_count = accounts.location_count;
        return tmp;
      }));
      
      setTableData(useableData);
      setLocationsLoading(false);
    } else {
      setLocationsLoading(false);
    }

  };

  useEffect(() => {
    setExportData(
      duplicates.map((i) => {
        // format the duplicates' dates and only output desired columns
        const tmp = {};
        tmp.clt_ref_no = i.clt_ref_no;
        tmp.location = i.location;
        tmp.last_activity = moment(i.last_activity).format("MM/DD/YYYY hh:mm A");
        tmp.moment_updated = moment(i.moment_updated).format("MM/DD/YYYY hh:mm A");
        return tmp;
      })
    );
    prepareTableData();
  },[duplicates,fullDupsRes]);

  const locationPortBreakdown = async (loc) => {
    dispatch(setFCViewLocation(loc));
    setPortsLoading(true);

    // get the number of accounts at each port from the spoke
    const spokes = await bitMgmtAPI.checkPortsForAccountsFromFile(loc.short_name,accountNumbers);

    // get the clt_id total_counts coombined with the duplicate counts from that location/clt_id
    // duplicates = [{location: 'DMO', clt_id: 'id', clt_ref_no: '1234}]
    const portDupCounts = duplicates
      // only return the duplicates from the current location
      .filter((item) => item.location === loc.short_name)
      
      //sort them by portfolio id 
      .reduce((prev,curr) => {
        const tmp = {...prev};
        tmp[curr.clt_id] = tmp[curr.clt_id] ? tmp[curr.clt_id] : [];
        tmp[curr.clt_id].push(curr);
        return tmp;
      },{});

    const portTableData = spokes.map((i) => {
      // empty object to hold the keys for the table data
      const tmp = {};
      tmp.clt_id = i.clt_id;
      // get the dups count from the original dups pull
      tmp.port_dups = portDupCounts[i.clt_id] ? portDupCounts[i.clt_id].length : 0;
      tmp.total_count = i.total_count;
      return tmp;
    });

    console.log('portTableData',portTableData);
    setLocationPorts(portTableData);
    setPortsLoading(false);
  };

  return(
    <Box>
      <Grid gridSize={'two'}>
        <Card
          title="Active Duplicates"
          style={{ height: "calc(100vh - 100px)" }}
          topRight={
            <span>
              {`Export `}
              <Button style={{ marginRight: 10 }}
                disabled={exportData.length ? false : true}
                onClick={() => {
                  const headers = {
                    ...Object.keys(exportData[0])
                      .reduce((p,c) => {
                        const tmp = {...p};
                        tmp[c] = c.toUpperCase();
                        return tmp;
                      }, {})
                    };
                  doExport([headers, ...exportData], 'csv');
              }}>CSV</Button>
              <Button
                style={{ marginRight: 10 }}
                disabled={exportData.length ? false : true}
                onClick={() => {
                  const headers = {
                    ...Object.keys(exportData[0])
                      .reduce((p,c) => {
                        const tmp =  {...p};
                        tmp[c] = c.toUpperCase();
                        return tmp;
                      }, {})
                    };
                  doExport([headers, ...exportData], 'xlsx');
              }}
              >XLS</Button>
            </span>
          }
        >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div style={{marginRight: 5}}>
            {file.file_name}
          </div>
          <label className={styles.button} for='upload_file'>
            <input
              type="file"
              id="upload_file"
              accept={SheetJSFT}
              onChange={handleChange}
              style={{display: 'none'}}
            />
              Upload
          </label>
          <Button
            onClick={() => setModal('matchCriteria')}
          >
            Criteria
          </Button>
        </div>
        { locationsLoading ?
            <div
              className="inline_center"
              style={{ alignItems: 'center' }}
            >
              <Spinner />
            </div>
        
          : tableData.length ?
            <Table
              pageIndexProp={locationTableIndex}
              pageSizeProp={locationTableSize}
              setPageSizeProp={setLocationTableSize}
              setPageIndexProp={setLocationTableIndex}
              columns={[
              {
                Header: 'short_name',
                accessor: 'short_name',
                customHeader: 'Location',
                Cell: ({row}) => {
                  const {original} = row;
                  return (
                    <span
                      onClick={() => locationPortBreakdown(original)}
                      style={{textDecoration: 'underline', cursor:'pointer'}}
                    >
                      {original.short_name}
                    </span>
                  )}
                },
                {
                  Header: 'external_dups',
                  accessor: 'external_dups',
                  customHeader: 'Active Dupes',
                },
                {
                  Header: 'total_count',
                  accessor: 'total_count',
                  customHeader: 'FullCircle',
                },
                {
                  Header: 'location_count',
                  accessor: 'location_count',
                  customHeader: 'Location',
                },
              ]}
              data={tableData}
            />
          : !locationsLoading && tableData.length === 0
          ? <div>No duplicates found</div>
          : null
        }
        </Card>

        <Card
          title={ locationsLoading
            ? ''
            : viewLocation.short_name
            ? `Location: ${viewLocation.short_name}`
            : `Select a location...`
          }
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          <div style={{ width: '100%' }}>
          { viewLocation.short_name ?
            <div>
            { portsLoading 
              ? <div
                  className="inline_center"
                  style={{ alignItems: 'center', paddingTop: 10 }}
                >
                  <Spinner />
              </div>
              : <Table
                pageIndexProp={portsTableIndex}
                pageSizeProp={portsTableSize}
                setPageSizeProp={setPortsTableSize}
                setPageIndexProp={setPortsTableIndex}
                columns={[
                  {
                    Header: 'clt_id',
                    accessor: 'clt_id',
                    customHeader: 'Portfolio',
                  },
                  {
                    Header: 'port_dups',
                    accessor: 'port_dups',
                    customHeader: 'Active Dupes',
                  },
                  {
                    Header: 'total_count',
                    accessor: 'total_count',
                    customHeader: 'Count',
                  },
                  {
                    Header: 'percent',
                    accessor: 'percent',
                    customHeader: 'AD %',
                    Cell: ({row}) => {
                      const {original} = row;
                      return (
                        <span>
                          {original.port_dups && original.total_count ? `${((original.port_dups / original.total_count) * 100).toFixed(2)}%` : '0%'}
                        </span>
                    )}
                  },
                ]}
                data={locationPorts}
              />
            }
            </div>
          : locationsLoading
            ? null
          : null
          }
        </div>
      </Card>
      </Grid>
      <ActiveDupsMatchModal
        matchToggle={matchToggle}
        modal={modal}
        setModal={setModal}
        updateMatchCriteria={(opts) => dispatch(updateMatchCriteria(opts))}
        sumbitChanges={checkAccountsAgainstFC}
      />
    </Box>
  );
};

export default ActiveDuplicatesFiles;
