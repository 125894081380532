import React from 'react';

//src modules
import {
  fc_molecules,
  fc_atoms,
} from '../../../fc_src/ui';

// made this its own organism bc it's used on both the dups and dups file checker pages KS 3/28/22
const ActiveDupsMatchModal = (props) => {
  const {
    sumbitChanges,
    matchToggle,
    updateMatchCriteria,
    modal,
    setModal,
  }=props;

  const {
    Card,
    NewModal,
  } = fc_molecules;

  const {
    Button,
    CheckableRightDisplay,
  } = fc_atoms;

  const toggleMatchCriteria = (key) => {
    // this seems verbose for a pretty simple toggle, maybe refactor it at some point KS 3/24/22
    if(key === 'both'){
      if(matchToggle.both === true){
        // set them all false, in case one was checked
        updateMatchCriteria({ssn: false, clt_ref_no: false, both: false, clt_id: matchToggle.clt_id});
      }
      if(matchToggle.both === false){
        // set them all true, in case one was checked
        updateMatchCriteria({ssn: true, clt_ref_no: true, both: true, clt_id: matchToggle.clt_id});
      }
    } else {
      if(matchToggle.both === true){
        // if both are selected and you're deselecting one, set the both key to false.
        updateMatchCriteria({...matchToggle, [key]: !matchToggle[key], both: false});
      } else {
        // otherwise just toggle the dang key
        updateMatchCriteria({...matchToggle, [key]: !matchToggle[key]});
      }
    }
  };

  return(
    <NewModal
      visible={modal === 'matchCriteria' ? true : false}
      onHide={() => setModal(false)}
    >
      <Card
        style={{ width: 250, height: 225 }}
        title={`Change Match Criteria`}
      >
        <CheckableRightDisplay
          title={<span>SSN Only</span>}
          checked={matchToggle.ssn}
          onClick={() => toggleMatchCriteria('ssn')}
        />
        <CheckableRightDisplay
          title={<span>Client Ref No Only</span>}
          checked={matchToggle.clt_ref_no}
          onClick={() => toggleMatchCriteria('clt_ref_no')}
        />
        <CheckableRightDisplay
          title={<span>Match Both</span>}
          checked={matchToggle.both}
          onClick={() => toggleMatchCriteria('both')}
        />
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
          <Button
            onClick={() => {
              sumbitChanges();
              setModal(false);
            }}
          >
            Save
          </Button>
        </div>
      </Card>
    </NewModal>
  );
};

export default ActiveDupsMatchModal;
