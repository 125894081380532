/* eslint-disable class-methods-use-this */

const {REACT_APP_API_URL} = process.env;

export default class CRMPutters {
  async editContactEmail(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/updateContactEmailInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  async editContactPhone(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/updateContactPhoneInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  async editContactAddress(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/updateContactAddressInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  async updateContact(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/updateContactInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  // Delete data calls
  async deleteContactEmail(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/deleteContactEmail`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  async deleteContactPhone(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/deleteContactPhone`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
  
  async deleteContactAddress(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/deleteContactAddress`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }


  async addContact(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/addContactInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async addContactEmail(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/addContactEmailInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async addContactPhone(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/addContactPhoneInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }

  async addContactAddress(data) {
    const out = await fetch(`${REACT_APP_API_URL}/crm/write/addContactAddressInfo`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return false;
      })
      .then((resJS) => {
        return resJS;
      });
    return out;
  }
}