const XLSX = require("xlsx");

    const parseToJSON = (file,setCb,headers) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, {type:'array'});

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert an array of objects */
        // const data = XLSX.utils.sheet_to_row_object_array(ws, {raw: true});
        const data = XLSX.utils.sheet_to_json(ws, {raw: false, header: headers ? [...headers] : null});

        /* Update state */
        // slicing the header row off for now
        if(setCb){
          setCb(data.slice(1));
          // setCb(data);
        }
        return data;
        // return data;
      };

      // actually read the file with the above objects
      return reader.readAsArrayBuffer(file);
    };

  module.exports={parseToJSON};