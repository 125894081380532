import React, { useState, useEffect } from "react";
import { XCircle } from "react-feather";

import {
  fc_molecules,
  fc_atoms
} from '../../fc_src/ui';

export const EditSpokeModal = (props) => {
  const {
    modalVisible,
    setModalVisible,
    spoke,
    mode,
    submitFunction,
  } = props;
  
  const {
    Card,
    Modal,
  } = fc_molecules;

  const {
    Button,
    BlockDataItem,
    TextInput,
    Checkable,
  } = fc_atoms;

  useEffect(() => {
    setInputValue(spoke);
  },[spoke]);

  const [inputValue,setInputValue] = useState({});

  const submitValues = () => {
    submitFunction(inputValue);
    setInputValue({});
    setModalVisible(false);
  };

  const checkValues =
    inputValue.host_name && inputValue.short_name
    ? false : true;

  return (
    <Modal
      visible={modalVisible}
      onHide={() => {
        setModalVisible(false);
        setInputValue({});
      }}
    >
      <Card
        title="Edit server"
        style={{ width: 300 }}
        topRight={
          <XCircle
            height={17}
            style={{ transform: "translateY(1px)" }}
            className="hoverable"
            onClick={() => setModalVisible(false)}
          />
        }
      >
        <BlockDataItem title="Client Host Name">
          <TextInput
            customRightOffset={"-50px"}
            style={{ width: 236 }}
            value={inputValue.host_name}
            onChange={(val) => setInputValue({...inputValue,host_name: val})}
          />
        </BlockDataItem>
        <BlockDataItem title="Location Short Name">
          <TextInput
            customRightOffset={"-50px"}
            style={{ width: 236 }}
            value={inputValue.short_name}
            onChange={(val) => setInputValue({...inputValue,short_name: val})}
          />
        </BlockDataItem>
        {
          mode === 'edit'
          ? <BlockDataItem title="Archive">
            <Checkable
              title="Archived"
              checked={inputValue.archived}
              onClick={() => setInputValue({...inputValue,archived: !inputValue.archived})}
            />
          </BlockDataItem>
          : null
        }

        <div className="inline_center" style={{ width: "calc(100% - 20px)" }}>
          <Button
            onClick={() => submitValues()}
            disabled={checkValues}
          >
            Save
          </Button>
        </div>
      </Card>
    </Modal>
  );
}

