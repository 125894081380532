import { configureStore } from '@reduxjs/toolkit';

// Refactored

// Clos Admin Mods
import ServerMonitor from "../modules/fc_clos_admin_mods/reducers/serverMonitorSlice";

// FC Core
import QueryTool from "../modules/fc_src/reducers/queryToolSlice";
import AppSettings from '../modules/fc_src/reducers/appSettingsSlice';
import UserSessions from "../modules/fc_src/reducers/userSessionSlice";
import Contacts from "../modules/fc_src/reducers/contactsSlice";
import Campaigns from "../modules/fc_src/reducers/campaignsSlice";
import NotificationPrefs from "../modules/fc_src/reducers/notificationsSlice";
import NewUser from "../modules/fc_src/reducers/newUserSlice";

// Bit Mgmt Admin Mods
import Organizations from "../modules/fc_bit_mgmt_mods/reducers/organizationsSlice";
import AccountSearch from "../modules/fc_bit_mgmt_mods/reducers/accountSlice";
import BitPortfolios from "../modules/fc_bit_mgmt_mods/reducers/portfoliosSlice";
import Locations from "../modules/fc_bit_mgmt_mods/reducers/locationsSlice";
import LocationPortfolios from "../modules/fc_bit_mgmt_mods/reducers/locationPortfoliosSlice";
import ActiveDuplicates from '../modules/fc_bit_mgmt_mods/reducers/activeDuplicatesSlice';
import PortfolioFile from '../modules/fc_bit_mgmt_mods/reducers/portfolioFile/portfolioFileSlice';

// Needs Refactor


// bit_mgmt

/*
SET_USER_SESSION_IS_AUTHENTICATED
      SET_USER_SESSION_PERMISSIONS
*/



export const store = configureStore({
  reducer: {
    QueryTool,
    NotificationPrefs,
    NewUser,
    BitOrganizations: Organizations,
    BitPortfolios,
    Locations,
    LocationPortfolios,
    Contacts,
    AccountSearch,
    UserSessions,
    ServerMonitor,
    AppSettings,
    ActiveDuplicates,
    Campaigns,
    PortfolioFile,
  },
});