// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webApp_web_app__3GuSy {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: flex-start;\n  background-size: cover;\n  background-position: center;\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/pages/webApp/webApp.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B","sourcesContent":[".web_app {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: flex-start;\n  background-size: cover;\n  background-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"web_app": "webApp_web_app__3GuSy"
};
export default ___CSS_LOADER_EXPORT___;
