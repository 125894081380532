import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const locationPortfoliosSlice = createSlice({
  name: "locationPortfolios",
  // Define the initial state for the app
  initialState: {
    portfolios: [],
    meta: {
      hasDownloaded: false,
      isSearching: false,
      pageIndex: 0,
      pageSize: 40,
      sortObj: {
        Header: null,
        isSortedDesc: null,
      },
      filterString: "",
      portfolioSearchString: "",
      locationSearchString: "",
    },
    activeReassignKey: {
      to: "BTS",
      from: "BTS",
      count: 0,
    },
  },
  reducers: {
    updateLocationPortfolioWorklistAssignment: (state, action) => {
      state.activeReassignKey = action.payload;
    },
    toggleLocationPortfolioIsSearching: (state, action) => {
      state.meta.isSearching = action.payload;
    },
    toggleLocationPortfolioLocationSearchString: (state, action) => {
      state.meta.locationSearchString = action.payload;
    },
    toggleLocationPortfolioPortfolioSearchString: (state, action) => {
      state.meta.portfolioSearchString = action.payload;
    },
    setLocationPortfolios: (state, action) => {
      if (action.payload.length) {
        state.portfolios = action.payload.map((port) => {
          port.created =
            port.created && moment(port.created).format("YYYY/MM/DD h:mm A");
          port.updated =
            port.updated && moment(port.updated).format("YYYY/MM/DD h:mm A");
          return port;
        });
      }
    },
    setBitLocationPortfoliosDownloaded: (state, action) => {
      state.meta.hasDownloaded = true;
    },
    setBitLocationPortfoliosPageSize: (state, action) => {
      state.meta.pageSize = action.payload;
    },
    setBitLocationPortfoliosPageIndex: (state, action) => {
      state.meta.pageIndex = action.payload;
    },
    setBitLocationPortfoliosSortObj: (state, action) => {
      state.meta.sortObj = action.payload;
    },
    setBitLocationPortfoliosFilterString: (state, action) => {
      state.meta.filterString = action.payload;
      state.meta.pageIndex = 0;
    },
  },
})

export const { 
  updateLocationPortfolioWorklistAssignment,
  toggleLocationPortfolioIsSearching,
  toggleLocationPortfolioLocationSearchString,
  toggleLocationPortfolioPortfolioSearchString,
  setLocationPortfolios,
  setBitLocationPortfoliosDownloaded,
  setBitLocationPortfoliosPageSize,
  setBitLocationPortfoliosPageIndex,
  setBitLocationPortfoliosSortObj,
  setBitLocationPortfoliosFilterString,
} = locationPortfoliosSlice.actions;

export default locationPortfoliosSlice.reducer;
