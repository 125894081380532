import React, {useEffect} from "react";
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
} from '@mui/material'
import {StyledTableCell, StyledTableRow, tableStyles} from "../theme/theme"


 const ContactDataTable = (props) => {
     const {archivedData, formattedRow, columns} = props

     const generateRows = (rowData) => {
         return rowData.map((row) => (
             formattedRow(row)
         ))
     }

     /* We have to check archive and archived because that inconsistency exists in the database */
     const activeRows = generateRows(props.rows.filter(row => row.archive === 0 || row.archived === 0));
     const archiveRows = generateRows(props.rows.filter(row => row.archive === 1 || row.archived === 1));
     let tableData = (archivedData === 1) ? archiveRows : activeRows;

     useEffect(() => {
         tableData = (archivedData === 1) ? archiveRows : activeRows;
     }, [archivedData])

    return (
        <TableContainer component={Paper} sx={{marginLeft: "8px"}}>
            <Table sx={tableStyles} size="small" aria-label="a dense table">
                <TableHead>
                    <StyledTableRow>
                        {columns.map((column) => {
                            return (<StyledTableCell key={column}><strong>{column}</strong></StyledTableCell>);
                        })}
                    </StyledTableRow>
                </TableHead>
                <TableBody sx={{color: "#fff"}}>
                    {tableData}
                </TableBody>
            </Table>
        </TableContainer>
    )
 }

 export default ContactDataTable