// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".numberInput_input__3jteX {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 4px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n\n.numberInput_input__3jteX:focus {\n  outline: none;\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/atoms/inputs/numberInput/numberInput.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;;AAEA;EACE,aAAa;AACf","sourcesContent":[".input {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 4px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n\n.input:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "numberInput_input__3jteX"
};
export default ___CSS_LOADER_EXPORT___;
