import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Box } from "@mui/material"

// src modules
import userSettings from "../../../fc_src/utils/userSettings/userSettings";

// API interface
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

// Import FC UI libraries
import {
  fc_molecules,
  fc_atoms
} from '../../../fc_src/ui';

import { 
  updateLocationPortfolioWorklistAssignment,
  toggleLocationPortfolioIsSearching,
  toggleLocationPortfolioLocationSearchString,
  toggleLocationPortfolioPortfolioSearchString,
  setLocationPortfolios,
  setBitLocationPortfoliosDownloaded,
  setBitLocationPortfoliosPageSize,
  setBitLocationPortfoliosPageIndex,
  setBitLocationPortfoliosSortObj,
  setBitLocationPortfoliosFilterString,
} from '../../reducers/locationPortfoliosSlice';

import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';
import StatsModal from "./statsModal";
import RemoteStatsModal from "./remoteStatsModal";

export const LocationPortfolios = () => {

  const {
    Card,
    Table,
  } = fc_molecules;

  const {
    Spinner,
    TextInput,
    Button,
  } = fc_atoms;

  
  const [loading, setLoading] = useState([]);
  const [statsModalVisible, setStatsModalVisible] = useState(false);
  const [remoteStatsModalVisible, setRemoteStatsModalVisible] = useState(false);
  const [remoteStatsFetching, setRemoteStatsFetching] = useState("");
  const [portfolioStats, setPortfolioStats] = useState({
    missing_address: 0,
    missing_phone: 0,
    missing_ssn: 0,
    total: 0
  });
  const [remotePortfolioStats, setRemotePortfolioStats] = useState({
    stats2: [],
    stats7: [],
  });

  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();

  const viewStatsModal = React.useMemo(() => (
      <StatsModal modalOpen={statsModalVisible} onClose={() => setStatsModalVisible(false)} data={portfolioStats}/>
    ),
    [statsModalVisible, portfolioStats]
  );

  const viewRemoteStatsModal = React.useMemo(() => (
      <RemoteStatsModal
          modalOpen={remoteStatsModalVisible}
          onClose={() => setRemoteStatsModalVisible(false)}
          data={remotePortfolioStats}
      />
    ),
    [remoteStatsModalVisible, remotePortfolioStats]
  );

  // Write
  const handleUpload = async (el, i) => {
    const nLoading = [...loading];
    nLoading.push(i);
    setLoading(nLoading);
    // const location = urlParams.get("loc");
    const uploadResult = await bitMgmtAPI.uploadBitLocationPortfolio(
      el.location_portfolio_id,
      el.location_name
    );
    const sLoading = [...loading];
    sLoading.splice(sLoading.indexOf(i), 1);
    setLoading(sLoading);
    if (uploadResult.error) {
      alert(`Error: ${uploadResult.error}`);
    } else if (uploadResult.success && uploadResult.data) {
      alert(`Successful records: ${uploadResult.data}`);
      handleFilterInput();
    } else {
      alert(`Unkown error: ${JSON.stringify(uploadResult)}`);
      // AFter a successful upload, the loaded number should have changed
      handleFilterInput();
    }
  };

  // Write
  const executePortfolioLocationAssignmentChange = async () => {
    const { location, portfolio, from, to, count } = activeReassignKey;
    if (from === undefined || to === undefined || count === undefined) {
      alert("please fill out the required fields")
    } else {
      const workListUpdateResults = await bitMgmtAPI.updateLocationPortfolioWorklist(
          location,
          portfolio,
          from,
          to,
          count
      );
      if (workListUpdateResults.data && workListUpdateResults.data.length) {
        const alertMapped = workListUpdateResults.data.reduce((p, c) => {
          return p + c.assign_id + ": " + c.total_assigned + " \n";
        }, "");
        alert(alertMapped);
        dispatch(updateLocationPortfolioWorklistAssignment({}));
      } else {
        alert("There has been an error");
      }
    }
  };

  const updateWorklistReassignFrom = (val) => {
    const tmp = { ...activeReassignKey };
    tmp.from = val;
    dispatch(updateLocationPortfolioWorklistAssignment(tmp));
  };
  const updateWorklistReassignTo = (val) => {
    const tmp = { ...activeReassignKey };
    tmp.to = val;
    dispatch(updateLocationPortfolioWorklistAssignment(tmp));
  };
  const updateWorklistReassignCount = (val) => {
    const tmp = { ...activeReassignKey };
    tmp.count = val;
    dispatch(updateLocationPortfolioWorklistAssignment(tmp));
  };

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  
  const activeReassignKey = useSelector(
    (state) => state.LocationPortfolios.activeReassignKey
  );
  const LocationPortfolios = useSelector(
    (state) => state.LocationPortfolios.portfolios,
    shallowEqual
  ).map((org, i) => {
    const tmp = {...org};
    /* This Action button is a dead end, doesn't have an endpoint, and it's not immediately obvious what it was supposed to do.
    *  For these reasons, it's being commented out until further notice. TODO: Determine if this is a thing that should exist.
    * */
    /*tmp.action = (
      <a
        href={`/locationPortfolioExport?org=${org.name}&loc=${org.location_name}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button onClick={() => null}>Location&nbsp;files&nbsp;list</Button>
      </a>
    );*/
    tmp.upload = loading.includes(i) ? (
      <Button onClick={() => handleUpload(org, i)} disabled={""}>
        <Spinner size={12} />
      </Button>
    ) : (
      <Button onClick={() => handleUpload(org, i)} disabled={""}>
        Import&nbsp;at&nbsp;location
      </Button>
    );
    tmp.reassign = (
      <div>
        {activeReassignKey.location === org.location_name &&
        activeReassignKey.portfolio === org.name ? (
          //  BSN, RCR, BTS, NEW
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              From
            </label>
            <select
              onChange={(e) => updateWorklistReassignFrom(e.target.value)}
            >
              <option value=""></option>
              <option value="BSN">BSN</option>
              <option value="RCR">RCR</option>
              <option value="BTS">BTS</option>
              <option value="NEW">NEW</option>
            </select>
            <label style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              #&nbsp;of&nbsp;Accounts
            </label> 
            <input type="number" min={0} style={{width: '75px', backgroundColor: "var(--darkest)", color: "#fff", paddingTop: "5px", paddingBottom: "5px" }} onChange={(e) => updateWorklistReassignCount(e.target.value)} />
            <label style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              To
            </label>
            <select onChange={(e) => updateWorklistReassignTo(e.target.value)}>
              <option value=""></option>
              <option value="BSN">BSN</option>
              <option value="RCR">RCR</option>
              <option value="BTS">BTS</option>
              <option value="NEW">NEW</option>
            </select>
            <Button
              onClick={() => executePortfolioLocationAssignmentChange()}
              style={{ marginLeft: "1em" }}
            >
              Execute
            </Button>
            <Button 
              onClick={() => {
                dispatch(updateLocationPortfolioWorklistAssignment({}));
              }
            }>
              Clear
            </Button>
          </div>
        ) : (
          <Button
            onClick={() =>
              dispatch(updateLocationPortfolioWorklistAssignment({
                location: org.location_name,
                portfolio: org.name,
              }))
            }
          >
            Change&nbsp;Worklist
          </Button>
        )}
      </div>
    );
    tmp.mark_portfolio_loaded = (
      <Button
        onClick={async () => {
          console.log(`Begin marking this portfolio ${org.location_portfolio_id} loaded `);
          (await bitMgmtAPI.markBitLocationPortfolioLoaded(org.location_portfolio_id))
          handleFilterInput();
          console.log(`End marking ${org.location_portfolio_id} loaded`);
        }}
      >
        Mark&nbsp;Loaded
      </Button>
    );
    tmp.stats = (
        <Button
            onClick={async () => {
              console.log(`get location stats for location_portfolio_id ${org.location_portfolio_id}`);
              setPortfolioStats(await bitMgmtAPI.getLocationPortfolioStats(org.location_portfolio_id));
              setStatsModalVisible(true);
            }}
        >
          View Stats
        </Button>
    );
    tmp.remote_stats = (
        (remoteStatsFetching === org.name) ?
          <Spinner/>
        :
        <Button
            onClick={async () => {
              setRemoteStatsFetching(org.name)
              const response = await bitMgmtAPI.getRemotePortfolioStats(org.location_name, org.name)
              if(response.success) {
                setRemotePortfolioStats({
                  stats2: Object.keys(response.data.stats2[0]).map(columnTitle => ({title: columnTitle, values: response.data.stats2.map(row => (row[columnTitle]))})),
                  stats7: response.data.stats7
                });
                setRemoteStatsModalVisible(true);
                setRemoteStatsFetching("")
              } else {
                alert('Unable to load remote portfolio stats for portfolio `' + org.name + '`\nError: ' + response.error)
                setRemoteStatsFetching("")
              }
            }}
        >
          Remote Stats
        </Button>
    );
    return tmp;
  });
  const filterString = useSelector(
    (state) => state.LocationPortfolios.meta.filterString
  );
  const locationSearchString = useSelector(
    (state) => state.LocationPortfolios.meta.locationSearchString
  );
  const portoflioSearchString = useSelector(
    (state) => state.LocationPortfolios.meta.portfolioSearchString
  );
  const pageSize = useSelector(
    (state) => state.LocationPortfolios.meta.pageSize
  );
  const pageIndex = useSelector(
    (state) => state.LocationPortfolios.meta.pageIndex
  );
  const sortObj = useSelector((state) => state.LocationPortfolios.meta.sortObj);


  const isSearching = useSelector(
    (state) => state.LocationPortfolios.meta.isSearching
  );

  // Read
  const handleFilterInput = async (e) => {
    dispatch(toggleLocationPortfolioIsSearching(true));
    const data = await bitMgmtAPI.getLocationPortfolios({
      locationSearchString,
      portoflioSearchString,
    });
    dispatch(setLocationPortfolios(data));
    dispatch(setBitLocationPortfoliosDownloaded(true));
    dispatch(toggleLocationPortfolioIsSearching(false));
  };

  useEffect(() => {
    if (ActivePage !== "location_portfolios") {
      dispatch(setActivePage("location_portfolios"));
    }
  });

  return (
    <Box>
        <Card
          title="All Portfolios"
          style={{ height: "calc(100vh - 150px)" }}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          <Box sx={{marginBottom: "8px", display: "flex", flexDirection: {xs: "column", md: "row"}}}>
            <TextInput
                customRightOffset={"15px"}
                placeholder="Location Search"
                onChange={(val) =>
                    dispatch(toggleLocationPortfolioLocationSearchString(val))
                }
                onEnter={handleFilterInput}
                value={locationSearchString}
                style={{ marginRight: 10 }}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Portfolio Search"
                onChange={(val) =>
                    dispatch(
                        toggleLocationPortfolioPortfolioSearchString(val)
                    )
                }
                onEnter={handleFilterInput}
                value={portoflioSearchString}
                style={{ marginRight: 10 }}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Filter"
                onChange={(val) =>
                    dispatch(setBitLocationPortfoliosFilterString(val))
                }
                value={filterString}
                style={{ marginRight: 10 }}
            />
          </Box>
          {isSearching ? (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : LocationPortfolios.length ? (
            <Table
              columns={(() => {
                const colData = [];
                LocationPortfolios[0] &&
                  Object.keys(LocationPortfolios[0]).forEach((key) => {
                    if (key === "account_avg" || key === "account_total") {
                      colData.push({
                        Header: key,
                        accessor: key,
                        customHeader: key.replaceAll("_", " "),
                        sortType: (a, b) => {
                          const newA = parseFloat(
                            a.original.account_avg.replace("$", "")
                          );
                          const newB = parseFloat(
                            b.original.account_avg.replace("$", "")
                          );

                          if (newA === newB) {
                            return newA > newB ? 1 : -1;
                          }
                          return newA > newB ? 1 : -1;
                        },
                      });
                    } else {
                      colData.push({
                        Header: key,
                        accessor: key,
                        customHeader: key.replaceAll("_", " "),
                      });
                    }
                  });
                return colData;
              })()}
              data={(() => {
                let rows = [];
                if (filterString.replace(/\s/g, "") === "") {
                  rows = LocationPortfolios;
                } else {
                  LocationPortfolios.forEach((row) => {
                    let hasInserted = false;
                    Object.keys(row).forEach((key) => {
                      if (
                        row[key] &&
                        row[key]
                          .toString()
                          .toLowerCase()
                          .includes(filterString.toLowerCase()) &&
                        !hasInserted
                      ) {
                        rows.push(row);
                        hasInserted = true;
                      }
                    });
                  });
                }
                return rows;
              })()}
              pageSizeProp={pageSize}
              pageIndexProp={pageIndex}
              sortObjProp={sortObj}
              setPageSizeProp={(val) => {
                dispatch(
                  setBitLocationPortfoliosPageSize(val)
                );
                userSettings.setNewUserSetting(pageSize, {
                  module: "LOCATIONPORTFOLIOS",
                  type: "SET_BIT_LOCATION_PORTFOLIOS_PAGE_SIZE",
                  payload: val,
                });
              }}
              setPageIndexProp={(val) => {
                dispatch(setBitLocationPortfoliosPageIndex(val));
              }}
              setSortObjProp={(val) => {
                dispatch(setBitLocationPortfoliosSortObj(val));
              }}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No matching portfolios. Enter a new search term
            </div>
          )}
        </Card>
      {viewStatsModal}
      {viewRemoteStatsModal}
    </Box>
  );
};

export default LocationPortfolios;