// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkable_container__2KWTT {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  line-height: 0px;\n  margin-bottom: 10px;\n  font-size: 13px;\n}\n.checkable_checkbox__3iZL2 {\n  background-color: var(--darkest);\n  color: white;\n  width: 17px;\n  height: 17px;\n  border-radius: 2px;\n  margin-right: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.checkable_checkbox_checked__3PkwA {\n}\n.checkable_container__2KWTT span {\n  line-height: 0px;\n}\n.checkable_checkbox__3iZL2 svg {\n  transform: translateY(1px);\n}\n.checkable_light__2W1_Y .checkable_checkbox__3iZL2 {\n  background-color: var(--dark);\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/atoms/checkable/checkable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gCAAgC;EAChC,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;AACA;AACA;EACE,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  line-height: 0px;\n  margin-bottom: 10px;\n  font-size: 13px;\n}\n.checkbox {\n  background-color: var(--darkest);\n  color: white;\n  width: 17px;\n  height: 17px;\n  border-radius: 2px;\n  margin-right: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.checkbox_checked {\n}\n.container span {\n  line-height: 0px;\n}\n.checkbox svg {\n  transform: translateY(1px);\n}\n.light .checkbox {\n  background-color: var(--dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "checkable_container__2KWTT",
	"checkbox": "checkable_checkbox__3iZL2",
	"checkbox_checked": "checkable_checkbox_checked__3PkwA",
	"light": "checkable_light__2W1_Y"
};
export default ___CSS_LOADER_EXPORT___;
