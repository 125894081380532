import React from 'react';
import { fc_atoms } from '../../ui';

const FCUserForm = (props) => {

  const {
    formValues, //state variable
    setFormValues, //state setter
    roles, //permission roles [{role_id: num, role_name: 'string'}]
  }=props;

  // UI elements
  const {
    BlockDataItem,
    TextInput,
  } = fc_atoms;

  const updateValue = (key,val) => {
    const tmp = {...formValues};
    tmp[key] = val;
    setFormValues(tmp);
  };

  return(
  <div
    style={{
      background: "var(--darker)",
      padding: "15px 0px 10px 15px",
      width: "calc(100% - 30px)",
      marginBottom: 20,
    }}
  >
    <BlockDataItem
      title="First name"
      style={{
        width: "calc(100% - 40px)",
        marginRight: 40,
        maxWidth: "none",
      }}
    >
      <TextInput
        style={{ width: "100%" }}
        value={formValues.first}
        onChange={(val) => updateValue('first',val)}
      />
    </BlockDataItem>
    <BlockDataItem
      title="Last name"
      style={{
        width: "calc(100% - 40px)",
        marginRight: 40,
        maxWidth: "none",
      }}
    >
      <TextInput
        style={{ width: "100%" }}
        value={formValues.last}
        onChange={(val) => updateValue('last',val)}
      />
    </BlockDataItem>
    <BlockDataItem
      title="Username*"
      style={{
        width: "calc(100% - 40px)",
        marginRight: 40,
        maxWidth: "none",
      }}
    >
      <TextInput
        style={{ width: "100%" }}
        whitelist="alpha"
        value={formValues.username}
        onChange={(val) => updateValue('username',val)}
      />
    </BlockDataItem>
    <BlockDataItem
      title="Email*"
      style={{
        width: "calc(100% - 40px)",
        marginRight: 40,
        maxWidth: "none",
      }}
    >
      <TextInput
        style={{ width: "100%" }}
        value={formValues.email}
        onChange={(val) => updateValue('email',val)}
      />
    </BlockDataItem>
    {/* roles are readonly until the permissions build out */}
    <BlockDataItem
      title="Role"
      style={{
        width: "calc(100% - 30px)",
        marginRight: 40,
        maxWidth: "none",
      }}
    >
      <select
        style={{ width: "100%" }}
        value={formValues.role_id}
        onChange={(e) => updateValue('role_id', e.target.value)}
        disabled
      >
        {roles.map((s) => (
          <option value={s.id}>{s.role_name}</option>
        ))}
      </select>
    </BlockDataItem>
  </div>
  );
};

export default FCUserForm;
