import React from "react";
import { PlusCircle } from "react-feather";

export default (props) => {
  return (
    <div
      onClick={props.onClick}
      className="hoverable"
      style={{ transform: "translateY(2px)" }}
    >
      <PlusCircle height={16} />
    </div>
  );
};
