import React from "react";
import { Check } from "react-feather";
import styles from "./checkable.module.css";

export default (props) => {
  return (
    <div
      className={`${styles.container} hoverable ${props.light && styles.light}`}
      onClick={() => props.onClick(props.key)}
      style={props.style && props.style}
    >
      <div
        className={
          props.checked
            ? `${styles.checkbox} ${styles.checkbox_checked}`
            : `${styles.checkbox}`
        }
      >
        {props.checked && <Check height={14} />}
      </div>
      <div
        style={{
          width: "calc(100% - 17px)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{props.title}</span>
        <div>{props.rightLabel}</div>
      </div>
    </div>
  );
};
