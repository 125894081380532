import React from "react";
import { useSelector } from "react-redux";
import { Check } from "react-feather";
import Spinner from "../../atoms/spinner/spinner";
import styles from "./statusBar.module.css";

export default (props) => {
  const StatusSyncing = useSelector((state) => state.ServerMonitor.syncing);
  return (
    <div
      className={`${styles.statusBar} ${styles[props.type]} ${
        props.centerText ? styles.show_success : ""
      }`}
    >
      <div className={styles.left}>
        {StatusSyncing ? (
          <div className="inline_left_center">
            <Spinner />
            &nbsp;&nbsp;
            <div style={{ transform: "translateY(-1px)" }}>
              Syncing Statuses
            </div>
          </div>
        ) : (
          <div className="inline_left_center">
            <Check className="icon" />
            &nbsp;&nbsp;
            <div>Statuses Synced</div>
          </div>
        )}
      </div>
      <div className={styles.center}>
        <div>{props.centerText}</div>
      </div>
      <div className={styles.right}></div>
    </div>
  );
};
