import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Tabs,
    Tab,
    Typography,
} from '@mui/material'
import PhoneTable from "./phoneTable";
import EmailTable from "./emailTable";
import AddressTable from "./addressTable";
import { PlusCircle } from "react-feather";

export const ContactDataCard = (props) => {
    const {
        setAddModalVisible,
        dataType,
        tableData,
        editFunction,
        deleteFunction,
        archivedData,
        setArchivedData,
        theme,
    } = props;

    const handleTabChange = (event, val) => {
        setArchivedData(val)
    }

    return (
        <Card sx={{
            margin: { xs: 0, sm: 0,  md: theme.spacing(1), lg: theme.spacing(1) },
            backgroundColor: "primary.main",
            color: "primary.contrastText"
        }}>
            <CardHeader
                sx={{marginBottom: {sm: "8px"}}}
                disableTypography
                title={<Typography variant={window.innerWidth <= 600 ? "h6" : "h5"}>{dataType}</Typography>}
                action={
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setAddModalVisible(true)}>
                        <PlusCircle height={16}/>
                </Button>}>
            </CardHeader>
            <Tabs
                value={archivedData}
                onChange={handleTabChange}
                textColor="inherit"
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#fff"
                    }
                }}
                sx={{marginLeft: "8px"}}
            >

                <Tab label="Active" value={0}/>
                <Tab label="Archived" value={1}/>
            </Tabs>
            <CardContent>
                <Grid container direction="row" spacing={2}>
                    {dataType === "Phone" && (
                            <PhoneTable
                                Phones={[tableData]}
                                editFunction={editFunction}
                                deleteFunction={deleteFunction}
                                archivedData={archivedData}
                            />
                    )}
                    {dataType === "Email" && (
                            <EmailTable
                                Emails={[tableData]}
                                editFunction={editFunction}
                                deleteFunction={deleteFunction}
                                archivedData={archivedData}
                            />
                    )}
                    {dataType === "Address" && (
                            <AddressTable
                                Addresses={[tableData]}
                                editFunction={editFunction}
                                deleteFunction={deleteFunction}
                                archivedData={archivedData}
                            />
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ContactDataCard;