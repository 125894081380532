import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const portfoliosSlice = createSlice({
  name: "portfolios",
  // Define the initial state for the app
  initialState: {
    portfolios: [],
    meta: {
      hasDownloaded: false,
      pageIndex: 0,
      pageSize: 40,
      isSearching: false,
      sortObj: {
        Header: null,
        isSortedDesc: null,
      },
      filterString: "",
      searchStringPort: "",
      searchStringPortName: "",
      searchStringOrgName: "",
    },
  },
  reducers: {
    setPortfolioSearchStringPort: (state, action) => {
      state.meta.searchStringPort = action.payload;
    },
    setPortfolioSearchStringPortName: (state, action) => {
      state.meta.searchStringPortName = action.payload;
    },
    setPortfolioSearchStringOrgName: (state, action) => {
      state.meta.searchStringOrgName = action.payload;
    },
    setIsSearching: (state, action) => {
      state.meta.isSearching = action.payload;
    },
    setBitPortfolios: (state, action) => {
      state.portfolios = action.payload.map((port) => {
        port.moment_created = moment(port.moment_created).format(
          "YYYY/MM/DD h:mm A"
        );
        port.created = moment(port.created).format("YYYY/MM/DD h:mm A");
        port.moment_updated = moment(port.moment_updated).format(
          "YYYY/MM/DD h:mm A"
        );
        return port;
      });
    },
    setBitPortfoliosDownloaded: (state, action) => {
      state.meta.hasDownloaded = true;
    },
    setBitPortfoliosPageSize: (state, action) => {
      state.meta.pageSize = action.payload;
    },
    setBitPortfoliosPageIndex: (state, action) => {
      state.meta.pageIndex = action.payload;
    },
    setBitPortfoliosSortObj: (state, action) => {
      state.meta.sortObj = action.payload;
    },
    setBitPortfoliosFilterString: (state, action) => {
      state.meta.filterString = action.payload;
      state.meta.pageIndex = 0;
    },
  },
})

export const { 
  setPortfolioSearchStringPort,
  setPortfolioSearchStringPortName,
  setPortfolioSearchStringOrgName,
  setIsSearching,
  setBitPortfolios,
  setBitPortfoliosDownloaded,
  setBitPortfoliosPageSize,
  setBitPortfoliosPageIndex,
  setBitPortfoliosSortObj,
  setBitPortfoliosFilterString,
} = portfoliosSlice.actions;

export default portfoliosSlice.reducer;
