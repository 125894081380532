import React from "react";
import {
    Button,
    Chip,
} from '@mui/material'
import { Trash, Edit } from "react-feather"
import ContactDataTable from "../../molecules/contactDataTable";
import {StyledTableCell, StyledTableRow} from "../../theme/theme";

const tableColumns = ["Email", "Description", "Status", "Actions"]

const EmailTable = (props) => {
    const { Emails, editFunction, archivedData, deleteFunction } = props
    const [emails] = Emails;

    const rowSnippet = (row) => {
        return (
            <StyledTableRow
                key={row.email_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell component="th" scope="row">
                    {row.email_address}
                </StyledTableCell>
                <StyledTableCell >{row.description}</StyledTableCell>
                <StyledTableCell >
                    {(row.archive === 1) ? <Chip label="Archived" color="warning"/> : <Chip label="Active" color="success"/>}
                    {(row.do_not_contact === 1) ? <Chip label="Do Not Contact" color="error"/> : ""}</StyledTableCell>
                <StyledTableCell >
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{marginRight: "5px"}}
                        onClick={() => {
                            editFunction(row.email_id)
                        }}><Edit size={16} />
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            deleteFunction(row.email_id)
                        }}>
                        <Trash size={16}/>
                    </Button>
                </StyledTableCell>
            </StyledTableRow> )
    }

    return (
        <ContactDataTable
            columns={tableColumns}
            rows={emails}
            editFunction={editFunction}
            deleteFunction={deleteFunction}
            archivedData={archivedData}
            formattedRow={rowSnippet}
        />
    )

}

export default EmailTable;