import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";
const contactsSlice = createSlice({
  name: "contacts",
  // Define the initial state for the app
  initialState: {
    contacts: [],
    meta: {
      hasDownloaded: false,
      pageIndex: 0,
      pageSize: 40,
      sortObj: {
        Header: null,
        isSortedDesc: null,
      },
      filterString: "",
    },
  },
  reducers: {
    setContacts: (state, action) => {
      state.contacts = action.payload && action.payload.length ? 
        action.payload.map((con) => {
          con.created = moment(con.created).format("YYYY/MM/DD h:mm A");
          con.updated = moment(con.updated).format("YYYY/MM/DD h:mm A");
          return con;
        }) 
      : [];
      state.meta.hasDownloaded = true;
    },
    setContactsDownloaded: (state, action) => {
      state.meta.hasDownloaded = true;
    },
    setContactsPageIndex: (state, action) => {
      state.meta.pageIndex = action.payload;
    },
    setContactsPageSize: (state, action) => {
      state.meta.pageSize = action.payload;
    },
    setContactsSortObj: (state, action) => {
      state.meta.sortObj = action.payload;
    },
    setContactsFilterString: (state, action) => {
      state.meta.filterString = action.payload;
      state.meta.pageIndex = 0;
    },
  },
})

export const { 
  setContacts,
  setContactsDownloaded,
  setContactsPageIndex,
  setContactsPageSize,
  setContactsSortObj,
  setContactsFilterString,
} = contactsSlice.actions;

export default contactsSlice.reducer;