// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_page__cuIuO {\n  width: calc(100vw - 201px);\n  height: 100vh;\n  position: relative;\n  background: rgba(0, 0, 0, 0.6);\n}\n.page_collapsed__3Zr0Q {\n  width: calc(100vw - 70px);\n}\n@keyframes page_fadeIn__x-lLL {\n  0% {\n    opacity: 0.8;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/organisms/page/page.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,kBAAkB;EAClB,8BAA8B;AAChC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".page {\n  width: calc(100vw - 201px);\n  height: 100vh;\n  position: relative;\n  background: rgba(0, 0, 0, 0.6);\n}\n.collapsed {\n  width: calc(100vw - 70px);\n}\n@keyframes fadeIn {\n  0% {\n    opacity: 0.8;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "page_page__cuIuO",
	"collapsed": "page_collapsed__3Zr0Q",
	"fadeIn": "page_fadeIn__x-lLL"
};
export default ___CSS_LOADER_EXPORT___;
