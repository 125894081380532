import {Box} from "@mui/material"
import { Loader } from "react-feather";

const CenteredSpinner = () => {
    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',}}
        >
            <Loader className="spinner" size={30} />
        </Box>
    )
}

export default CenteredSpinner