import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Box } from "@mui/material"

import userSettings from "../../../fc_src/utils/userSettings/userSettings";

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_organisms,
  fc_molecules,
  fc_atoms
} from '../../../fc_src/ui';

import {
  setOrganizationsPageSize,
  setOrganizationsPageIndex,
  setOrganizationsSortObj,
  setOrganizationsFilterString,
} from "../../reducers/organizationsSlice";

import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';

export const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const GridLayout = useSelector((state) => state.AppSettings.gridLayout.organizations);
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);

  
  // Bit selectors
  const filterString = useSelector((state) => state.BitOrganizations.meta.filterString);
  const pageSize = useSelector((state) => state.BitOrganizations.meta.pageSize);
  const pageIndex = useSelector((state) => state.BitOrganizations.meta.pageIndex);
  const sortObj = useSelector((state) => state.BitOrganizations.meta.sortObj);
  
  const dispatch = useDispatch();

  const bitMgmtAPI = new BitMgmt();

  const {
    Grid,
  } = fc_organisms;

  const {
    Card,
    Table,
  } = fc_molecules;

  const {
    Spinner,
    TextInput,
  } = fc_atoms;

  useEffect(() => {
    if (ActivePage !== "organizations") {
      dispatch(setActivePage("organizations"));
      bitMgmtAPI.getOrganizations()
        .then((data) => setOrganizations(data));
    } 
  });
  return (
    <Box>
      <Grid gridSize={GridLayout}>
        <Card
          title="All Organizations"
          style={{ height: "calc(100vh - 150px)" }}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          <Box sx={{marginTop: "8px", marginBottom: "8px"}}>
            <TextInput
                customRightOffset={"15px"}
                placeholder="Filter"
                onChange={(val) =>
                    dispatch(setOrganizationsFilterString(val))
                }
                value={filterString}
                style={{ marginRight: 10 }}
            />
          </Box>
          {
            organizations.length ? 
              <Table
                columns={(() => {
                  const colData = [];
                  organizations[0] &&
                    Object.keys(organizations[0]).forEach((key) => {
                      colData.push({
                        Header: key,
                        accessor: key,
                      });
                    });
                  return colData;
                })()}
                data={(() => {
                  let rows = [];
                  const mappedOrgs = organizations.map((i) => {
                    const ns = {...i};
                    ns.status = i.status === 1 ? 'Active' : i.status === 2 ? 'Inactive' : ''
                    ns.created = moment(i.created).format("MM/DD/YYYY hh:mm A");
                    ns.updated = moment(i.updated).format("MM/DD/YYYY hh:mm A");
                    return ns;
                  });
                  if (filterString.replace(/\s/g, "") === "") {
                    rows = mappedOrgs;
                  } else {
                    mappedOrgs.forEach((row) => {
                      let hasInserted = false;
                      Object.keys(row).forEach((key) => {
                        if (
                          row[key] &&
                          row[key]
                            .toString()
                            .toLowerCase()
                            .includes(filterString.toLowerCase()) &&
                          !hasInserted
                        ) {
                          rows.push(row);
                          hasInserted = true;
                        }
                      });
                    });
                  }
                  return rows;
                })()}
                pageSizeProp={pageSize}
                pageIndexProp={pageIndex}
                sortObjProp={sortObj}
                setPageSizeProp={(val) => {
                  dispatch(setOrganizationsPageSize(val));
                  userSettings.setNewUserSetting(pageSize, {
                    module: "ORGANIZATIONS",
                    type: "SET_BIT_ORGANIZATIONS_PAGE_SIZE",
                    payload: val,
                  });
                }}
                setPageIndexProp={(val) => {
                  dispatch(setOrganizationsPageIndex(val));
                }}
                setSortObjProp={(val) => {
                  dispatch(setOrganizationsSortObj(val));
                }}
              />
            : 
            <div
              className="inline_center"
              style={{ width: "calc(100% - 20px)", paddingTop: '15%' }}
            >
              <Spinner />
            </div>
          }
        </Card>
      </Grid>
    </Box>
  );
};

export default Organizations;
