import React, { useState, useEffect } from "react";
import { Edit } from "react-feather";

import {
  fc_molecules,
  fc_organisms,
  fc_atoms
} from '../../ui';

export const UsersTable = (props) => {
  
  const {
    Table,
  } = fc_molecules;
  const {
    Grid,
  } = fc_organisms;
  const {
    Button,
    Spinner
  } = fc_atoms;

  const { 
    setActiveEditId,
    filterString,
    activateUserClick,
    loadingButton,
  } = props;

  const [colData, setColData] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const colData = [];
    [...props.Users][0] &&
      Object.keys([...props.Users][0]).forEach((key) => {
        colData.push({
          Header: key,
          accessor: key,
        });
      });
    if (![...props.Users]?.[0]?.edit) {
      colData.push({
        Header: "Edit",
        accessor: "edit",
      });
    }
    setColData(colData);
  }, [props.Users]);

  useEffect(() => {
    let rows = [];
    [...props.Users].map((row, i) => {
      let hasInserted = false;
      const newRow = { ...row };
      newRow.edit = (
        <Button onClick={() => setActiveEditId(i)}>
          <Edit size={13} />
        </Button>
      );
      newRow.active = 
      loadingButton === row.id ?
      (
        <Button>
          <Spinner />
        </Button>
      ) :
      row.active ? (
        <Button
          onClick={() => {
            activateUserClick(row, 0);
          }}
        >
          Deactivate
        </Button>
      ) : (
        <Button
          onClick={() => {
            activateUserClick(row, 1);
          }}
          color="red"
        >
          Activate
        </Button>
      );
      Object.keys(newRow).forEach((key) => {
        if (
          newRow[key] &&
          newRow[key]
            .toString()
            .toLowerCase()
            .includes(filterString.toLowerCase()) &&
          !hasInserted
        ) {
          rows.push({ ...newRow });
          hasInserted = true;
        }
      });
    });

    setRowData([...rows]);
  }, [props.Users,loadingButton]);
  return (
    rowData.length ? 
      <Table
        columns={colData}
        data={rowData}
        pageSizeProp={50}
        pageIndexProp={0}
        sortObjProp={{
          Header: null,
          isSortedDesc: null,
        }}
        setPageSizeProp={(val) => null}
        setPageIndexProp={(val) => null}
        setSortObjProp={(val) => null}
      />
    : 

    <Grid gridSize="one">
      <div className="inline_center" style={{backgroundColor: 'rgb(116 116 116 .8) !important', width: '100%', height: '100%', paddingTop: '20vh'}}>
        <Spinner />
      </div>
    </Grid>
  );
};

export default UsersTable;