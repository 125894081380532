import React, { useState } from "react";
import styles from "./login.module.css";
import TextInput from "../../atoms/inputs/textInput/textInput";
import BlockDataItem from "../../atoms/blockDataItem/blockDataItem";
import Button from "../../atoms/button/button";
import Spinner from "../../atoms/spinner/spinner";
import doLoginCheck from "../../utils/fetch/auth/doLoginCheck";

export const Login =  (props) => {
  const [loading, setLoading] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const {setLoginInfo} = props;
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    doLoginCheck({setLoginInfo, setLoading, userName, password});
  };

  return (
    <div className={styles.login}>
      <div className={styles.background}>
        <div className={styles.overlay}>
          <div className={styles.main_container}>
            <img className={styles.logo} src="/bit_text.png" alt="logo" />
            <form className="loginBox" onSubmit={(e) => onSubmit(e)}>
              {/* <Card title="Login"> */}
              <BlockDataItem
                title="Username"
                style={{ width: "calc(100%)", maxWidth: "none" }}
              >
                <TextInput
                  name="username"
                  onChange={(val) => setUsername(val)}
                  value={userName}
                  customRightOffset={"0px"}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "none",
                    borderRadius: 0,
                    padding: 0,
                    borderBottom: "1px solid white",
                    fontSize: 20,
                    color: "white",
                  }}
                />
              </BlockDataItem>
              <br />
              <br />
              <BlockDataItem
                title="Password"
                style={{ width: "calc(100%)", maxWidth: "none" }}
              >
                <TextInput
                  type="password"
                  name="password"
                  customRightOffset={"0px"}
                  onChange={(val) => setPassword(val)}
                  value={password}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "none",
                    borderRadius: 0,
                    padding: 0,
                    borderBottom: "1px solid white",
                    fontSize: 20,
                    color: "white",
                  }}
                />
              </BlockDataItem>
              <br />
              <br />
              <div
                style={{
                  width: "calc(100%)",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "1px solid white",
                  }}
                  onClick={(e) => onSubmit(e)}
                >
                  {loading ? <Spinner /> : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;