/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material"

import {
  fc_organisms,
  fc_molecules,
  fc_atoms
} from '../../ui';

import UserAPIGetters from "../../utils/fetch/users/userGetters";
import userPutters from "../../utils/fetch/users/userPutters";

import {
  setActivePage
} from '../../reducers/appSettingsSlice';
import EditUserModal from "../../organisms/users/editUserModal";
import FCUserForm from "../../molecules/forms/fcUserForm";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const Users =  (props) => {

  const {
    Page,
    Grid,
    Header,
    UsersTable,
  } = fc_organisms;
  const {
    Card,
    Modal,
  } = fc_molecules;
  const {
    Button,
    Spinner,
  } = fc_atoms;

  const userGetters = new UserAPIGetters();
  const userPutterAPI = new userPutters();

  const dispatch = useDispatch();
  const [statusBarText, setStatusBarText] = useState("");
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [errorModalText, setErrorModalText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [users, setUsers] = useState([]);

  const [loadingButton,setLoadingButton] = useState(false);

  // state storing UserForm values, role_id hardcoded until permissions are more functional KS 2/11/22
  // set initial keys so the inputs stay as controlled elements rather than switching from controlled to uncontrolled
  const [newUser, setNewUser] = useState({
    role_id: 25,
    first: '',
    last: '',
    username: '',
    email: '',
  });

  const ActivePage = useSelector((state) => state.ActivePage);

  const getUsers = async () => {
    const data = await userGetters.getFCUsers();
    setHasDownloaded(true);
    setUsers(data);
  };

  const getRoleList = async () => {
    const roles = await userGetters.getRoleList();
    setRoles(roles);
  };

  const getPermissionList = async () => {
    const permissionList = await userGetters.getPermissionList();
    setPermissionList(permissionList);
  };

  useEffect(() => {
    if (ActivePage !== "blckline_users") {
      getUsers();
      getRoleList();
      getPermissionList();
      dispatch(setActivePage("blckline_users"));
    }
  }, [ActivePage]);

  const loadPermissions = async (i) => {
    // const permissions = await userGetters.getUserPermissions([users[i].id]);
    // setEditValues({
    //   first: users[i]?.first ? users[i].first : "",
    //   last: users[i]?.last ? users[i].last : "",
    //   username: users[i]?.username ? users[i].username : "",
    //   email: users[i]?.email ? users[i].email : "",
    //   role_id: users[i]?.role_id ? users[i].role_id : "",
    //   permissions,
    // });
  };

  const populateUserEdit = async (i) => {
    setEditValues({...users[i], permissions: []});
  };

  const submitUserEdit = async (data) => {
    setShowEditModal(false);
    await userPutterAPI.updateUser(data);
    setStatusBarText("User updated successfully");
    getUsers();
  };

  const submitNewUser = async (data) => {
    console.log(data);
    const submitToApi = await userPutterAPI.createFCUser(newUser);
    if(submitToApi.ok){
      setStatusBarText("Account created successfully");

      // reset the object but retain the role_id value
      // this clears the form inputs
      setNewUser({
        role_id: 25,
        first: '',
        last: '',
        username: '',
        email: '',
      });
      getUsers();
    }
  };

  const confirmAndToggleActive = (user,val) => {
    const actionString =
      val === 0
      ? 'deactivate'
      : 'activate';

    confirmAlert({
      title: `${actionString[0].toUpperCase() + actionString.slice(1)} User`,
      message: `Are you sure you want to ${actionString} ${user.first}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            // get the user to update
            const updateUser = users.find((u) => {return u.id === user.id});

            // make a temporary object and update the user's active value
            const tmp = {...updateUser, active: val};

            // copy the user array
            const tmpArrayHolder = [...users];

            // get the index of the udpated user
            const userIndex = tmpArrayHolder.indexOf(updateUser);
            tmpArrayHolder[userIndex] = tmp;
            setUsers(tmpArrayHolder);
            setLoadingButton(user.id);
            await userPutterAPI.toggleUserActive(user.id,val).then(() => {
              setStatusBarText(`User ${actionString}d successfully`);
              setLoadingButton(false);
            });
          }
        },
        {
          label: "No",
          onClick: () => {
            return "";
          },
        },
      ],
    });
  };

  return (
    <Box>
      <Grid gridSize="three_one">
        <Card title="All Users">
          <UsersTable
            setActiveEditId={(i) => {
              setShowEditModal(true);
              setActiveEditId(i);
              populateUserEdit(i);
              loadPermissions(i);
            }}
            Users={users}
            showArchive={false}
            filterString=""
            activateUserClick={confirmAndToggleActive}
            loadingButton={loadingButton}
          />
        </Card>
        <Card title="Add User">
          <FCUserForm
            formValues={newUser}
            setFormValues={setNewUser}
            roles={roles}
          />
          <div style={{ width: "calc(100% - 20px)" }} className="inline_center">
            <Button onClick={() => submitNewUser(newUser)} style={{paddingBottom: '25px', paddingTop: '5px', height: '40px'}}>
              {submitting ? <Spinner /> : "Submit"}
            </Button>
          </div>
        </Card>
      </Grid>
      <EditUserModal
        modalVisible={showEditModal}
        setModalVisible={setShowEditModal}
        userValues={editValues}
        submitFunction={submitUserEdit}
        permissionList={permissionList}
        roles={roles}
      />
      <Modal visible={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Card title="Error" style={{ width: 350 }}>
          {errorModalText}
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              width: "calc(100% - 20px)",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => setShowErrorModal(false)}>Ok</Button>
          </div>
        </Card>
      </Modal>
    </Box>
  );
};

export default Users;