import React, { useState, useEffect } from "react";
import FCQueryAPI from "../../utils/fetch/queryTool/queryTool";
import {
  fc_organisms,
  fc_molecules,
} from '../../ui';

export const QueryAuth = (props) => {
  const {
    Header,
    Page,
    Grid,
  } = fc_organisms;

  const {
    Card,
  } = fc_molecules;

  const { match } = props;
  const {
    params: { emailCode },
  } = match;
  const queryAPI = new FCQueryAPI();
  const [accessCode, setAccessCode] = useState("");

  useEffect(() => {
    queryAPI.getAuthAccessCode(emailCode, setAccessCode);
    setAccessCode(emailCode)
  }, [emailCode]);
  return (
    <Page>
      <Header headerLeft={<span>Remote Query Authorization</span>} />
      <Grid gridSize={`one`}>
        <Card title="Auth Code">
          {accessCode}
          <br />
        </Card>
      </Grid>
    </Page>
  );
};

export default QueryAuth;
