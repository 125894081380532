import React from "react";
import styles from "./newmodal.module.css";

export default (props) => {
  return (
    <div
      className={`
        ${styles.modal_overlay} 
        ${!props.visible && styles.hidden} 
        ${props.isFull && styles.is_full}
        ${props.light && styles.light}
      `}
      onClick={(e) => {
        if (e.target.className.toString().includes("modal")) {
          props.onHide();
        }
      }}
    >
      {props.children}
    </div>
  );
};
