import React, { useState, useEffect } from "react";
import styles from "./numberInput.module.css";

export default (props) => {
  const [numVal, setNumVal] = useState(null);
  useEffect(() => {
    setNumVal(props.value);
  }, [props.value]);
  return (
    <input
      type="number"
      style={props.style}
      className={styles.input}
      placeholder={props.placeholder}
      value={numVal}
      onChange={(e) => {
        setNumVal(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          props.onEnter && props.onEnter(e.target.value);
        }
      }}
    />
  );
};
