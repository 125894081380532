import React from "react"
import { Modal, Box, Typography } from '@mui/material'
import {localModalStyle} from "./statsModal";

const remoteStatsModal = (props) => {
    const { modalOpen, data, onClose } = props;

    return (
        <Modal
            open={modalOpen}
            onClose={onClose}
        >
            <Box sx={localModalStyle}>
                <Typography variant="h4">Remote Portfolio Statistics</Typography>
                <br/>
                {data.stats2.map((row) =>
                    <>
                        <Typography><strong>{row.title}</strong></Typography>
                        <Typography>{row.values[0]} ({row.values[1]})</Typography>
                    </>
                )}
                <hr style={{width: "80%"}}/>
                {data.stats7.map((row) =>
                    <Typography><strong>{row.assign_id}</strong>: {row.totalAccounts}</Typography>
                )}
            </Box>
        </Modal>
    )
}

export default remoteStatsModal;