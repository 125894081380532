import React from "react";
import styles from "./numberInput.module.css";

export default (props) => {
  return (
    <input
      type="number"
      style={props.style}
      className={styles.input}
      placeholder={props.placeholder}
      value={props.value + 1}
      onChange={(e) => {
        props.onChange && props.onChange(e.target.value - 1);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          props.onEnter && props.onEnter(e.target.value);
        }
      }}
    />
  );
};
