import {createSlice} from '@reduxjs/toolkit';

const activeDuplicatesSlice = createSlice({
  name: 'activeDuplicates',
  initialState: {
    locationList: [],
    accountSync: {
      locations: {},
    },
    viewLocation: {},
    meta: {
      fetchingLocations: false,
    },
    fileChecker: { //referred to as 'FC' hereafter
      viewLocation: {},
    },
    matchToggle: {
      clt_id: false,
      ssn: true,
      clt_ref_no: true,
      both: true,
    },
  },
  reducers: {
    setLocationList: (state, action) => {
      const locations = action.payload;
      state.locationList = locations && locations.length ? locations : [];
    },
    // update individual location's account count
    updateSpokeAccountCount: (state, action) => {
      const { location, location_count } = action.payload;
      const findLocation = state.locationList.find(loc => loc.short_name === location);
      const indexToUpdate = state.locationList.indexOf(findLocation);
      console.log('indexToUpdate account count',indexToUpdate);

      state.locationList[indexToUpdate].location_count = location_count;

      // set loading to false
      state.locationList[indexToUpdate].loadingCount = false;
    },
    setLocationCountLoading: (state,action) => {
      const { location, loading } = action.payload;
      const findLocation = state.locationList.find(loc => loc.short_name === location);
      const indexToUpdate = state.locationList.indexOf(findLocation);
      state.locationList[indexToUpdate].loadingCount = loading;
    },
    toggleSyncLocation: (state, action) => {
      const chosenLocation = action.payload;
      const locationData = {...state.accountSync.locations};
      const shortName = chosenLocation.short_name;
      const sync_size = chosenLocation.sync_size;
      const reducedLocations = locationData[shortName]
        ? Object.keys(locationData).reduce((p, c) => {
            const tmp = {...p};
            if (shortName !== c) {
              tmp[c] = locationData[c];
            }
            return tmp;
          }, {})
        : {...locationData};
      if (!locationData[shortName]) {
        reducedLocations[shortName] = {
          sync_size: sync_size,
          isRunning: false,
          isComplete: false,
          results: [],
          error: null,
        };
      }
      state.accountSync.locations = reducedLocations;
    },
    changeSyncSize: (state, action) => {
      const {newSize, location} = action.payload;
      const updatedLocation = state.locationList.find(loc => loc.short_name === location);
      const locIndex = state.locationList.indexOf(updatedLocation);
      const syncLocations = {...state.accountSync.locations};
      if(syncLocations[location]){
        state.accountSync.locations[location].sync_size = newSize;
      }
      state.locationList[locIndex].sync_size = newSize;
    },
    setSyncLocationResults: (state,action) => {
      const { location, results } = action.payload;
      state.accountSync.locations[location].results = results;
    },
    // sets active location for viewing portfolio breakdown
    setViewLocation: (state,action) => {
      state.viewLocation = action.payload;
    },
    setFCViewLocation: (state,action) => {
      state.fileChecker.viewLocation = action.payload;
    },
    updateMatchCriteria: (state,action) => {
      state.matchToggle = action.payload;
    },

    // set the location sync to running
    setLocationSyncing: (state, action) => {
      const { location, running } = action.payload;
      const findLocation = state.locationList.find(loc => loc.short_name === location);
      const indexToUpdate = state.locationList.indexOf(findLocation);
      state.locationList[indexToUpdate].syncing = running;
    },

    // update the fc_count and external_dups of one location
    updateLocationCounts: (state,action) => {
      const { location, fc_count, external_dups } = action.payload;
      const findLocation = state.locationList.find(loc => loc.short_name === location);
      const indexToUpdate = state.locationList.indexOf(findLocation);
      console.log(indexToUpdate);

      state.locationList[indexToUpdate].fc_count = fc_count;
      state.locationList[indexToUpdate].external_dups = external_dups;

      // set loading to false
      state.locationList[indexToUpdate].syncing = false;

    },
  }
});

export const {
  setLocationList,
  toggleSyncLocation,
  changeSyncSize,
  setSyncLocationResults,
  setViewLocation,
  setFCViewLocation,
  updateMatchCriteria,
  updateLocationCounts,
  setLocationSyncing,
  setLocationCountLoading,
  updateSpokeAccountCount,
} = activeDuplicatesSlice.actions;

export default activeDuplicatesSlice.reducer;
