import React from "react";
import styles from "./tabView.module.css";

export default (props) => {
  const {
    setActiveItem,
    activeItem,
    headers,
  } = props;

  return (
    <div className={styles.tabView}>
      <div className={styles.tabContainer}>
        {headers.map(({title},i) => {
          return (
            <div
              key={Math.random()}
              className={`${styles.tab} ${
                activeItem === i && styles.tabActive
              }`}
              onClick={() => setActiveItem(i)}
            >
              {title}
            </div>
          );
        })}
      </div>
      <div className={styles.bottomSection}>
        {props.children}
      </div>
    </div>
  );
};
