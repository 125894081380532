import {createSlice, current} from "@reduxjs/toolkit";
import { inspect } from "util";

const handleFilter = (initData=[], filterString) => {
  const data = [];
  initData.forEach((server) => {
    if (
      server.siteName.toUpperCase().includes(filterString.toUpperCase()) ||
      server.shortName.toUpperCase().includes(filterString.toUpperCase())
    ) {
      data.push(server);
    }
  });
  return data;
};

const paginateData = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

const serverMonitorSlice = createSlice({
  name: "servermonitor",
  // Define the initial state for the app
  initialState: {
    data: [],
    initialData: [],
    locations: [],
    locationsMap: [],
    serverAddData: {
      ip: "",
      name: "",
      short: "",
    },
    filterString: "",
    meta: { 
      sortKey: {}, 
      sortDir: "", 
      numRows: 20, 
      numPages: 0, 
      pageSize: 20 
    },
    syncing: false,
    syncTime: null,
  },
  reducers: {
    setInitServerData: (state, action) => {
      const {
        locations,
        initialData,
        locationsMap,
        filterString,
      } = action.payload;
      if (!(
        JSON.stringify(inspect(action.payload.initialData)) ===
        JSON.stringify(inspect(state.initialData))
      )) {
        const filteredData = handleFilter(
          action.payload.initialData,
          state.filterString
        );
        state.locations = locations;
        state.data = filteredData;
        state.initialData = initialData;
        state.locationsMap = locationsMap;
        state.filterString = filterString;
      }
    },
    sortServerMonitorTable: (state, action) => {
      state.meta.sortKey = action.payload;
    },
    setServerMonitorTableSize: (state, action) => {
      state.meta.pageSize = action.payload;
    },
    updateItemStatus: (state, action) => {
      state.data = state.data.map((el) => {
        if (el.siteID === action.payload) {
          el.syncEnabled = !el.syncEnabled;   //toggle value
          return el;
        } else {
          return el;
        }
      });
      state.initialData = [...state.data];
    },
    updateItemKillStatus: (state, action) => {
      state.data = state.data.map((el) => {
        if (el.siteID === action.payload) {
          if (el.eol === true) {
            el.killSwitchEngaged = 0;
            return el;
          } else {
            el.killSwitchEngaged = 1;
            return el;
          }
        } else {
          return el;
        }
      });
      state.initialData = [...state.data]
    },
    setNumRows: (state, action) => {
      state.meta.numRows = action.payload;
      state.data = paginateData(state.data, action.payload);
      state.meta.numPages = state.initialData.length / action.payload;
    },
    filterServerData: (state, action) => {
      state.data = state.initialData.map((server) => {
        if (server.location.includes(action.payload)) {
          return server
        };
      });
    },
    setServerFilterString: (state, action) => {
      state.filterString = action.payload;
      state.data = current(state.initialData).filter((server) => {
        return server.siteName && server.siteName.toUpperCase().includes(action.payload.toUpperCase())
      });
    },
    setSyncingStatus: (state, action) => {
      const {syncing, syncTime} = action.payload;
      state.syncing = syncing;
      state.syncTime = syncTime;
    },
  },
})

export const { 
  setInitServerData, 
  sortServerMonitorTable,
  setServerMonitorTableSize,
  updateItemStatus,
  updateItemKillStatus,
  setNumRows,
  filterServerData,
  setServerFilterString,
  setSyncingStatus,
} = serverMonitorSlice.actions;

export default serverMonitorSlice.reducer;
/*
  case "SET_SERVER_ADD_IP":
    const newAddDataIP = Object.assign({}, state);
    newAddDataIP.serverAddData.ip = action.payload;
    newAddDataIP.serverAddData = Object.assign({}, state.serverAddData);
    newAddDataIP.serverAddData.ip = action.payload;
    return newAddDataIP;
  case "SET_SERVER_ADD_NAME":
    const newAddDataName = Object.assign({}, state);
    newAddDataName.serverAddData.name = action.payload;
    return newAddDataName;
  case "SET_SERVER_ADD_SHORT":
    const newAddDataShort = Object.assign({}, state);
    newAddDataShort.serverAddData.short = action.payload;
    return newAddDataShort;
*/
