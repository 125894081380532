import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Box, Typography } from "@mui/material"

import {
  fc_molecules,
  fc_atoms
} from '../../../fc_src/ui';


import userSettings from "../../../fc_src/utils/userSettings/userSettings";
import {
  setActivePage
} from '../../../fc_src/reducers/appSettingsSlice';

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  setPortfolioSearchStringPort,
  setPortfolioSearchStringPortName,
  setPortfolioSearchStringOrgName,
  setBitPortfoliosPageSize,
  setBitPortfoliosPageIndex,
  setBitPortfoliosSortObj,
  setBitPortfoliosFilterString,
} from '../../reducers/portfoliosSlice';

export const Portfolios = () => {

  const {
    Card,
    Table,
  } = fc_molecules;

  const {
    Spinner,
    TextInput,
    Button,
  } = fc_atoms;

  const [portfolioMatches, setPortfolioMatches] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  // Redux Selectors
  const GridLayout = useSelector((state) => state.AppSettings.gridLayout.portfolios);
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const filterString = useSelector((state) => state.BitPortfolios.meta.filterString);
  const pageSize = useSelector((state) => state.BitPortfolios.meta.pageSize);
  const pageIndex = useSelector((state) => state.BitPortfolios.meta.pageIndex);
  const sortObj = useSelector((state) => state.BitPortfolios.meta.sortObj);
  const searchStringPort = useSelector((state) => state.BitPortfolios.meta.searchStringPort);
  const searchStringPortName = useSelector((state) => state.BitPortfolios.meta.searchStringPortName);
  const searchStringOrgName = useSelector((state) => state.BitPortfolios.meta.searchStringOrgName);

  // 
  const runPortfolioSearch = async () => {
    setIsSearching(true)
    const bitMgmtAPI = new BitMgmt();
    bitMgmtAPI.getPortfolios(
      searchStringPort,
      searchStringPortName,
      searchStringOrgName
    )
    .then((data) => {
      setIsSearching(false);
      const results =
        (
          Array.isArray(data) && data.length > 0 && Array.isArray(data[0])
          ? 
            data[0]
          : 
            []
        ).map((org) => {
          org.action = (
            <a
              href={`/portfolioSummary?id=${org.portfolio_id}&import_id=${org.import_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button onClick={() => null}>Summary</Button>
            </a>
          );
          return org;
        });
        setPortfolioMatches(results);
    });
  };

  useEffect(() => {
    if (ActivePage !== "portfolios") {
      dispatch(setActivePage("portfolios"));
      if(searchStringPort.length || searchStringPortName.length || searchStringOrgName.length ) {
        runPortfolioSearch();
      }
    }
  });

  return (
    <Box>
        <Card
          title="All Portfolios"
          style={{ height: "calc(100vh - 150px)" }}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          <Box sx={{marginTop: "8px", marginBottom: "8px", display: "flex", flexDirection: "row"}}>
            <TextInput
                customRightOffset={"15px"}
                placeholder="Portfolio ID"
                onChange={(val) => dispatch(setPortfolioSearchStringPort(val))}
                onEnter={runPortfolioSearch}
                value={searchStringPort}
                style={{ marginRight: 10 }}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Portfolio Name"
                onChange={(val) =>
                    dispatch(setPortfolioSearchStringPortName(val))
                }
                onEnter={runPortfolioSearch}
                value={searchStringPortName}
                style={{ marginRight: 10 }}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Organization Name"
                onChange={(val) =>
                    dispatch(setPortfolioSearchStringOrgName(val))
                }
                onEnter={runPortfolioSearch}
                value={searchStringOrgName}
                style={{ marginRight: 10 }}
            />
            <TextInput
                customRightOffset={"15px"}
                placeholder="Filter"
                onChange={(val) =>
                    dispatch(setBitPortfoliosFilterString(val))
                }
                value={filterString}
                style={{ marginRight: 10 }}
            />
          </Box>
          {isSearching ? (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : portfolioMatches.length ? (
            <Table
              columns={(() => {
                const colData = [];
                portfolioMatches[0] &&
                  Object.keys(portfolioMatches[0]).forEach((key) => {
                    colData.push({
                      Header: key,
                      accessor: key,
                      customHeader: key.replace("_", " "),
                    });
                  });
                return colData;
              })()}
              data={(() => {
                let rows = [];
                const ports = portfolioMatches.map((i) => {
                  const ns = {...i};
                  ns.moment_created = moment(i.moment_created).format("MM/DD/YYYY hh:mm A");
                  ns.moment_updated = moment(i.moment_updated).format("MM/DD/YYYYhh:mm A");
                  return ns;
                });
                if (filterString.replace(/\s/g, "") === "") {
                  rows = [...ports];
                } else {
                  ports.forEach((row) => {
                    let hasInserted = false;
                    Object.keys(row).forEach((key) => {
                      if (
                        row[key] &&
                        row[key]
                          .toString()
                          .toLowerCase()
                          .includes(filterString.toLowerCase()) &&
                        !hasInserted
                      ) {
                        rows.push(row);
                        hasInserted = true;
                      }
                    });
                  });
                }
                return rows;
              })()}
              pageSizeProp={pageSize}
              pageIndexProp={pageIndex}
              sortObjProp={sortObj}
              setPageSizeProp={(val) => {
                dispatch(setBitPortfoliosPageSize(val));
                userSettings.setNewUserSetting(pageSize, {
                  module: "PORTFOLIOS",
                  type: "SET_BIT_PORTFOLIOS_PAGE_SIZE",
                  payload: val,
                });
              }}
              setPageIndexProp={(val) => {
                dispatch(setBitPortfoliosPageIndex(val));
              }}
              setSortObjProp={(val) => {
                dispatch(setBitPortfoliosSortObj(val));
              }}
            />
          ) : (
              <Box sx={{display: "flex", alignContent: "center", justifyContent: "center", height: "100%"}}>
                <Typography>No Data Found</Typography>
              </Box>
          )}
        </Card>
    </Box>
  );
};

export default Portfolios;