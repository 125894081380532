import React from 'react';
import moment from "moment";
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Typography,
    Box,
    Link,
} from '@mui/material'
import { ArrowLeft, Edit } from "react-feather"

export const ContactOverview = (props) => {
    const { setModalVisible, generalContactData } = props;
    const { honorific, first, middle, last, suffix} = generalContactData;

    const nameString = `${honorific} ${first} ${middle} ${last} ${suffix}`.trim();
    return (
        <Card sx={{ backgroundColor: "primary.main", color: "#fff"}}>
            <Box sx={{backgroundColor: "secondary.dark", paddingTop: "16px", paddingBottom: "16px"}}>
                <ArrowLeft size={18} style={{marginLeft: "8px", marginRight: "8px"}}/><Link href="/contacts/all" color="inherit" underline="none">Back to All Contacts</Link>
            </Box>
            <CardHeader
                title={nameString}
                action={<Button color="secondary" variant="contained" onClick={() => setModalVisible(true)}><Edit size={15}/></Button>}>
            </CardHeader>
            <CardContent>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={4}>
                        <Typography>
                            <strong>Created:</strong>&nbsp;
                            {moment(generalContactData.created).format("MM/DD/YY hh:mm")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            <strong>Last Updated:</strong>&nbsp;
                            {moment(generalContactData.updated).format("MM/DD/YY hh:mm")}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ContactOverview;