import React from "react";
import { useSelector } from "react-redux";
import styles from "./page.module.css";

export default (props) => {
  const collapsed = useSelector((state) => state.SidePanelToggle);
  return (
    <div
      className={
        collapsed ? `${styles.page} ${styles.collapsed}` : `${styles.page}`
      }
    >
      {props.children}
    </div>
  );
};
