import {createSlice} from "@reduxjs/toolkit";

const organizationsSlice = createSlice({
  name: "organizations",
  // Define the initial state for the app
  initialState: {
    organizations: [],
    meta: {
      hasDownloaded: false,
      pageIndex: 0,
      pageSize: 40,
      sortObj: {
        Header: null,
        isSortedDesc: null,
      },
      filterString: "",
    },
  },
  reducers: {
    setOrganizationsPageSize: (state, action) => {
      state.meta.pageSize = action.payload;
    },
    setOrganizationsPageIndex: (state, action) => {
      state.meta.pageIndex = action.payload;
    },
    setOrganizationsSortObj: (state, action) => {
      state.meta.sortObj = action.payload;
    },
    setOrganizationsFilterString: (state, action) => {
      state.meta.filterString = action.payload;
      state.meta.pageIndex = 0;
    },
  },
})

export const { 
  setOrganizationsPageSize,
  setOrganizationsPageIndex,
  setOrganizationsSortObj,
  setOrganizationsFilterString,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
