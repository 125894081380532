import React, { useEffect } from "react"
import {
    Modal,
    Box,
    FormGroup,
    Button,
    TextField,
    Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import {modalStyle} from "../../theme/theme";


export const EditUserDetailsModal = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const {modalOpen, setModalOpen, submitFunction, dataToEdit} = props
    const handleClose = () => setModalOpen(false)
    const onErrors = (errors) => console.error(errors)
    const submitEdits = (data) => submitFunction(data);

    useEffect(() => {
        const fields = ['honorific', 'first', 'middle', 'last', 'suffix'];
        fields.forEach(field => setValue(field, dataToEdit[field] ))
    }, [dataToEdit])

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
        >
            <Box sx={modalStyle}>
                <Typography variant='h4' align="center">Edit User Details</Typography>
                <FormGroup>
                    <TextField
                        {...register("honorific")}
                        sx={{margin: '5px'}}
                        name="honorific"
                        label="Honorific"
                    />
                    <TextField
                        {...register("first", {required: true})}
                        sx={{margin: '5px'}}
                        name="first"
                        label="First"
                    />
                    <TextField
                        {...register("middle")}
                        sx={{margin: '5px'}}
                        name="middle"
                        label="Middle"
                    />
                    <TextField
                        {...register("last", {required: true})}
                        sx={{margin: '5px'}}
                        name="last"
                        label="Last"
                    />
                    <TextField
                        {...register("suffix")}
                        sx={{margin: '5px'}}
                        name="suffix"
                        label="Suffix"
                    />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        marginTop: "16px"
                    }}>
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit(submitEdits, onErrors)}> Save </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            sx={{color: "#fff"}}
                            onClick={handleClose}> Cancel </Button>
                    </div>
                </FormGroup>
            </Box>
        </Modal>
)
}

export default EditUserDetailsModal;